import { TextInput, TextInputProps } from '@mantine/core';
import { IconCheck, IconX } from '@tabler/icons-react';
import { ValidationIndicatorState } from '@zorro/types';
import { forwardRef } from 'react';

import { ZorroIcon } from '../Icon';
import { brand } from '../theme';
import classNames from './TextInput.module.scss';

export type ITextInputProps = Omit<TextInputProps, 'value'> & {
  value?: string;
  masked?: boolean;
  validationState?: ValidationIndicatorState;
};

// TODO: Remove this hot shit https://app.clickup.com/t/8696wu71b
export const ValidationIndicator = ({
  state,
}: {
  state?: ValidationIndicatorState;
}) => {
  const spinnerStyles = `
    @keyframes spin {
      0% { transform: rotate(0deg); }
      100% { transform: rotate(360deg); }
    }

    .validation-spinner {
      width: 16px;
      height: 16px;
      border: 2px solid var(--mantine-color-blue-4);
      border-top-color: transparent;
      border-radius: 50%;
      animation: spin 0.8s linear infinite;
      display: inline-block;
    }
  `;

  switch (state) {
    case ValidationIndicatorState.VALIDATING: {
      return (
        <>
          <style>{spinnerStyles}</style>
          <div className="validation-spinner" />
        </>
      );
    }
    case ValidationIndicatorState.VALID: {
      return <ZorroIcon icon={IconCheck} color={brand.zorroGreen600} />;
    }
    case ValidationIndicatorState.INVALID: {
      return <ZorroIcon icon={IconX} color={brand.zorroFire900} />;
    }
    default: {
      return null;
    }
  }
};

export const ZorroTextInput = forwardRef<HTMLInputElement, ITextInputProps>(
  (
    {
      size = 'lg',
      disabled = false,
      masked = false,
      validationState,
      ...props
    },
    ref
  ) => {
    const inputClassNames = [
      classNames.input ?? null,
      masked ? 'fs-mask' : null,
    ].filter((className): className is string => Boolean(className));

    return (
      <TextInput
        ref={ref}
        size={size}
        classNames={{
          ...classNames,
          input: inputClassNames.join(' '),
        }}
        labelProps={{
          disabled,
        }}
        disabled={disabled}
        rightSection={
          validationState ? (
            <ValidationIndicator state={validationState} />
          ) : null
        }
        {...props}
      />
    );
  }
);

ZorroTextInput.displayName = 'ZorroTextInput';

export default ZorroTextInput;
