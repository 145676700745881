/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum FinchConnectionStatusType {
  PENDING = 'pending',
  PROCESSING = 'processing',
  CONNECTED = 'connected',
  ERROR_NO_ACCOUNT_SETUP = 'error_no_account_setup',
  ERROR_PERMISSIONS = 'error_permissions',
  REAUTH = 'reauth',
}
