import { useOs } from '@mantine/hooks';
import { Box, Group, Text } from '@zorro/zorro-ui-design';

export const AppLayoutHeaderSearchFooter = () => {
  const os = useOs();

  return (
    <Group
      justify="flex-end"
      bg="zorroPlum.1"
      pos="absolute"
      bottom={0}
      left={0}
      gap="xs"
      w="100%"
      py="sm"
      px="xl"
    >
      <Text c="zorroGray.4" size="sm">
        Use
      </Text>
      <Box
        px={6}
        style={(theme) => ({
          border: `1px solid ${theme.colors.zorroGray[2]}`,
          borderRadius: 2,
        })}
      >
        <Text c="zorroGray.4" fw={600} size="xs">
          {os === 'macos' ? '⌘' : 'Ctrl'}
        </Text>
      </Box>
      <Text c="zorroGray.4" size="sm">
        +
      </Text>
      <Box
        px={6}
        style={(theme) => ({
          border: `1px solid ${theme.colors.zorroGray[2]}`,
          borderRadius: 2,
        })}
      >
        <Text c="zorroGray.4" fw={600} size="xs">
          B
        </Text>
      </Box>
      <Text c="zorroGray.4" size="sm">
        for quick search
      </Text>
    </Group>
  );
};
