import { Radio, RadioProps } from '@mantine/core';
import { useMobileDetector } from '@zorro/shared/utils';

import { ZorroBox } from '../../Box';
import { CheckmarkCircle } from '../../CheckmarkCircle';
import classNames from './RadioButton.module.scss';

export type RadioButtonSvgIcon = React.FC<
  React.ComponentPropsWithoutRef<'svg'>
>;

export type RadioButtonSvgIconStyles = {
  marginLeft?: string | number;
  mobileSize?: string | number;
  size?: string | number;
};

export interface IRadioButtonProps extends Omit<RadioProps, 'icon'> {
  svgIcon?: RadioButtonSvgIcon;
  svgIconStyles?: RadioButtonSvgIconStyles;
}

// TODO: Refactor RadioButton code to a custom input or Button https://app.clickup.com/t/860ruaxa0
export const ZorroRadioButton = ({
  checked,
  value,
  label,
  name,
  svgIcon: icon,
  svgIconStyles,
  readOnly,
  ...props
}: IRadioButtonProps) => {
  const { isMobile } = useMobileDetector();

  const left = isMobile ? 0 : svgIconStyles?.marginLeft;

  return (
    <ZorroBox w="100%" pos="relative" style={{ cursor: 'pointer' }}>
      {checked && <CheckmarkCircle size={20} isPlumVariant />}
      <Radio
        icon={icon}
        name={name}
        value={value}
        label={label}
        classNames={classNames}
        styles={{
          inner: {
            height: isMobile ? svgIconStyles?.mobileSize : svgIconStyles?.size,
            width: isMobile ? svgIconStyles?.mobileSize : svgIconStyles?.size,
          },
          labelWrapper: {
            marginLeft: left,
          },
          label: {
            marginLeft: `-${left}`, // This makes clicking on the icon to select the radio.
            paddingLeft: left,
          },
        }}
        wrapperProps={{
          'data-checked': checked,
          'data-desktop': !isMobile,
          'data-readonly': readOnly,
        }}
        {...props}
      />
    </ZorroBox>
  );
};

ZorroRadioButton.displayName = 'ZorroRadioButton';
