import { yupResolver } from '@hookform/resolvers/yup';
import { GetEmployerDto, PayrollCycle } from '@zorro/clients';
import { PayrollReportCalendarIcon } from '@zorro/shared/assets';
import {
  ERROR_MESSAGES,
  showErrorNotification,
  useForm,
} from '@zorro/shared/utils';
import {
  Button,
  Drawer,
  FormErrorMessage,
  Group,
  MonthPickerInput,
  Space,
  Stack,
} from '@zorro/zorro-ui-design';
import { useState } from 'react';
import { Controller } from 'react-hook-form';

import { PayrollCycleInput } from '../PayrollCycleInput';
import { StepHeading } from '../StepHeading';
import { PayrollDataTable } from './PayrollDataTable';
import { PayrollFormData, payrollFormSchema } from './PayrollReportUtils';
import { usePaginatedPayrollReports } from './hooks/usePaginatedPayrollReports';

type Props = {
  employer: GetEmployerDto;
};

export const PayrollReport = ({ employer }: Props) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);
  const [selectedPayroll, setSelectedPayroll] = useState<PayrollFormData>();

  const { control, getValues, formState } = useForm<PayrollFormData>({
    mode: 'all',
    resolver: yupResolver(payrollFormSchema),
    defaultValues: {
      payrollCycle: employer.payrollCycle || PayrollCycle.MONTHLY,
    },
  });

  const { isLoading, data: employees = [] } = usePaginatedPayrollReports(
    employer.id,
    selectedPayroll
  );

  const { isValid, errors } = formState;

  const onSubmit = () => {
    const { effectiveOn, payrollCycle } = getValues();
    if (!effectiveOn) {
      showErrorNotification({
        message: ERROR_MESSAGES.EFFECTIVE_DATE_REQUIRED_ERROR_MESSAGE,
      });
      return;
    }

    setSelectedPayroll({ effectiveOn, payrollCycle });
    setIsDrawerOpen(false);
  };

  return (
    <>
      {selectedPayroll ? (
        <PayrollDataTable
          {...selectedPayroll}
          onNewPayrollClick={() => setIsDrawerOpen(true)}
          employees={employees}
          employer={employer}
          isLoading={isLoading}
        />
      ) : (
        <Stack align="center">
          <PayrollReportCalendarIcon />
          <StepHeading
            title="Run a new payroll report"
            subtitle="Generate detailed payroll data for your benefit period selection"
          />
          <Space h="xs" />
          <Button onClick={() => setIsDrawerOpen(true)}>
            Select Benefit Period
          </Button>
        </Stack>
      )}
      <Drawer
        onClose={() => setIsDrawerOpen(false)}
        title="New payroll report"
        position="right"
        opened={isDrawerOpen}
      >
        <Stack align="center" gap="3rem">
          <Group w="100%" wrap="nowrap">
            <Controller
              name="payrollCycle"
              control={control}
              render={({ field }) => (
                // eslint-disable-next-line id-length
                <PayrollCycleInput {...field} selectProps={{ w: '100%' }} />
              )}
            />
            <FormErrorMessage fieldName="payrollCycle" errors={errors} />

            <Controller
              control={control}
              key="effectiveOn"
              name="effectiveOn"
              render={({ field: { ref: _ref, ...rest } }) => (
                <MonthPickerInput
                  {...rest}
                  label="Benefit period"
                  placeholder="Benefit period"
                  required
                  w="100%"
                />
              )}
            />
          </Group>
          <Button disabled={!isValid} w="50%" onClick={onSubmit}>
            Run Payroll Report
          </Button>
        </Stack>
      </Drawer>
    </>
  );
};
