import { IconInfoCircle } from '@tabler/icons-react';
import { PayrollCycle } from '@zorro/clients';
import { formatToFixedFloat } from '@zorro/shared/formatters';
import { useMonolithQuery } from '@zorro/shared/utils';
import { Group, Icon, Stack, Text } from '@zorro/zorro-ui-design';

/* eslint-disable react/destructuring-assignment */
const payPeriodToCyclesPerYear: Record<PayrollCycle, number> = {
  [PayrollCycle.MONTHLY]: 12,
  [PayrollCycle.SEMI_MONTHLY]: 24,
  [PayrollCycle.BIWEEKLY]: 26,
  [PayrollCycle.BIWEEKLY_DEDUCTIONS]: 24,
  [PayrollCycle.WEEKLY]: 52,
};

type Props =
  | {
      mode: 'fetch';
      onboardingPeriodId: string;
      employerId: string;
    }
  | {
      mode: 'data';
      employeeContributionPerMonth: number;
      employerId: string;
    };

export const ContributionPerPayPeriodInfoBox = ({
  employerId,
  ...props
}: Props) => {
  const shouldFetch = props.mode === 'fetch';
  const onboardingPeriodId = shouldFetch ? props.onboardingPeriodId : undefined;

  const { data: benefits } = useMonolithQuery({
    method: 'benefitsControllerGetBenefits',
    params: onboardingPeriodId && [onboardingPeriodId],
    enabled: shouldFetch,
  });
  const { data: employer } = useMonolithQuery({
    method: 'employersControllerFindOne',
    params: [employerId],
  });

  const payrollCycle = employer?.payrollCycle;
  if (!payrollCycle) {
    return null;
  }

  const employeeContributionPerMonth = shouldFetch
    ? benefits?.majorMedicalBenefit?.plan?.employeeMonthlyContribution ?? 0
    : props.employeeContributionPerMonth;

  const cyclesPerYear = payPeriodToCyclesPerYear[payrollCycle];
  const contributionPerPayPeriod = formatToFixedFloat(
    (employeeContributionPerMonth * 12) / cyclesPerYear
  );

  return (
    <Stack gap="xs">
      <Group gap="xs" wrap="nowrap" align="center">
        <Icon icon={IconInfoCircle} size="1.25rem" style={{ flexShrink: 0 }} />
        <Text size="md">
          Your total contribution per pay period: ${contributionPerPayPeriod}
        </Text>
      </Group>
    </Stack>
  );
};
