import { RoleDisplayName, Roles } from '@zorro/types';

export const isAgentRole = (roles: string[]): boolean => {
  return (
    roles.includes(Roles.AGENT) &&
    !roles.includes(Roles.OMNIPOTENT_ADMIN) &&
    !roles.includes(Roles.OPERATOR)
  );
};

export const isAgentWithoutSupervisor = (roles: string[]): boolean => {
  const isAgent = isAgentRole(roles);
  return isAgent && !roles.includes(Roles.ACCOUNT_SUPERVISOR);
};

export const isZorroOperationsRole = (roles: string[]): boolean => {
  return (
    roles.includes(Roles.OMNIPOTENT_ADMIN) || roles.includes(Roles.OPERATOR)
  );
};

export const isAgencyAdminRole = (roles: string[]): boolean => {
  return roles.includes(Roles.AGENCY_ADMIN);
};

export const isAccountSupervisor = (roles: string[]): boolean => {
  return roles.includes(Roles.ACCOUNT_SUPERVISOR);
};

export const isEmployerAdminRole = (roles: string[]): boolean => {
  return roles.includes(Roles.EMPLOYER_ADMIN);
};

export const isManagement = (roles: string[]): boolean => {
  return (
    roles.includes(Roles.OMNIPOTENT_ADMIN) ||
    roles.includes(Roles.OPERATOR) ||
    roles.includes(Roles.AGENT)
  );
};

export const rolesToDisplayName = (roles: Roles[]) => {
  if (!roles || roles.length === 0) {
    return RoleDisplayName.OTHER;
  }
  return roles?.includes(Roles.EMPLOYER_ADMIN)
    ? RoleDisplayName.ADMIN
    : RoleDisplayName.EMPLOYEE;
};
