import { IconEdit, IconHelp } from '@tabler/icons-react';
import { AddIcon } from '@zorro/shared/assets';
import {
  formatCurrencyEnUs,
  formatDateEnLocale,
  formatDateEnLocaleRange,
  parseDateISO,
} from '@zorro/shared/formatters';
import { isDefined, useMonolithQuery, useRoles } from '@zorro/shared/utils';
import { oepPaymentMethodLabelConfig } from '@zorro/types';
import {
  ActionIcon,
  Box,
  Button,
  Center,
  Drawer,
  Group,
  Icon,
  Loader,
  NewTable,
  Text,
  Tooltip,
  brand,
} from '@zorro/zorro-ui-design';
import {
  DataTableColumn,
  DataTableColumnGroup,
  DataTableSortStatus,
} from 'mantine-datatable';
import { useState } from 'react';

import { PlanYearSetupForm } from '../PlanYearSetupForm/PlanYearSetupForm';
import { AllowanceModelCell } from './cells/AllowanceModelCell';
import { NO_DATA_PLACEHOLDER } from './planYearTable.consts';

type PlanYearRecord = {
  id: string;
  year: string;
  effectivePeriod: string;
  openEnrollmentPeriod: string;
  totalPEPMFees: string;
  pepmCommissions: string;
  monthlyPlatformFee: string;
  paymentMethod: string;
  initialDrawAmount: string;
  initialDrawDate: string;
  reserveAmount: string;
};

type Props = {
  employerName: string;
  employerId: string;
  isEditable: boolean;
};

export const PlanYearsTable = ({
  employerName,
  employerId,
  isEditable,
}: Props) => {
  const { isEmployerAdmin } = useRoles();
  const [isPlanYearDrawerOpen, setIsPlanYearDrawerOpen] = useState(false);
  const [editPeriod, setEditPeriod] = useState<PlanYearRecord>();
  const [sortStatus, setSortStatus] = useState<
    DataTableSortStatus<PlanYearRecord>
  >({
    columnAccessor: 'year',
    direction: 'desc',
  });

  const { data: openEnrollmentPeriods = [], isLoading: isLoadingOEPs } =
    useMonolithQuery({
      method: 'openEnrollmentPeriodsControllerFindAllForEmployer',
      params: [employerId],
    });

  if (isLoadingOEPs) {
    return <Loader />;
  }

  const planYearRecords: PlanYearRecord[] = openEnrollmentPeriods.map(
    ({
      id,
      effectiveFrom,
      effectiveUntil,
      onboardingFrom,
      onboardingUntil,
      totalPEPMFees,
      pepmCommissions,
      monthlyPlatformFee,
      paymentMethod,
      initialDrawAmount,
      initialDrawDate,
      reserveAmount,
    }) => {
      return {
        id,
        year: parseDateISO(effectiveFrom).year().toString(),
        effectivePeriod: formatDateEnLocaleRange(effectiveFrom, effectiveUntil),
        openEnrollmentPeriod: formatDateEnLocaleRange(
          onboardingFrom,
          onboardingUntil
        ),
        totalPEPMFees: isDefined(totalPEPMFees)
          ? formatCurrencyEnUs(totalPEPMFees)
          : NO_DATA_PLACEHOLDER,
        pepmCommissions: isDefined(pepmCommissions)
          ? formatCurrencyEnUs(pepmCommissions)
          : NO_DATA_PLACEHOLDER,
        monthlyPlatformFee: isDefined(monthlyPlatformFee)
          ? formatCurrencyEnUs(monthlyPlatformFee)
          : NO_DATA_PLACEHOLDER,
        paymentMethod: paymentMethod
          ? oepPaymentMethodLabelConfig[paymentMethod]
          : NO_DATA_PLACEHOLDER,
        initialDrawAmount: isDefined(initialDrawAmount)
          ? formatCurrencyEnUs(initialDrawAmount)
          : NO_DATA_PLACEHOLDER,
        initialDrawDate: isDefined(initialDrawDate)
          ? formatDateEnLocale(initialDrawDate)
          : NO_DATA_PLACEHOLDER,
        reserveAmount: isDefined(reserveAmount)
          ? formatCurrencyEnUs(reserveAmount)
          : NO_DATA_PLACEHOLDER,
      };
    }
  );

  const dataToSort = planYearRecords.sort((first, second) => {
    return (first[sortStatus.columnAccessor as keyof PlanYearRecord] ?? '')
      .toLocaleString()
      .localeCompare(
        (
          second[sortStatus.columnAccessor as keyof PlanYearRecord] ?? ''
        ).toLocaleString()
      );
  });

  const handleClose = () => {
    setIsPlanYearDrawerOpen(false);
  };

  const records =
    sortStatus.direction === 'desc' ? dataToSort.reverse() : dataToSort;

  const planDesignColumns: DataTableColumn<PlanYearRecord>[] = [
    {
      accessor: 'year',
      title: 'Year',
      sortable: true,
      width: 120,
    },
    {
      accessor: 'effectivePeriod',
      title: 'Coverage period',
      width: 250,
    },
    {
      accessor: 'openEnrollmentPeriod',
      title: 'Election window',
      width: 250,
    },
    {
      accessor: 'allowanceModelFile',
      title: 'Allowance model file',
      render: ({ year }) => (
        <AllowanceModelCell
          employerId={employerId}
          employerName={employerName}
          year={year}
        />
      ),
      width: isEmployerAdmin ? '150px' : 'auto',
    },
  ];

  const fullPlanYearGroups: DataTableColumnGroup<PlanYearRecord>[] = [
    {
      id: 'plan_design',
      title: (
        <Box ta="center" w="100%">
          Plan design
        </Box>
      ),
      columns: planDesignColumns,
    },
    {
      id: 'charges',
      title: (
        <Box ta="center" w="100%">
          Charges
        </Box>
      ),
      columns: [
        {
          accessor: 'totalPEPMFees',
          title: 'Total PEPM fees',
        },
        {
          accessor: 'pepmCommissions',
          title: (
            <Group wrap="nowrap" gap="4">
              <Text size="sm" fw={500} c={brand.zorroGray400}>
                PEPM commissions
              </Text>
              <Tooltip
                label="Broker commission portion of the total PEPM fees"
                position="top"
              >
                <Box pos="relative">
                  <Center>
                    <Icon
                      icon={IconHelp}
                      color={brand.zorroGray400}
                      size="1.25rem"
                    />
                  </Center>
                </Box>
              </Tooltip>
            </Group>
          ),
        },
        {
          accessor: 'monthlyPlatformFee',
          title: 'Monthly platform fee',
        },
      ],
    },
    {
      id: 'payments',
      title: (
        <Box ta="center" w="100%">
          Payments
        </Box>
      ),
      columns: [
        {
          accessor: 'paymentMethod',
          title: 'Payment method',
          width: 230,
        },
        {
          accessor: 'initialDrawAmount',
          title: 'Initial draw amount',
        },
        {
          accessor: 'initialDrawDate',
          title: 'Initial draw date',
        },
        {
          accessor: 'reserveAmount',
          title: 'Reserve amount',
        },
        ...(isEditable
          ? [
              {
                title: 'Actions',
                accessor: 'actions',
                sortable: false,
                render: (record: PlanYearRecord) => (
                  <ActionIcon
                    size="md"
                    onClick={() => {
                      setEditPeriod(record);
                      setIsPlanYearDrawerOpen(true);
                    }}
                  >
                    <IconEdit />
                  </ActionIcon>
                ),
              },
            ]
          : []),
      ],
    },
  ];

  return (
    <>
      <Drawer
        title={editPeriod ? 'Edit plan year' : 'New plan year'}
        onClose={handleClose}
        opened={isPlanYearDrawerOpen}
      >
        <PlanYearSetupForm
          openEnrollmentPeriodId={editPeriod?.id}
          isEdit={Boolean(editPeriod)}
          employerId={employerId}
          onSuccess={() => {
            setIsPlanYearDrawerOpen(false);
          }}
        />
      </Drawer>
      <Box w={isEmployerAdmin ? '800px' : ''}>
        <NewTable
          reduceHeightBy={350}
          records={records}
          sortStatus={sortStatus}
          onSortStatusChange={setSortStatus}
          {...(isEmployerAdmin
            ? { columns: planDesignColumns }
            : { groups: fullPlanYearGroups })}
        />
      </Box>

      {isEditable && (
        <Group>
          <Tooltip label="Add a new plan year">
            <Button
              size="sm"
              p={0}
              variant="subtle"
              onClick={() => {
                setEditPeriod(undefined);
                setIsPlanYearDrawerOpen(true);
              }}
            >
              <AddIcon />
            </Button>
          </Tooltip>
        </Group>
      )}
    </>
  );
};
