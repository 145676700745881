import {
  EnrollmentStatus,
  FamilyUnit,
  InsuredPeopleDto,
  MajorMedicalPlanDto,
} from '@zorro/clients';
import { Grid } from '@zorro/zorro-ui-design';
import { ReactNode } from 'react';

import { NoEmployeeContribution } from './NoEmployeeContribution';
import { WithEmployeeContribution } from './WithEmployeeContribution';

export type SummaryRowProps = {
  isLoading: boolean;
  icon: ReactNode;
  title: string;
  yourContribution?: number | null;
  employerContribution?: number | null;
  familyUnitCovered?: FamilyUnit;
  selectedMedicalPlan?: MajorMedicalPlanDto | null;
  enrollmentStatus?: EnrollmentStatus;
  memberId?: string | null;
  editActionColumn?: ReactNode;
  insuredPeople?: InsuredPeopleDto | null;
  shouldShowBenefitStatus?: boolean;
  shouldShowEmptyValues?: boolean;
};

export const SummaryRow = ({
  isLoading,
  icon,
  title,
  yourContribution,
  employerContribution,
  enrollmentStatus,
  memberId,
  shouldShowBenefitStatus,
  shouldShowEmptyValues,
  familyUnitCovered,
  selectedMedicalPlan,
  editActionColumn,
  insuredPeople,
}: SummaryRowProps) => {
  return (
    <Grid.Col py="xl" px="xl" style={{ borderRadius: 'inherit' }}>
      <Grid align="center">
        {typeof employerContribution === 'number' &&
        typeof yourContribution === 'number' ? (
          <WithEmployeeContribution
            isLoading={isLoading}
            title={title}
            yourContribution={yourContribution}
            employerContribution={employerContribution}
            enrollmentStatus={enrollmentStatus}
            memberId={memberId}
            icon={icon}
            familyUnitCovered={familyUnitCovered}
            selectedMedicalPlan={selectedMedicalPlan}
            shouldShowBenefitStatus={shouldShowBenefitStatus}
            shouldShowEmptyValues={shouldShowEmptyValues}
            editActionColumn={editActionColumn}
            insuredPeople={insuredPeople}
            isSummaryRow
          />
        ) : (
          <NoEmployeeContribution title={title} icon={icon} />
        )}
      </Grid>
    </Grid.Col>
  );
};
