import { IconDots, IconDownload } from '@tabler/icons-react';
import { EmployerDocumentDto } from '@zorro/clients';
import { FileIcon } from '@zorro/shared/assets';
import {
  formatDateEnLocale,
  singleLineTruncatedTextWithEllipsis,
} from '@zorro/shared/formatters';
import { downloadEmployerDocument } from '@zorro/shared/utils';
import {
  ActionIcon,
  Card,
  Flex,
  Group,
  RadixMenu,
  Stack,
  Text,
  ThemeIcon,
  brand,
} from '@zorro/zorro-ui-design';
import _noop from 'lodash/noop';

export type Props = {
  document: EmployerDocumentDto;
  isEditable: boolean;
  onEdit?: (document: EmployerDocumentDto) => void;
  onDelete?: (document: EmployerDocumentDto) => void;
};

export const EmployerDocumentCard = ({
  document,
  isEditable,
  onEdit = _noop,
  onDelete = _noop,
}: Props) => {
  const handleDeleteEmployerDocument = () => onDelete(document);
  const handleEditEmployerDocument = () => onEdit(document);
  const handleDownloadEmployerDocument = async () =>
    await downloadEmployerDocument(document.documentUrl, document.fileName);

  return (
    <Card p="sm" h="5rem">
      <Group justify="space-between" align="center" wrap="nowrap" h="100%">
        <Flex
          gap="sm"
          align="center"
          style={{
            overflow: 'hidden',
          }}
        >
          <ThemeIcon color={brand.primaryButtonSelected} size="3.5rem">
            <FileIcon height={32} width={32} />
          </ThemeIcon>
          <Stack justify="space-between" gap="xs">
            <Text style={{ ...singleLineTruncatedTextWithEllipsis }}>
              {document.fileName}
            </Text>
            {document.description && (
              <Text
                style={{ ...singleLineTruncatedTextWithEllipsis }}
                fw={500}
                c={brand.zorroGray400}
                size="sm"
              >
                {document.description}
              </Text>
            )}
          </Stack>
        </Flex>

        <Stack justify="space-between" gap={0}>
          <Text size="sm" c="zorroGray.4" flex="0 0 auto">
            {`Updated on ${
              document.updatedAt
                ? formatDateEnLocale(document.updatedAt)
                : formatDateEnLocale(document.createdAt)
            }`}
          </Text>
          <Group justify="flex-end">
            <ActionIcon size="md">
              <IconDownload
                onClick={handleDownloadEmployerDocument}
                color={brand.zorroGray400}
                size={20}
              />
            </ActionIcon>
            {isEditable && (
              <RadixMenu>
                <RadixMenu.Trigger>
                  <ActionIcon size="md">
                    <IconDots size={20} />
                  </ActionIcon>
                </RadixMenu.Trigger>
                <RadixMenu.Content align="end">
                  <RadixMenu.Item onClick={handleEditEmployerDocument}>
                    <Text size="sm">Edit</Text>
                  </RadixMenu.Item>
                  <RadixMenu.Item onClick={handleDeleteEmployerDocument}>
                    <Text size="sm">Delete</Text>
                  </RadixMenu.Item>
                </RadixMenu.Content>
              </RadixMenu>
            )}
          </Group>
        </Stack>
      </Group>
    </Card>
  );
};
