import { Grid, Skeleton, Stack, brand } from '@zorro/zorro-ui-design';

export const EmployeeLayoutSidebarItemsSkeleton = () => {
  return (
    <Stack pt={16}>
      <Grid gutter="xs" align="center" justify="center" pl={16}>
        <Grid.Col span={1}>
          <Skeleton h="1.5rem" w="1.5rem" />
        </Grid.Col>
        <Grid.Col span={11} pl={16} pr={16}>
          <Grid>
            <Grid.Col span={8}>
              <Skeleton h="1.25rem" />
            </Grid.Col>
            <Grid.Col span={2} offset={2}>
              <Skeleton h="1.25rem" w="1.5rem" circle />
            </Grid.Col>
          </Grid>
        </Grid.Col>
      </Grid>

      <Grid
        gutter="xs"
        bg={brand.primaryButtonSelected}
        p={20}
        style={{ borderRadius: '0.5rem' }}
      >
        <Grid.Col span={11} offset={1}>
          <Grid>
            <Grid.Col span={10}>
              <Skeleton h="1.25rem" />
            </Grid.Col>
            <Grid.Col span={2}>
              <Skeleton h="1.5rem" w="1.5rem" />
            </Grid.Col>
          </Grid>
          <Grid>
            <Grid.Col span={8}>
              <Skeleton h="1rem" />
            </Grid.Col>
          </Grid>
        </Grid.Col>
      </Grid>

      <Grid gutter="xs" mt={6}>
        <Grid.Col span={1} offset={2}>
          <Skeleton h="1.5rem" w="1.5rem" />
        </Grid.Col>
        <Grid.Col span={6} pl={16}>
          <Skeleton h="1.25rem" />
        </Grid.Col>
      </Grid>
      <Grid gutter="xs" pl={16} mt={16}>
        <Grid.Col span={1}>
          <Skeleton h="1.5rem" w="1.5rem" />
        </Grid.Col>
        <Grid.Col span={6} pl={16}>
          <Skeleton h="1.25rem" />
        </Grid.Col>
      </Grid>
    </Stack>
  );
};
