import { GuyTypingOnLaptop2 } from '@zorro/shared/assets';
import { Button, Container, Link, Stack } from '@zorro/zorro-ui-design';

import { StepHeading } from '../StepHeading';

export const PageNotFound = () => {
  return (
    <Container mt={{ md: '10rem' }}>
      <Stack align="center">
        <GuyTypingOnLaptop2 />

        <StepHeading
          title="Oops! We can't seem to find that page"
          subtitle="It looks like the page you’re looking for doesn't exist. Check the URL, or go back to the homepage to get back on track!"
          align="center"
        />
        <Link href="/" prefetch={false}>
          <Button mt="lg" size="lg">
            Home page
          </Button>
        </Link>
      </Stack>
    </Container>
  );
};
