import { Pill } from '@mantine/core';
import {
  COMPANY_SETUP_DYNAMIC_ROUTES,
  SUCCESS_MESSAGES,
  showSuccessNotification,
  useDynamicMurrietaRouter,
  useMonolithQuery,
  useRoles,
} from '@zorro/shared/utils';
import { Roles } from '@zorro/types';
import { Group, Stack, Tabs, Title } from '@zorro/zorro-ui-design';
import { ReactElement } from 'react';
import { FieldValues, FormState } from 'react-hook-form';

import { EmployerDotsMenu } from '../EmployerDotsMenu';
import { FormFooter } from '../FormFooter';
import { useLoadingOverlay } from '../LoadingOverlayContext';

export enum CompanySetupTabType {
  COMPANY_DETAILS = 'COMPANY_DETAILS',
  CONTACTS = 'CONTACTS',
  COMPANY_SETUP = 'COMPANY_SETUP',
  ZORRO_SETUP = 'ZORRO_SETUP',
  PLAN_YEAR_SETUP = 'PLAN_YEAR_SETUP',
  PAYROLL_SYNC = 'PAYROLL_SYNC',
}

const getContentWidth = (isPayrollCycleVisible = false) => {
  return isPayrollCycleVisible ? '790px' : '710px';
};

const setupTabConfig: Record<
  CompanySetupTabType,
  {
    label: string;
    route: keyof typeof COMPANY_SETUP_DYNAMIC_ROUTES;
    isFullPageTab?: boolean;
  }
> = {
  [CompanySetupTabType.COMPANY_DETAILS]: {
    label: 'Company details',
    route: 'COMPANY_SETUP_COMPANY_DETAILS',
  },
  [CompanySetupTabType.CONTACTS]: {
    label: 'Contacts',
    route: 'COMPANY_SETUP_CONTACTS',
  },
  [CompanySetupTabType.COMPANY_SETUP]: {
    label: 'Company setup',
    route: 'COMPANY_SETUP_COMPANY_SETUP',
  },
  [CompanySetupTabType.ZORRO_SETUP]: {
    label: 'Zorro setup',
    route: 'COMPANY_SETUP_ZORRO_SETUP',
  },
  [CompanySetupTabType.PLAN_YEAR_SETUP]: {
    label: 'Plan year setup',
    route: 'COMPANY_SETUP_PLAN_YEAR_SETUP',
    isFullPageTab: true,
  },
  [CompanySetupTabType.PAYROLL_SYNC]: {
    label: 'Payroll sync',
    route: 'COMPANY_SETUP_PAYROLL_SYNC',
    isFullPageTab: true,
  },
};

function getSetupTabs(
  {
    userRoles,
    isZorroOperations,
    isEmployerAdmin,
  }: ReturnType<typeof useRoles>,
  isPayrollSyncVisible: boolean = false
) {
  const allSetupTabs = Object.values(CompanySetupTabType);

  return allSetupTabs.filter((setupTab) => {
    switch (setupTab) {
      case CompanySetupTabType.ZORRO_SETUP: {
        if (isEmployerAdmin) {
          return false;
        }
        break;
      }
      case CompanySetupTabType.PAYROLL_SYNC: {
        const isAccountSupervisor = userRoles.includes(
          Roles.ACCOUNT_SUPERVISOR
        );

        if (!isPayrollSyncVisible) {
          return false;
        }
        if (!(isZorroOperations || isEmployerAdmin || isAccountSupervisor)) {
          return false;
        }
        break;
      }
    }

    return true;
  });
}

type Props = {
  tab: CompanySetupTabType;
  employerId: string;
  children: ReactElement;
  formState?: FormState<FieldValues>;
  onSubmit?: () => Promise<boolean>;
  onCancel?: () => void;
};

export const CompanySetupTabWrapper = ({
  tab,
  employerId,
  onSubmit,
  onCancel,
  formState,
  children,
}: Props) => {
  const rolesInfo = useRoles();
  const { isAgentWithoutSupervisor: isReadonlyMode, isEmployerAdmin } =
    rolesInfo;

  const { startLoading, stopLoading } = useLoadingOverlay();
  const { navigateToRoute } = useDynamicMurrietaRouter();

  const { isFullPageTab } = setupTabConfig[tab];

  const { data: employer } = useMonolithQuery({
    method: 'employersControllerFindOne',
    params: [employerId],
  });

  const contentWidth = getContentWidth(employer?.isPayrollSyncVisible);

  const handleTabClick = (newTab: CompanySetupTabType) => {
    const { route } = setupTabConfig[newTab];
    navigateToRoute(route, [employerId]);
  };

  const handleSubmit = async () => {
    startLoading();
    if (await onSubmit?.()) {
      showSuccessNotification({
        message: SUCCESS_MESSAGES.SAVED_SUCCESSFULLY_MESSAGE,
      });
    }

    stopLoading();
  };

  const { isValid, isDirty } = formState || {};
  const isSubmitDisabled = !isValid || !isDirty;

  const setupTabs = getSetupTabs(rolesInfo, employer?.isPayrollSyncVisible);

  return (
    <Stack maw={isFullPageTab ? undefined : contentWidth} pb="6rem">
      <Group gap="xs">
        <Title fw={600}>Setup</Title>
        {!isReadonlyMode && !isEmployerAdmin && (
          <EmployerDotsMenu employerId={employerId} />
        )}
        {employer?.isDemo && (
          <Pill ml="sm" size="md" bg="#E0E3FE" radius="sm">
            Demo company
          </Pill>
        )}
      </Group>
      <Tabs maw={contentWidth} mb="xl" keepMounted={false} value={tab}>
        <Tabs.List grow>
          {setupTabs.map((newTab) => (
            <Tabs.Tab
              onClick={() => handleTabClick(newTab)}
              value={newTab}
              key={newTab}
            >
              {setupTabConfig[newTab].label}
            </Tabs.Tab>
          ))}
        </Tabs.List>
      </Tabs>
      {children}
      {formState && onSubmit && !isReadonlyMode && (
        <FormFooter
          primaryLabel="Save changes"
          primaryButtonProps={{
            disabled: isSubmitDisabled,
            onClick: handleSubmit,
          }}
          secondaryLabel={onCancel ? 'Cancel' : undefined}
          secondaryButtonProps={{ onClick: onCancel }}
        />
      )}
    </Stack>
  );
};
