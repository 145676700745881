export const ManWithFile = () => {
  return (
    <svg
      width="117"
      height="117"
      viewBox="0 0 117 117"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.4946 12.0211C20.5396 11.9679 20.5928 11.9188 20.6419 11.8683C30.2543 2.32138 45.7872 1.09391 58.19 4.98907C64.2537 6.89163 69.0108 12.2379 74.7117 14.8906C76.6088 15.7703 78.6082 16.3499 80.7945 16.4099C86.5144 16.5668 91.8566 12.7848 97.5616 13.2199C104.845 13.7654 109.134 21.1302 110.17 27.6767C111.881 38.5015 106.927 50.247 100.76 58.8952C97.3748 63.6468 93.5655 68.033 89.9104 72.5664C87.075 76.0947 85.2065 81.4314 88.9557 85.0974C93.9338 89.9623 95.4654 79.1961 101.334 81.6537C115.945 87.7706 114.278 100.884 110.961 106.498C107.934 111.632 102.096 115.078 96.1446 115.564C95.1997 115.642 94.25 115.642 93.305 115.564"
        stroke="#E0E3FE"
      />
      <path
        d="M46.8291 80.355V80.4C46.8291 81.4024 51.2671 85.2253 51.9353 85.9331"
        stroke="#CCDDE2"
        stroke-width="0.661961"
      />
      <path
        d="M33.0208 115.515C31.6747 115.56 30.4022 115.555 29.1938 115.506C15.2089 113.789 4.68957 85.9334 21.1444 77.4775"
        stroke="#E0E3FE"
      />
      <path
        d="M48.8824 43.3248V15.1027C48.8801 13.4006 47.5008 12.0213 45.7987 12.019H6.38348C4.68402 12.025 3.30888 13.4032 3.30664 15.1027V71.06C3.3089 72.7613 4.68906 74.139 6.3903 74.1383H45.8055C46.1879 74.1409 46.567 74.0682 46.9211 73.9241"
        stroke="#3C0789"
        stroke-width="0.661961"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <rect
        x="4.98828"
        y="13.896"
        width="42.0472"
        height="58.0252"
        rx="3.08143"
        fill="#E0E3FE"
      />
      <path
        d="M12.4517 25.7371H39.3561"
        stroke="#3C0789"
        stroke-linecap="round"
      />
      <path
        d="M12.5625 39.4652H39.4669"
        stroke="#3C0789"
        stroke-linecap="round"
      />
      <path
        d="M12.4365 53.1932H39.3409"
        stroke="#3C0789"
        stroke-linecap="round"
      />
      <path
        d="M12.4517 32.6014H39.3561"
        stroke="#3C0789"
        stroke-linecap="round"
      />
      <path
        d="M12.5625 46.3289H39.4669"
        stroke="#3C0789"
        stroke-linecap="round"
      />
      <path
        d="M12.4365 60.057H39.3409"
        stroke="#3C0789"
        stroke-linecap="round"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M78.618 44.6565C83.5524 45.6535 91.2036 49.7764 91.2036 49.7764C104.201 57.2953 101.337 81.6496 101.337 81.6496C101.337 81.6496 100.977 94.2638 92.1583 101.915C83.3397 109.566 65.8428 108.606 57.5629 107.001C49.283 105.396 44.4372 101.713 44.4372 101.713C47.2031 99.9101 48.1578 96.5973 48.1578 96.5973L48.226 96.5728C46.123 97.0392 44.1181 97.5711 42.7379 98.1412C38.3095 99.966 32.8704 98.6022 32.8704 98.6022C21.2941 95.4803 20.3844 86.2403 21.1414 77.4775C21.8983 68.7148 24.2318 58.4955 32.3795 51.9395C40.5271 45.3835 49.9867 43.2559 49.9867 43.2559L51.9057 50.1024L55.4981 48.7263L54.6579 52.6091L60.963 66.4113L70.9382 55.7569L70.8836 51.6394L74.5196 54.4012C74.5196 54.4012 76.9418 49.029 77.7629 47.211C78.5839 45.393 78.618 44.6565 78.618 44.6565ZM79.472 85.5841C79.4905 85.4865 79.5058 85.405 79.5183 85.3417C79.7247 84.3084 78.3203 83.6686 74.7241 82.0304C74.2099 81.7962 73.6509 81.5415 73.0455 81.2624C68.2052 79.0312 58.0527 82.123 58.0527 82.123C58.0527 82.123 53.5561 89.5819 58.0227 94.815C60.1612 94.6241 61.7051 94.5654 61.7051 94.5654C65.5411 94.1674 68.1924 94.5284 70.2994 94.8153C72.1274 95.0641 73.5457 95.2572 74.9726 94.8505C77.8789 94.0219 79.1476 87.3023 79.472 85.5841Z"
        fill="#8033EF"
      />
      <path
        d="M88.0381 105.978V115.566C72.5584 115.566 51.6151 115.541 36.4846 115.522C36.2978 110.588 36.0032 99.2365 37.2552 99.156C38.8577 99.0578 40.553 98.0309 42.5087 100.366C44.4645 102.701 52.6517 106.877 64.1203 107.859C75.5889 108.841 85.2599 106.139 87.408 104.97C87.4093 104.971 88.0381 103.85 88.0381 105.978Z"
        fill="#8033EF"
      />
      <path
        d="M74.2947 20.3662C73.7492 20.489 73.2718 20.9118 72.9077 21.3632C72.5691 21.7866 72.2825 22.2491 72.0539 22.7407C72.0539 22.7407 70.9028 17.8308 70.4173 13.5715C70.255 12.1859 69.7695 11.4439 69.1789 11.0702C67.961 10.2983 66.3149 11.1234 66.3149 11.1234L60.8458 14.1021C59.7966 14.6802 58.6416 15.0411 57.4498 15.1631C56.893 15.2273 56.3306 15.2273 55.7737 15.1631C52.7609 13.6738 52.7173 7.79836 56.3779 7.75745C56.488 7.86028 56.5914 7.97006 56.6874 8.08614C56.6347 7.97794 56.5856 7.87019 56.5402 7.7629C56.7449 7.76913 56.9488 7.79194 57.1498 7.8311C57.4498 7.88974 57.7294 8.19934 57.9163 8.41619C56.9438 6.52862 57.9804 4.15007 59.8966 3.42859C61.0613 2.9908 62.3638 3.07399 63.579 3.3304C63.7713 3.36858 63.9622 3.41359 64.1545 3.46678C67.2014 4.38602 69.869 5.98308 71.8398 8.64941C74.3343 12.0222 74.9576 16.2924 74.2947 20.3662Z"
        fill="#3C0789"
      />
      <path
        d="M39.5591 71.3774C36.2218 86.2434 49.0324 83.753 65.6795 80.8221C71.6273 79.776 76.6885 82.3578 79.6208 84.7663"
        stroke="#3C0789"
        stroke-width="0.893648"
        stroke-linejoin="round"
      />
      <path
        d="M49.9872 43.2549C42.4219 45.1165 33.4969 49.6322 28.5871 55.7614C22.1592 63.774 20.8131 74.4284 20.959 84.4022C21.0954 94.1469 31.3066 100.877 40.5753 98.7717"
        stroke="#3C0789"
        stroke-width="0.893648"
        stroke-linejoin="round"
      />
      <path
        d="M53.9053 39.6141H53.9108"
        stroke="#3C0789"
        stroke-width="0.893648"
        stroke-linejoin="round"
      />
      <path
        d="M80.4388 71.9255C80.4388 71.9255 80.7116 78.3751 79.6205 84.7661C79.1254 87.6834 78.3467 90.5884 77.1315 92.8633C76.4674 94.0867 75.1981 94.8598 73.8064 94.8886C72.5603 94.9113 71.3139 94.8617 70.0736 94.74C59.3837 93.7362 49.3649 95.9388 42.0588 98.3596C41.5678 98.5206 41.0741 98.6569 40.5776 98.7688C45.1861 102.95 57.042 110.681 79.4582 107.328C109.599 102.828 100.179 63.0264 100.179 63.0264C99.3609 58.7385 96.805 54.7574 93.7323 51.7419C90.2517 48.3323 83.5034 46.0779 78.9154 44.6499"
        stroke="#3C0789"
        stroke-width="0.893648"
        stroke-linejoin="round"
      />
      <path
        d="M62.7933 37.1566C62.7933 37.1566 53.6228 35.52 54.8516 24.7047C55.3971 19.893 56.4487 16.9498 57.4429 15.1577"
        stroke="#3C0789"
        stroke-width="0.893648"
        stroke-linejoin="round"
      />
      <path
        d="M71.4785 34.3117C73.1724 31.7804 72.9133 28.4198 73.4452 27.5743C74.2635 26.2622 77.5367 22.1693 75.5728 20.695C75.12 20.3554 74.6876 20.2859 74.2948 20.365C72.9733 20.6595 72.054 22.7394 72.054 22.7394C72.054 22.7394 70.9043 17.8296 70.4174 13.5689C69.9305 9.30824 66.3136 11.1222 66.3136 11.1222L60.8446 14.0994C59.7957 14.6788 58.6406 15.0402 57.4486 15.1619C56.8249 15.2353 56.1944 15.2303 55.5719 15.1469C51.344 14.6013 51.0712 6.59691 55.9211 7.50932C56.2211 7.56797 56.5007 7.87756 56.6876 8.09441C55.7138 6.20684 56.7517 3.82829 58.6679 3.10682C59.8326 2.66902 61.1351 2.75222 62.3503 3.00862C66.1445 3.8092 69.49 5.46355 71.8399 8.6495C74.3358 12.025 74.9604 16.2952 74.2948 20.369"
        stroke="#3C0789"
        stroke-width="0.893648"
        stroke-linejoin="round"
      />
      <path
        d="M61.6479 26.9988H59.6826L59.8108 25.4413L60.3359 19.1362"
        stroke="#3C0789"
        stroke-width="0.893648"
        stroke-linejoin="round"
      />
      <path
        d="M59.8069 25.4414H54.4088C53.6119 25.4414 52.9658 24.7954 52.9658 23.9985V19.1377H62.09V23.9985C62.0907 24.7956 62.7371 25.4414 63.5343 25.4414H68.8874C69.6845 25.4414 70.331 24.7956 70.3317 23.9985V19.1377H62.09"
        stroke="#3C0789"
        stroke-width="0.893648"
        stroke-linejoin="round"
      />
      <path
        d="M61.6475 28.8785C61.6475 28.8785 63.0399 29.3695 64.5961 26.9937"
        stroke="#3C0789"
        stroke-width="0.893648"
        stroke-linejoin="round"
      />
      <path
        d="M60.9072 66.8885L71.0706 55.6095L70.8851 51.638L74.3998 54.6671L78.9155 44.6551L78.9278 44.6292C77.8235 43.9785 76.7932 43.2098 75.855 42.3365C75.4231 41.931 75.014 41.5073 74.6275 41.0654C74.1716 40.5479 73.7463 40.0041 73.3537 39.437L73.0359 38.9883"
        stroke="#3C0789"
        stroke-width="0.893648"
        stroke-linejoin="round"
      />
      <path
        d="M67.5742 51.2479L65.4821 48.3661L63.2508 45.4256L67.9343 42.5916L73.0405 38.9883L74.624 41.0654L75.1559 41.7651L73.8643 43.4194L63.7786 56.3651V56.3705L61.8706 58.7423"
        stroke="#3C0789"
        stroke-width="0.893648"
        stroke-linejoin="round"
      />
      <path
        d="M57.7923 49.6088L63.2477 45.43L56.5457 38.7321L54.0062 41.127L61.8688 58.7466L60.9073 66.8901L54.6595 52.6093L55.4956 48.7278L51.9032 50.0998L49.8179 43.3256C52.2146 42.4007 54.3818 40.966 56.1693 39.1208L56.5485 38.728"
        stroke="#3C0789"
        stroke-width="0.893648"
        stroke-linejoin="round"
      />
      <path
        d="M73.036 38.9882L71.4785 34.3062C71.0546 34.9003 70.5081 35.3965 69.876 35.7614"
        stroke="#3C0789"
        stroke-width="0.893648"
        stroke-linejoin="round"
      />
      <path
        d="M57.619 34.3062L56.5483 38.7277"
        stroke="#3C0789"
        stroke-width="0.893648"
        stroke-linejoin="round"
      />
      <ellipse
        cx="65.0221"
        cy="22.3507"
        rx="0.717384"
        ry="0.717384"
        fill="#3C0789"
      />
      <ellipse
        cx="58.7662"
        cy="22.3507"
        rx="0.717384"
        ry="0.717384"
        fill="#3C0789"
      />
      <path
        d="M88.0438 115.566V104.651V104.371"
        stroke="#3C0789"
        stroke-width="0.893648"
        stroke-linejoin="round"
      />
      <path
        d="M36.5789 115.526V99.1475V99.1421"
        stroke="#3C0789"
        stroke-width="0.893648"
        stroke-linejoin="round"
      />
      <path
        d="M17.0117 115.492C17.0117 115.492 25.1048 115.507 36.4861 115.522C51.6248 115.541 72.564 115.565 88.0396 115.565C99.9705 115.565 108.649 115.541 108.906 115.492"
        stroke="#3C0789"
        stroke-linejoin="round"
      />
    </svg>
  );
};
