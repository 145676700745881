import { IconCopy, IconPencil } from '@tabler/icons-react';
import { ThreeDotsIcon } from '@zorro/shared/assets';
import {
  ZORRO_UI_DYNAMIC_ROUTES,
  createImpersonationSession,
  getFullName,
  showSuccessNotification,
  useMonolithQuery,
  useRoles,
} from '@zorro/shared/utils';
import { rolesLabelConfig } from '@zorro/types';
import {
  ActionIcon,
  Box,
  Divider,
  Group,
  Icon,
  Menu,
  Stack,
  Text,
  brand,
  useDisclosure,
} from '@zorro/zorro-ui-design';
import CopyToClipboard from 'react-copy-to-clipboard';

import { EligibilityStatusBadge } from '../../EligibilityStatusBadge';
import { resetPassword } from '../../EmployeeActions/AccountManagement/accountManagement.utils';
import { UserAvatar } from '../../UserAvatar';
import { EmployeeLayoutAction } from '../EmployeeLayoutActions';
import { getEmployeeRole } from '../EmployeeLayoutUtils';
import { EmployeeLayoutSidebarHeaderSkeleton } from './EmployeeLayoutSidebarHeaderSkeleton';

type Props = {
  employerId: string;
  employeeId: string;
  setAction: (action: EmployeeLayoutAction) => void;
};

export const EmployeeLayoutSidebarHeader = ({
  employerId,
  employeeId,
  setAction,
}: Props) => {
  const { isZorroOperations } = useRoles();
  const [opened, { toggle }] = useDisclosure(false);

  const { data: employee, isLoading: isLoadingEmployee } = useMonolithQuery({
    method: 'employeesControllerFindOne',
    params: [employeeId],
  });

  const { data: userRoles, isLoading: isLoadingRoles } = useMonolithQuery({
    method: 'usersControllerGetUserRoles',
    params: employee && [employerId, employee.userId],
  });

  if (!employee || !userRoles || isLoadingEmployee || isLoadingRoles) {
    return <EmployeeLayoutSidebarHeaderSkeleton />;
  }

  const role = getEmployeeRole(userRoles);

  return (
    <Stack
      bg="zorroWhite.0"
      p="md"
      pt="lg"
      mb="lg"
      gap="sm"
      top={0}
      pos="sticky"
      style={{ borderRadius: 'var(--mantine-radius-lg)', zIndex: 5 }}
    >
      <Group align="start">
        <UserAvatar
          firstName={employee.firstName}
          lastName={employee.lastName}
          color="zorroGreen.0"
          size={44}
          textSize={16}
        />
        <Stack gap="0">
          <Text fw={600} size="sm">
            {getFullName(employee)}
          </Text>
          <Group gap="xs">
            <Text c="zorroGray.4" size="sm">
              {employee.shortId}
            </Text>
            <CopyToClipboard
              text={employee.shortId}
              onCopy={() =>
                showSuccessNotification({
                  message: 'Zorro ID copied to clipboard',
                })
              }
            >
              <ActionIcon size="sm">
                <IconCopy
                  size={16}
                  cursor="pointer"
                  color={brand.zorroGray400}
                />
              </ActionIcon>
            </CopyToClipboard>
          </Group>
        </Stack>
        <Box ml="auto" id="userMenu">
          <Menu shadow="md" width={200} opened={opened} onChange={toggle}>
            <Menu.Target>
              <ActionIcon isChecked={opened}>
                <ThreeDotsIcon />
              </ActionIcon>
            </Menu.Target>

            <Menu.Dropdown>
              <Menu.Item
                onClick={() =>
                  setAction(EmployeeLayoutAction.EMAIL_ACCOUNT_INVITATION)
                }
              >
                Send account invitation
              </Menu.Item>
              <Menu.Item
                onClick={() =>
                  createImpersonationSession(
                    employeeId,
                    ZORRO_UI_DYNAMIC_ROUTES.MY_COVERAGE()
                  )
                }
              >
                Log in as
              </Menu.Item>
              <Menu.Item
                onClick={() =>
                  setAction(EmployeeLayoutAction.EDIT_COMPANY_EMAIL)
                }
              >
                Edit company email
              </Menu.Item>
              {isZorroOperations && (
                <Menu.Item onClick={() => resetPassword(employee.userId)}>
                  Edit account password
                </Menu.Item>
              )}
              <Menu.Item
                onClick={() => setAction(EmployeeLayoutAction.DELETE_EMPLOYEE)}
              >
                Delete employee
              </Menu.Item>
            </Menu.Dropdown>
          </Menu>
        </Box>
      </Group>
      <Divider w="100%" />
      <Stack gap={0}>
        <Group justify="space-between">
          <Text c="zorroGray.4" size="sm">
            Role
          </Text>
          <Group gap="xs">
            <Text size="sm">{rolesLabelConfig[role]}</Text>
            <ActionIcon
              onClick={() => setAction(EmployeeLayoutAction.EDIT_EMPLOYEE_ROLE)}
            >
              <Icon icon={IconPencil} color={brand.zorroGray400} size={20} />
            </ActionIcon>
          </Group>
        </Group>
        <Group justify="space-between">
          <Text c="zorroGray.4" size="sm">
            Employee ID
          </Text>
          <Group gap="xs">
            <Text size="sm">{employee.idFromEmployer || '-'}</Text>
            <ActionIcon
              onClick={() =>
                setAction(EmployeeLayoutAction.EDIT_ID_FROM_EMPLOYER)
              }
            >
              <Icon icon={IconPencil} color={brand.zorroGray400} size={20} />
            </ActionIcon>
          </Group>
        </Group>
      </Stack>
      <EligibilityStatusBadge setAction={setAction} employeeId={employeeId} />
    </Stack>
  );
};
