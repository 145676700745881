import { Skeleton, SkeletonProps } from '@mantine/core';

export type ISkeletonProps = SkeletonProps & {
  borderRadius?: number;
};

export function ZorroSkeleton({
  borderRadius = 32,
  style,
  ...props
}: ISkeletonProps) {
  return <Skeleton {...props} style={{ borderRadius, ...style }} />;
}
