/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum SearchResultType {
  EMPLOYER = 'EMPLOYER',
  PROSPECT = 'PROSPECT',
  EMPLOYEE = 'EMPLOYEE',
  AGENCY = 'AGENCY',
  AGENT = 'AGENT',
}
