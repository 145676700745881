import {
  IconBriefcase,
  IconChevronRight,
  IconTarget,
  IconTopologyStar,
  IconUser,
} from '@tabler/icons-react';
import {
  EmployerSignupStatus,
  SearchResultDto,
  SearchResultType,
} from '@zorro/clients';
import { ImagePlaceholderIcon, SearchResultsIcon } from '@zorro/shared/assets';
import {
  MURRIETA_DYNAMIC_ROUTES,
  getRouteForEmployerStatus,
  useDynamicRouter,
  useRoles,
} from '@zorro/shared/utils';
import {
  Center,
  Group,
  Icon,
  Stack,
  Text,
  brand,
} from '@zorro/zorro-ui-design';
import { ElementType, ReactElement } from 'react';
import { $enum } from 'ts-enum-util';

import { EmployerLogo } from '../../../../EmployerLogo';
import { useAppLayout } from '../../../AppLayoutContext';
import classNames from './AppLayoutHeaderSearchResults.module.scss';
import { AppLayoutHeaderSearchResultsSkeleton } from './AppLayoutHeaderSearchResultsSkeleton';

const getSearchResultTypeConfig = ({
  id,
  searchResultType,
  signupStatus = EmployerSignupStatus.DRAFT,
  parentName,
  parentId = '',
}: SearchResultDto) => {
  return $enum.mapValue(searchResultType).with<{
    label: ReactElement;
    icon: ElementType;
    href: string;
  }>({
    [SearchResultType.EMPLOYER]: {
      href: MURRIETA_DYNAMIC_ROUTES[getRouteForEmployerStatus(signupStatus)](
        id
      ),
      icon: IconBriefcase,
      label: (
        <Text size="sm" c="zorroGray.4">
          Employers
        </Text>
      ),
    },
    [SearchResultType.PROSPECT]: {
      href: MURRIETA_DYNAMIC_ROUTES.QUOTES({ employerId: id }),
      icon: IconTarget,
      label: (
        <Text size="sm" c="zorroGray.4">
          Prospects
        </Text>
      ),
    },
    [SearchResultType.EMPLOYEE]: {
      href: MURRIETA_DYNAMIC_ROUTES.EMPLOYEE_PAGE({
        employerId: parentId,
        employeeId: id,
      }),
      icon: IconUser,
      label: (
        <Group gap="xs">
          <Text size="sm" c="zorroGray.4">
            Employers
          </Text>
          <Icon icon={IconChevronRight} color={brand.zorroGray400} />
          <Text size="sm" c="zorroGray.4">
            {parentName}
          </Text>
          <Icon icon={IconChevronRight} color={brand.zorroGray400} />
          <Text size="sm" c="zorroGray.4">
            Employees
          </Text>
        </Group>
      ),
    },
    [SearchResultType.AGENCY]: {
      href: MURRIETA_DYNAMIC_ROUTES.AGENCY_SETTINGS(id),
      icon: IconTopologyStar,
      label: (
        <Text size="sm" c="zorroGray.4">
          Agencies
        </Text>
      ),
    },
    [SearchResultType.AGENT]: {
      href: MURRIETA_DYNAMIC_ROUTES.AGENCY_SETTINGS(parentId, 'users'),
      icon: IconUser,
      label: (
        <Group gap="xs">
          <Text size="sm" c="zorroGray.4">
            Agencies
          </Text>
          <Icon icon={IconChevronRight} color={brand.zorroGray400} />
          <Text size="sm" c="zorroGray.4">
            {parentName}
          </Text>
          <Icon icon={IconChevronRight} color={brand.zorroGray400} />
          <Text size="sm" c="zorroGray.4">
            Agents
          </Text>
        </Group>
      ),
    },
  });
};

type Props = {
  searchResults?: SearchResultDto[];
  isLoading: boolean;
};

export const AppLayoutHeaderSearchResults = ({
  searchResults,
  isLoading,
}: Props) => {
  const { router } = useDynamicRouter({});
  const { toggleSearch } = useAppLayout();
  const { isZorroOperations, isAccountSupervisor } = useRoles();

  const handleResultClick = (href: string, isChecked: boolean) => {
    toggleSearch();
    if (isChecked) {
      router.reload();
    } else {
      router.push(href);
    }
  };

  if (isLoading) {
    return (
      <>
        <Text c="zorroGray.4">Results</Text>
        <AppLayoutHeaderSearchResultsSkeleton />;
      </>
    );
  }

  if (!searchResults) {
    return null;
  }

  if (searchResults.length === 0) {
    return (
      <>
        <Text c="zorroGray.4">Results</Text>
        <Stack mt="4rem" align="center" gap={0}>
          <SearchResultsIcon />
          <Text fw={600} mt="xs">
            No results found
          </Text>
          <Text c="zorroGray.4">Please try another search term</Text>
        </Stack>
      </>
    );
  }

  const filteredSearchResults = searchResults.filter(({ searchResultType }) => {
    if (isZorroOperations) {
      return true;
    }

    if (
      searchResultType === SearchResultType.AGENCY ||
      searchResultType === SearchResultType.AGENT
    ) {
      return false;
    } else if (searchResultType === SearchResultType.PROSPECT) {
      return isAccountSupervisor;
    }

    return true;
  });

  return (
    <>
      <Text c="zorroGray.4">Results</Text>
      <Stack mb="2rem" h="20rem" style={{ overflowY: 'auto' }}>
        <Stack gap={0}>
          {filteredSearchResults.map((searchResult) => {
            const { label, icon, href } =
              getSearchResultTypeConfig(searchResult);
            const isChecked = router.asPath.includes(href);

            return (
              <Group
                onClick={() => handleResultClick(href, isChecked)}
                data-checked={isChecked}
                classNames={classNames}
                key={searchResult.id}
              >
                <Center
                  w="7.25rem"
                  h="4.25rem"
                  bg="zorroWhite.0"
                  style={(theme) => ({
                    border: `1px solid ${theme.colors.zorroGray[2]}`,
                    borderRadius: theme.radius.md,
                    overflow: 'hidden',
                  })}
                >
                  {searchResult.logoUrl ? (
                    <EmployerLogo logoUrl={searchResult.logoUrl} h="3.5rem" />
                  ) : (
                    <ImagePlaceholderIcon />
                  )}
                </Center>
                <Stack gap={0}>
                  <Group gap="0.25rem">
                    <Icon icon={icon} />
                    <Text fw={600}>{searchResult.name}</Text>
                  </Group>
                  {label}
                </Stack>
              </Group>
            );
          })}
        </Stack>
      </Stack>
    </>
  );
};
