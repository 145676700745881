import { DocumentType, EmployerDocumentDto } from '@zorro/clients';
import { AddIcon, CancelIcon } from '@zorro/shared/assets';
import { useMonolithQuery, useRoles } from '@zorro/shared/utils';
import { DeepReadonly } from '@zorro/types';
import {
  Button,
  Drawer,
  Group,
  Modal,
  Space,
  Text,
  Title,
} from '@zorro/zorro-ui-design';
import { Fragment, useState } from 'react';

import { FullPageLoader } from '../FullPageLoader';
import { ModalTitle } from '../ModalComponents';
import { DeleteDocumentConfirmationForm } from './DeleteDocumentConfirmationForm';
import { EmployerDocumentCard } from './EmployerDocumentCard';
import { EmployerDocumentForm } from './EmployerDocumentForm';
import { EmptyEmployerDocumentsSection } from './EmptyEmployerDocumentsSection';

type Props = {
  employerId: string;
  documentType: DocumentType;
  openEnrollmentPeriodId: string | null;
};

const VISIBLE_TO_ADMINS_ONLY = '(visible to admins only)';

const documentTypeConfig: DeepReadonly<{
  [documentType in DocumentType]: {
    label: string;
    visibleTo: string;
    uploadTitleSuffix: string;
  };
}> = {
  [DocumentType.ADMIN]: {
    label: 'Admin documents',
    visibleTo: VISIBLE_TO_ADMINS_ONLY,
    uploadTitleSuffix: 'admin document',
  },
  [DocumentType.ZORRO_PAY]: {
    label: 'Payment reports',
    visibleTo: VISIBLE_TO_ADMINS_ONLY,
    uploadTitleSuffix: 'payment report',
  },
  [DocumentType.PLAN]: {
    label: 'Plan documents',
    visibleTo: '(visible to everyone in the company)',
    uploadTitleSuffix: 'plan document',
  },
  [DocumentType.COMPLIANCE]: {
    label: 'Compliance reports',
    visibleTo: VISIBLE_TO_ADMINS_ONLY,
    uploadTitleSuffix: 'compliance report',
  },
  [DocumentType.INVOICE]: {
    label: 'Invoices',
    visibleTo: VISIBLE_TO_ADMINS_ONLY,
    uploadTitleSuffix: 'invoice',
  },
};

const getUploadDrawerTitle = (
  documentType: DocumentType,
  isEditMode: boolean
) => {
  const prefix = isEditMode ? 'Edit' : 'Add a new';
  const { uploadTitleSuffix } = documentTypeConfig[documentType];
  return `${prefix} ${uploadTitleSuffix}`;
};

const getIsEditable = (
  isZorroOperations: boolean,
  isAccountSupervisor: boolean,
  documentType: DocumentType
) => {
  if (
    documentType === DocumentType.ZORRO_PAY ||
    documentType === DocumentType.COMPLIANCE ||
    documentType === DocumentType.INVOICE
  ) {
    return isZorroOperations;
  }
  return isZorroOperations || isAccountSupervisor;
};

export const DocumentsPage = ({
  employerId,
  documentType,
  openEnrollmentPeriodId,
}: Props) => {
  const { isZorroOperations, isAccountSupervisor } = useRoles();

  const isEditable = getIsEditable(
    isZorroOperations,
    isAccountSupervisor,
    documentType
  );

  const [selectedDocument, setSelectedDocument] =
    useState<EmployerDocumentDto>();
  const [isDeleteDocumentsModalOpen, setIsDeleteDocumentsModalOpen] =
    useState(false);
  const [isUploadDocumentModalOpen, setIsUploadDocumentModalOpen] =
    useState(false);
  const [uploadedFileFromEmptyState, setUploadedFileFromEmptyState] = useState<
    File | undefined
  >(undefined);

  const { data: documents, isLoading: isDocumentLoading } = useMonolithQuery({
    method: 'employerDocumentControllerGetDocumentsByType',
    params: [employerId, documentType],
  });

  if (isDocumentLoading || !documents) {
    return <FullPageLoader />;
  }

  const filteredDocuments = documents
    .filter(
      (document) => document.openEnrollmentPeriodId === openEnrollmentPeriodId
    )
    .sort((first, second) => second.createdAt.localeCompare(first.createdAt));

  const { label } = documentTypeConfig[documentType];

  const resetModalsAndSelections = () => {
    setSelectedDocument(undefined);
    setUploadedFileFromEmptyState(undefined);
    setIsUploadDocumentModalOpen(false);
    setIsDeleteDocumentsModalOpen(false);
  };

  return (
    <>
      {filteredDocuments.length > 0 && (
        <Group justify="space-between">
          <Group wrap="nowrap" gap="xxs">
            <Title order={4} fw={600}>
              {label}
            </Title>
            <Text c="zorroGray.4" fw={400}>
              {documentTypeConfig[documentType].visibleTo}
            </Text>
          </Group>
          {isEditable && (
            <Button
              variant="subtle"
              p={0}
              onClick={() => setIsUploadDocumentModalOpen(true)}
            >
              <AddIcon />
            </Button>
          )}
        </Group>
      )}
      {filteredDocuments.length === 0 && (
        <EmptyEmployerDocumentsSection
          label={label}
          isEditable={isEditable}
          visibleTo={documentTypeConfig[documentType].visibleTo}
          onUpload={(file) => {
            setUploadedFileFromEmptyState(file);
            setIsUploadDocumentModalOpen(true);
          }}
        />
      )}
      <Space h="xl" />
      {filteredDocuments.map((document) => (
        <Fragment key={document.id}>
          <EmployerDocumentCard
            document={document}
            isEditable={isEditable}
            onEdit={() => {
              setSelectedDocument(document);
              setIsUploadDocumentModalOpen(true);
            }}
            onDelete={() => {
              setSelectedDocument(document);
              setIsDeleteDocumentsModalOpen(true);
            }}
          />
          <Space h="md" />
        </Fragment>
      ))}

      <Drawer
        opened={isUploadDocumentModalOpen}
        onClose={resetModalsAndSelections}
        title={getUploadDrawerTitle(documentType, !!selectedDocument)}
      >
        <EmployerDocumentForm
          employerId={employerId}
          type={documentType}
          existingDocument={selectedDocument}
          viewedOpenEnrollmentPeriodId={openEnrollmentPeriodId ?? undefined}
          onSuccess={resetModalsAndSelections}
          onCancel={resetModalsAndSelections}
          uploadedFile={uploadedFileFromEmptyState}
        />
      </Drawer>
      <Modal
        size="lg"
        opened={isDeleteDocumentsModalOpen}
        onClose={resetModalsAndSelections}
        showSeparator={false}
        title={
          <ModalTitle
            title="Delete document"
            icon={<CancelIcon />}
            titleOrder={2}
          />
        }
      >
        <DeleteDocumentConfirmationForm
          selectedDocument={selectedDocument}
          employerId={employerId}
          onClose={resetModalsAndSelections}
          onSubmit={resetModalsAndSelections}
        />
      </Modal>
    </>
  );
};
