import { Center } from '@zorro/zorro-ui-design';

import { SVGIconProps } from '../types';
import classNames from './Icon.module.scss';

export const SearchResultsIcon = ({ style, grow }: SVGIconProps) => {
  return (
    <Center style={style} classNames={classNames} data-grow={grow}>
      <svg
        width="86"
        height="86"
        viewBox="0 0 86 86"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="86" height="86" fill="white" />
        <rect
          x="0.5"
          y="0.5"
          width="85"
          height="21"
          rx="3.5"
          stroke="#EDEFF6"
        />
        <rect x="6" y="6" width="18" height="10" rx="2" fill="#EDEFF6" />
        <rect x="32" y="9" width="46" height="4" rx="2" fill="#EDEFF6" />
        <rect
          x="0.5"
          y="26.5"
          width="85"
          height="21"
          rx="3.5"
          stroke="#EDEFF6"
        />
        <rect x="6" y="32" width="18" height="10" rx="2" fill="#EDEFF6" />
        <rect x="32" y="35" width="46" height="4" rx="2" fill="#EDEFF6" />
        <rect
          x="0.5"
          y="52.5"
          width="85"
          height="21"
          rx="3.5"
          stroke="#EDEFF6"
        />
        <rect x="6" y="58" width="18" height="10" rx="2" fill="#EDEFF6" />
        <rect x="32" y="61" width="46" height="4" rx="2" fill="#EDEFF6" />
        <rect
          x="58.5"
          y="58.5"
          width="27"
          height="27"
          rx="13.5"
          fill="#EDEFF6"
          stroke="white"
        />
        <path
          d="M78 78L74 74M66 70.6667C66 71.2795 66.1207 71.8863 66.3552 72.4525C66.5898 73.0187 66.9335 73.5332 67.3668 73.9665C67.8002 74.3998 68.3146 74.7436 68.8808 74.9781C69.447 75.2126 70.0538 75.3333 70.6667 75.3333C71.2795 75.3333 71.8863 75.2126 72.4525 74.9781C73.0187 74.7436 73.5332 74.3998 73.9665 73.9665C74.3998 73.5332 74.7436 73.0187 74.9781 72.4525C75.2126 71.8863 75.3333 71.2795 75.3333 70.6667C75.3333 70.0538 75.2126 69.447 74.9781 68.8808C74.7436 68.3146 74.3998 67.8002 73.9665 67.3668C73.5332 66.9335 73.0187 66.5898 72.4525 66.3552C71.8863 66.1207 71.2795 66 70.6667 66C70.0538 66 69.447 66.1207 68.8808 66.3552C68.3146 66.5898 67.8002 66.9335 67.3668 67.3668C66.9335 67.8002 66.5898 68.3146 66.3552 68.8808C66.1207 69.447 66 70.0538 66 70.6667Z"
          stroke="#676979"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </Center>
  );
};
