/* eslint-disable react/boolean-prop-naming */
import '@mantine/core/styles.css';
import { useViewportSize } from '@mantine/hooks';
import { DataTable, DataTableProps } from 'mantine-datatable';
import 'mantine-datatable/styles.css';

import { ZorroLoader } from '../../Loader';
import { ZorroStack } from '../../Stack';
import { ZorroText } from '../../Text';
import { brand } from '../../theme';
import { EmptyIcon } from './EmptyIcon';
import classNames from './Table.module.scss';

const EMPTY_TABLE_HEIGHT = 250;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type ITableProps<T = any> = DataTableProps<T> & {
  isSelectable?: boolean;
  pinFirstColumn?: boolean;
  reduceHeightBy?: number;
  grow?: boolean;
  fetching?: boolean;
};

// TODO: move svg to own component.
export function ZorroTable({
  withTableBorder = true,
  withRowBorders = true,
  records = [],
  height: heightProp = records?.length === 0 ? EMPTY_TABLE_HEIGHT : 'unset',
  minHeight = 150,
  horizontalSpacing = 'md',
  verticalSpacing = 'sm',
  withColumnBorders = true,
  noRecordsText = 'No records to show',
  pinFirstColumn = true,
  reduceHeightBy = 350,
  isSelectable = false,
  fetching = false,
  groups,
  grow,
  ...props
}: ITableProps) {
  const { height: viewportHeight } = useViewportSize();

  return (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    <DataTable
      {...props}
      withTableBorder={withTableBorder}
      withRowBorders={withRowBorders}
      records={records}
      groups={groups}
      height={grow ? viewportHeight - reduceHeightBy : heightProp}
      // Don't set a minHeight when we have data, but add default minHeight of 150px for empty state
      minHeight={records.length > 0 ? undefined : minHeight}
      borderRadius="md"
      horizontalSpacing={horizontalSpacing}
      verticalSpacing={verticalSpacing}
      withColumnBorders={withColumnBorders}
      rowClassName={classNames.row}
      data-is-selectable={isSelectable}
      data-pin-first-column={pinFirstColumn}
      data-has-groups={groups && groups.length > 0}
      customRowAttributes={() => ({
        'data-is-selectable': isSelectable,
        'data-pin-first-column': pinFirstColumn,
      })}
      paginationActiveBackgroundColor={brand.primaryButtonSelected}
      paginationActiveTextColor={brand.zorroCoal900}
      classNames={classNames}
      emptyState={
        records?.length === 0 ? (
          <ZorroStack gap="xs">
            <EmptyIcon />
            <ZorroText>{noRecordsText}</ZorroText>
          </ZorroStack>
        ) : (
          <div />
        )
      }
      noRecordsIcon={undefined}
      fetching={fetching}
      customLoader={<ZorroLoader />}
    />
  );
}
