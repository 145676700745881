import { CompanyOverviewDto, EnrollmentStatus } from '@zorro/clients';
import { Box, Card, Grid, Group, Space, Text } from '@zorro/zorro-ui-design';

import { EnrollmentStatusIconTooltip } from '../EnrollmentStatus/EnrollmentStatusIconTooltip';
import { enrollmentStatusConfig } from '../EnrollmentStatus/EnrollmentStatusUtils';

type Props = {
  eligibleEmployeesCount: number;
  currentCoverageCountByStatus: CompanyOverviewDto['currentCoverageCountByStatus'];
};

const coverageIcons = [
  EnrollmentStatus.ACTIVE_COVERAGE,
  EnrollmentStatus.WAIVED_COVERAGE,
  EnrollmentStatus.COVERAGE_ENDED,
];

export const CompanyCoverageOverview = ({
  eligibleEmployeesCount,
  currentCoverageCountByStatus,
}: Props) => {
  return (
    <Box>
      <Text fw={600} size="md">
        Current coverage
        <Text c="zorroGray.4" ml="xs">
          {eligibleEmployeesCount} currently eligible
        </Text>
      </Text>
      <Space h="sm" />
      <Grid>
        {coverageIcons.map((enrollmentStatus) => (
          <Grid.Col key={enrollmentStatus} span={4}>
            <Card radius="md">
              <Group mb="lg" justify="space-between" align="center">
                <Text size="24px" fw="bold">
                  {currentCoverageCountByStatus[enrollmentStatus] || 0}
                </Text>
                <EnrollmentStatusIconTooltip
                  enrollmentStatus={enrollmentStatus}
                />
              </Group>
              <Text size="md">
                {enrollmentStatusConfig[enrollmentStatus].label}
              </Text>
            </Card>
          </Grid.Col>
        ))}
      </Grid>
    </Box>
  );
};
