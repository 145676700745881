/* generated using openapi-typescript-codegen -- do not edit */

/* istanbul ignore file */

/* tslint:disable */

/* eslint-disable */
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { AddressValidationRequest } from '../models/AddressValidationRequest';
import type { AddressValidationResponse } from '../models/AddressValidationResponse';
import type { AgencyDto } from '../models/AgencyDto';
import type { AgencyReportRowDto } from '../models/AgencyReportRowDto';
import type { AgentDto } from '../models/AgentDto';
import type { AgentReportRowDto } from '../models/AgentReportRowDto';
import type { AllAgenciesDto } from '../models/AllAgenciesDto';
import type { AllowanceModelDto } from '../models/AllowanceModelDto';
import type { BenefitDocumentDto } from '../models/BenefitDocumentDto';
import type { BenefitDto } from '../models/BenefitDto';
import type { BenefitEnrollmentDto } from '../models/BenefitEnrollmentDto';
import type { CarePreferencesDto } from '../models/CarePreferencesDto';
import type { CompanyOverviewDto } from '../models/CompanyOverviewDto';
import type { CreateActivationLinkDto } from '../models/CreateActivationLinkDto';
import type { CreateAgencyDto } from '../models/CreateAgencyDto';
import type { CreateAgentDto } from '../models/CreateAgentDto';
import type { CreateBenefitDocumentDto } from '../models/CreateBenefitDocumentDto';
import type { CreateEmployeeDto } from '../models/CreateEmployeeDto';
import type { CreateEmployeePaymentMethodPayloadDto } from '../models/CreateEmployeePaymentMethodPayloadDto';
import type { CreateEmployerContactDto } from '../models/CreateEmployerContactDto';
import type { CreateEmployerDocumentDto } from '../models/CreateEmployerDocumentDto';
import type { CreateEmployerDto } from '../models/CreateEmployerDto';
import type { CreateManyUsersDto } from '../models/CreateManyUsersDto';
import type { CreateOnboardingPeriodByAdminDto } from '../models/CreateOnboardingPeriodByAdminDto';
import type { CreateOpenEnrollmentPeriodDto } from '../models/CreateOpenEnrollmentPeriodDto';
import type { CreateQualifyingLifeEventDto } from '../models/CreateQualifyingLifeEventDto';
import type { CreateQuoteDto } from '../models/CreateQuoteDto';
import type { CreateUserDto } from '../models/CreateUserDto';
import type { Drug } from '../models/Drug';
import type { EmailDto } from '../models/EmailDto';
import type { EmployeeClassesDto } from '../models/EmployeeClassesDto';
import type { EmployeeCount } from '../models/EmployeeCount';
import type { EmployeeDto } from '../models/EmployeeDto';
import type { EmployeeInfoDto } from '../models/EmployeeInfoDto';
import type { EmployeePaymentMethodDetailsDto } from '../models/EmployeePaymentMethodDetailsDto';
import type { EmployeePaymentMethodDetailsPayloadDto } from '../models/EmployeePaymentMethodDetailsPayloadDto';
import type { EmployeePaymentMethodDto } from '../models/EmployeePaymentMethodDto';
import type { EmployerContactsByType } from '../models/EmployerContactsByType';
import type { EmployerContributionDto } from '../models/EmployerContributionDto';
import type { EmployerDocumentDto } from '../models/EmployerDocumentDto';
import type { EmployerDto } from '../models/EmployerDto';
import type { EmployerPublicInfoDto } from '../models/EmployerPublicInfoDto';
import type { EmployerReportRowDto } from '../models/EmployerReportRowDto';
import type { EnrollmentConfirmationDto } from '../models/EnrollmentConfirmationDto';
import type { EnrollmentInstructionsDto } from '../models/EnrollmentInstructionsDto';
import type { EventDto } from '../models/EventDto';
import type { FileDto } from '../models/FileDto';
import type { FinchConnectEmployerStatusDto } from '../models/FinchConnectEmployerStatusDto';
import type { FinchConnectPayloadDto } from '../models/FinchConnectPayloadDto';
import type { FinchConnectResponseDto } from '../models/FinchConnectResponseDto';
import type { FinchEmployeeDto } from '../models/FinchEmployeeDto';
import type { FinchFeatureManagePayloadDto } from '../models/FinchFeatureManagePayloadDto';
import type { FinchFeatureStatusResponseDto } from '../models/FinchFeatureStatusResponseDto';
import type { FinchPaymentDto } from '../models/FinchPaymentDto';
import type { FindLastUpdatedAgenciesDto } from '../models/FindLastUpdatedAgenciesDto';
import type { FindLastUpdatedEmployeesDto } from '../models/FindLastUpdatedEmployeesDto';
import type { FindLastUpdatedEmployersDto } from '../models/FindLastUpdatedEmployersDto';
import type { GenerateQuoteOutputDto } from '../models/GenerateQuoteOutputDto';
import type { GenerateQuoteResultDto } from '../models/GenerateQuoteResultDto';
import type { GetAllowanceModelDto } from '../models/GetAllowanceModelDto';
import type { GetEmployerDto } from '../models/GetEmployerDto';
import type { GetPlanSearchModeDto } from '../models/GetPlanSearchModeDto';
import type { GetUserRolesDto } from '../models/GetUserRolesDto';
import type { InsuredPeopleDto } from '../models/InsuredPeopleDto';
import type { IntercomUserHashPayloadDto } from '../models/IntercomUserHashPayloadDto';
import type { IntercomUserHashResponseDto } from '../models/IntercomUserHashResponseDto';
import type { LifestyleDto } from '../models/LifestyleDto';
import type { MajorMedicalCarrierDto } from '../models/MajorMedicalCarrierDto';
import type { MedicalPlan } from '../models/MedicalPlan';
import type { MedicalPlanForEnrollment } from '../models/MedicalPlanForEnrollment';
import type { MedicalPlanSearchRequest } from '../models/MedicalPlanSearchRequest';
import type { OnboardingPeriodBenefitsDto } from '../models/OnboardingPeriodBenefitsDto';
import type { OnboardingPeriodBenefitsRenewalDto } from '../models/OnboardingPeriodBenefitsRenewalDto';
import type { OnboardingPeriodDto } from '../models/OnboardingPeriodDto';
import type { OnboardingPeriodReportDto } from '../models/OnboardingPeriodReportDto';
import type { OpenEnrollmentPeriodDto } from '../models/OpenEnrollmentPeriodDto';
import type { PaginatedEmployeeReportRowDto } from '../models/PaginatedEmployeeReportRowDto';
import type { PaginatedEmployerOnboardingReportDto } from '../models/PaginatedEmployerOnboardingReportDto';
import type { PaginatedPayrollReportRowDto } from '../models/PaginatedPayrollReportRowDto';
import type { PasswordResetDto } from '../models/PasswordResetDto';
import type { PaymentsMigrationDto } from '../models/PaymentsMigrationDto';
import type { PaymentsMigrationPayloadDto } from '../models/PaymentsMigrationPayloadDto';
import type { PlanExclusionFilter } from '../models/PlanExclusionFilter';
import type { PlanIssuerDto } from '../models/PlanIssuerDto';
import type { PlanSearchSelfDto } from '../models/PlanSearchSelfDto';
import type { PlanYearEmployeeRenewalReportDto } from '../models/PlanYearEmployeeRenewalReportDto';
import type { PossibleEmployerContributionDto } from '../models/PossibleEmployerContributionDto';
import type { ProspectReportRowDto } from '../models/ProspectReportRowDto';
import type { Provider } from '../models/Provider';
import type { QualifyingLifeEventDto } from '../models/QualifyingLifeEventDto';
import type { QuoteDto } from '../models/QuoteDto';
import type { QuotePreferenceDto } from '../models/QuotePreferenceDto';
import type { RecommendationDataDto } from '../models/RecommendationDataDto';
import type { RecommendationDataInputDto } from '../models/RecommendationDataInputDto';
import type { RecommendationDataSearchDto } from '../models/RecommendationDataSearchDto';
import type { ReportSupportIssuePayloadDto } from '../models/ReportSupportIssuePayloadDto';
import type { SearchMajorMedicalCarriersPayloadDto } from '../models/SearchMajorMedicalCarriersPayloadDto';
import type { SearchResultDto } from '../models/SearchResultDto';
import type { SelectedSupplementalBenefitDto } from '../models/SelectedSupplementalBenefitDto';
import type { UpdateAgencyDto } from '../models/UpdateAgencyDto';
import type { UpdateAgencyNameDto } from '../models/UpdateAgencyNameDto';
import type { UpdateAgentDto } from '../models/UpdateAgentDto';
import type { UpdateBenefitDocumentDto } from '../models/UpdateBenefitDocumentDto';
import type { UpdateBenefitEnrollmentDto } from '../models/UpdateBenefitEnrollmentDto';
import type { UpdateBenefitsDto } from '../models/UpdateBenefitsDto';
import type { UpdateEligibilityDto } from '../models/UpdateEligibilityDto';
import type { UpdateEmployeeDto } from '../models/UpdateEmployeeDto';
import type { UpdateEmployeeInfoDto } from '../models/UpdateEmployeeInfoDto';
import type { UpdateEmployeeNameDto } from '../models/UpdateEmployeeNameDto';
import type { UpdateEmployeePaymentMethodAssociatedBenefitsPayloadDto } from '../models/UpdateEmployeePaymentMethodAssociatedBenefitsPayloadDto';
import type { UpdateEmployeePaymentMethodPayloadDto } from '../models/UpdateEmployeePaymentMethodPayloadDto';
import type { UpdateEmployerDocumentDto } from '../models/UpdateEmployerDocumentDto';
import type { UpdateEmployerDto } from '../models/UpdateEmployerDto';
import type { UpdateEmployerNameDto } from '../models/UpdateEmployerNameDto';
import type { UpdateExternalMajorMedicalPlanDto } from '../models/UpdateExternalMajorMedicalPlanDto';
import type { UpdateMajorMedicalCarriersDto } from '../models/UpdateMajorMedicalCarriersDto';
import type { UpdateMajorMedicalPlanDto } from '../models/UpdateMajorMedicalPlanDto';
import type { UpdateOnboardingPeriodByAdminDto } from '../models/UpdateOnboardingPeriodByAdminDto';
import type { UpdateOpenEnrollmentPeriodDto } from '../models/UpdateOpenEnrollmentPeriodDto';
import type { UpdateQuoteDto } from '../models/UpdateQuoteDto';
import type { UpdateSelfReportMajorMedicalPlanDto } from '../models/UpdateSelfReportMajorMedicalPlanDto';
import type { UpdateUserRolesDto } from '../models/UpdateUserRolesDto';
import type { UpsertAllowanceModelDto } from '../models/UpsertAllowanceModelDto';
import type { UpsertEnrollmentInstructionsDto } from '../models/UpsertEnrollmentInstructionsDto';
import type { UpsertInsuredPeopleDto } from '../models/UpsertInsuredPeopleDto';
import type { UpsertManyEmployeesRequest } from '../models/UpsertManyEmployeesRequest';
import type { UpsertManyEmployeesResult } from '../models/UpsertManyEmployeesResult';
import type { UpsertManyUsersResponseDto } from '../models/UpsertManyUsersResponseDto';
import type { UserAndTenantDto } from '../models/UserAndTenantDto';
import type { UserDto } from '../models/UserDto';
import type { UserDtoWithRoles } from '../models/UserDtoWithRoles';
import type { UserIdsDto } from '../models/UserIdsDto';
import type { UserStatusDto } from '../models/UserStatusDto';
import type { UsersRolesDto } from '../models/UsersRolesDto';
import type { WaiveCoverageDto } from '../models/WaiveCoverageDto';

export class MonolithService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}
  /**
   * @param requestBody
   * @returns AddressValidationResponse
   * @throws ApiError
   */
  public addressControllerValidateAddress(
    requestBody: AddressValidationRequest
  ): CancelablePromise<AddressValidationResponse> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/address',
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * @param requestBody
   * @returns BenefitDocumentDto
   * @throws ApiError
   */
  public benefitDocumentsControllerCreateBenefitDocument(
    requestBody: CreateBenefitDocumentDto
  ): CancelablePromise<BenefitDocumentDto> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/benefit-documents',
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * @param periodId
   * @returns BenefitDocumentDto
   * @throws ApiError
   */
  public benefitDocumentsControllerGetBenefitDocumentsByOnboardingPeriod(
    periodId: string
  ): CancelablePromise<Array<BenefitDocumentDto>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/onboarding-periods/{periodId}/benefit-documents',
      path: {
        periodId: periodId,
      },
    });
  }
  /**
   * @param benefitDocumentId
   * @param requestBody
   * @returns any
   * @throws ApiError
   */
  public benefitDocumentsControllerUpdateBenefitDocument(
    benefitDocumentId: string,
    requestBody: UpdateBenefitDocumentDto
  ): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/benefit-documents/{benefitDocumentId}',
      path: {
        benefitDocumentId: benefitDocumentId,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * @param benefitDocumentId
   * @returns any
   * @throws ApiError
   */
  public benefitDocumentsControllerRemoveBenefitDocument(
    benefitDocumentId: string
  ): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/benefit-documents/{benefitDocumentId}',
      path: {
        benefitDocumentId: benefitDocumentId,
      },
    });
  }
  /**
   * @param employeeId
   * @returns BenefitEnrollmentDto
   * @throws ApiError
   */
  public benefitEnrollmentsControllerGetEmployeeBenefitEnrollments(
    employeeId: string
  ): CancelablePromise<Array<BenefitEnrollmentDto>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/employees/{employeeId}/benefit-enrollments',
      path: {
        employeeId: employeeId,
      },
    });
  }
  /**
   * @param benefitId
   * @param requestBody
   * @returns void
   * @throws ApiError
   */
  public benefitEnrollmentsControllerPatch(
    benefitId: string,
    requestBody: UpdateBenefitEnrollmentDto
  ): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'PATCH',
      url: '/benefit-enrollments/{benefitId}',
      path: {
        benefitId: benefitId,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * @param employeeId
   * @returns OnboardingPeriodBenefitsDto
   * @throws ApiError
   */
  public benefitsControllerGetEmployeeBenefits(
    employeeId: string
  ): CancelablePromise<OnboardingPeriodBenefitsDto> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/employees/{employeeId}/onboarding-periods/active/benefits',
      path: {
        employeeId: employeeId,
      },
    });
  }
  /**
   * @param employeeId
   * @param requestBody
   * @returns void
   * @throws ApiError
   */
  public benefitsControllerUpsertEmployeeBenefits(
    employeeId: string,
    requestBody: UpdateBenefitsDto
  ): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/employees/{employeeId}/onboarding-periods/active/benefits',
      path: {
        employeeId: employeeId,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * @param employeeId
   * @returns OnboardingPeriodBenefitsDto
   * @throws ApiError
   */
  public benefitsControllerGetAllEmployeeOnboardingPeriodBenefits(
    employeeId: string
  ): CancelablePromise<Array<OnboardingPeriodBenefitsDto>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/employees/{employeeId}/onboarding-periods/all/benefits',
      path: {
        employeeId: employeeId,
      },
    });
  }
  /**
   * @param employeeId
   * @returns OnboardingPeriodBenefitsDto
   * @throws ApiError
   */
  public benefitsControllerGetRenewableOnboardingPeriodBenefits(
    employeeId: string
  ): CancelablePromise<OnboardingPeriodBenefitsDto> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/employees/{employeeId}/onboarding-periods/renewable-benefits',
      path: {
        employeeId: employeeId,
      },
    });
  }
  /**
   * @param employeeId
   * @param periodId
   * @returns OnboardingPeriodBenefitsRenewalDto
   * @throws ApiError
   */
  public benefitsControllerGetOnboardingPeriodRenewalStatus(
    employeeId: string,
    periodId: string
  ): CancelablePromise<OnboardingPeriodBenefitsRenewalDto> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/employees/{employeeId}/onboarding-periods/{periodId}/benefit/renewal-status',
      path: {
        employeeId: employeeId,
        periodId: periodId,
      },
    });
  }
  /**
   * @param periodId
   * @returns OnboardingPeriodBenefitsDto
   * @throws ApiError
   */
  public benefitsControllerGetBenefits(
    periodId: string
  ): CancelablePromise<OnboardingPeriodBenefitsDto> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/onboarding-periods/{periodId}/benefits',
      path: {
        periodId: periodId,
      },
    });
  }
  /**
   * @param openEnrollmentPeriodId
   * @returns BenefitDto
   * @throws ApiError
   */
  public benefitsControllerGetOpenEnrollmentPeriodBenefits(
    openEnrollmentPeriodId: string
  ): CancelablePromise<Array<BenefitDto>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/open-enrollment-periods/{openEnrollmentPeriodId}/benefits',
      path: {
        openEnrollmentPeriodId: openEnrollmentPeriodId,
      },
    });
  }
  /**
   * @param periodId
   * @param requestBody
   * @returns void
   * @throws ApiError
   */
  public benefitsControllerWaiveCoverage(
    periodId: string,
    requestBody: WaiveCoverageDto
  ): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/onboarding-periods/{periodId}/waive-coverage',
      path: {
        periodId: periodId,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * @param employeeId
   * @param familyUnit
   * @returns EmployerContributionDto
   * @throws ApiError
   */
  public contributionModelsControllerFindEmployerContributionForEmployee(
    employeeId: string,
    familyUnit?:
      | 'EMPLOYEE_ONLY'
      | 'EMPLOYEE_SPOUSE'
      | 'EMPLOYEE_CHILD'
      | 'FAMILY'
  ): CancelablePromise<EmployerContributionDto> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/employees/{employeeId}/onboarding-periods/active/employer-contribution',
      path: {
        employeeId: employeeId,
      },
      query: {
        familyUnit: familyUnit,
      },
    });
  }
  /**
   * @param periodId
   * @param familyUnit
   * @returns EmployerContributionDto
   * @throws ApiError
   */
  public contributionModelsControllerFindEmployeesEmployerContribution(
    periodId: string,
    familyUnit?:
      | 'EMPLOYEE_ONLY'
      | 'EMPLOYEE_SPOUSE'
      | 'EMPLOYEE_CHILD'
      | 'FAMILY'
  ): CancelablePromise<EmployerContributionDto> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/onboarding-periods/{periodId}/employer-contribution',
      path: {
        periodId: periodId,
      },
      query: {
        familyUnit: familyUnit,
      },
    });
  }
  /**
   * @param employeeId
   * @returns PossibleEmployerContributionDto
   * @throws ApiError
   */
  public contributionModelsControllerFindPossibleEmployerContributions(
    employeeId: string
  ): CancelablePromise<Array<PossibleEmployerContributionDto>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/employees/{employeeId}/possible-employer-contributions',
      path: {
        employeeId: employeeId,
      },
    });
  }
  /**
   * @param employeeId
   * @returns InsuredPeopleDto
   * @throws ApiError
   */
  public insuredControllerFindInsuredForEmployee(
    employeeId: string
  ): CancelablePromise<InsuredPeopleDto> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/employees/{employeeId}/onboarding-periods/active/insured',
      path: {
        employeeId: employeeId,
      },
    });
  }
  /**
   * @param employeeId
   * @param requestBody
   * @returns void
   * @throws ApiError
   */
  public insuredControllerUpsertInsuredForEmployee(
    employeeId: string,
    requestBody: UpsertInsuredPeopleDto
  ): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/employees/{employeeId}/onboarding-periods/active/insured',
      path: {
        employeeId: employeeId,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * @param employeeId
   * @param requestBody
   * @returns void
   * @throws ApiError
   */
  public insuredControllerUpsertSelfReportInsured(
    employeeId: string,
    requestBody: UpsertInsuredPeopleDto
  ): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/employees/{employeeId}/onboarding-periods/self-report/insured',
      path: {
        employeeId: employeeId,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * @param periodId
   * @param requestBody
   * @returns string
   * @throws ApiError
   */
  public insuredControllerUpsertInsuredForPeriod(
    periodId: string,
    requestBody: UpsertInsuredPeopleDto
  ): CancelablePromise<Array<string>> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/employees/{employeeId}/onboarding-periods/{periodId}/insured',
      path: {
        periodId: periodId,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * @param periodId
   * @returns InsuredPeopleDto
   * @throws ApiError
   */
  public insuredControllerFindInsuredForPeriod(
    periodId: string
  ): CancelablePromise<InsuredPeopleDto> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/employees/{employeeId}/onboarding-periods/{periodId}/insured',
      path: {
        periodId: periodId,
      },
    });
  }
  /**
   * @returns MajorMedicalCarrierDto
   * @throws ApiError
   */
  public majorMedicalCarriersControllerGetCarriers(): CancelablePromise<
    Array<MajorMedicalCarrierDto>
  > {
    return this.httpRequest.request({
      method: 'GET',
      url: '/major-medical-carriers',
    });
  }
  /**
   * @param requestBody
   * @returns MajorMedicalCarrierDto
   * @throws ApiError
   */
  public majorMedicalCarriersControllerSearchCarriers(
    requestBody: SearchMajorMedicalCarriersPayloadDto
  ): CancelablePromise<Array<MajorMedicalCarrierDto>> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/major-medical-carriers/search',
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * @param formData
   * @returns UpdateMajorMedicalCarriersDto
   * @throws ApiError
   */
  public majorMedicalCarriersControllerUpdateCarriers(
    formData: FileDto
  ): CancelablePromise<UpdateMajorMedicalCarriersDto> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/major-medical-carriers/update-carriers',
      formData: formData,
      mediaType: 'multipart/form-data',
    });
  }
  /**
   * @param planYear
   * @param zipCodes
   * @returns PlanIssuerDto
   * @throws ApiError
   */
  public majorMedicalCarriersControllerGetCarrierPlans(
    planYear: number,
    zipCodes: Array<string>
  ): CancelablePromise<Array<PlanIssuerDto>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/major-medical-carriers/plans/{planYear}',
      path: {
        planYear: planYear,
      },
      query: {
        zipCodes: zipCodes,
      },
    });
  }
  /**
   * @param employeeId
   * @param requestBody
   * @returns void
   * @throws ApiError
   */
  public majorMedicalControllerUpsertMajorMedicalPlanBySelfShopping(
    employeeId: string,
    requestBody: UpdateExternalMajorMedicalPlanDto
  ): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/employees/{employeeId}/onboarding-periods/active/major-medical-plan',
      path: {
        employeeId: employeeId,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * @param periodId
   * @param edgeCase
   * @param requestBody
   * @returns void
   * @throws ApiError
   */
  public majorMedicalControllerUpsertMajorMedicalPlanByOperations(
    periodId: string,
    edgeCase: boolean,
    requestBody: UpdateMajorMedicalPlanDto
  ): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/employees/{employeeId}/onboarding-periods/{periodId}/major-medical-plan',
      path: {
        periodId: periodId,
      },
      query: {
        edgeCase: edgeCase,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * @param periodId
   * @returns void
   * @throws ApiError
   */
  public majorMedicalControllerDeleteMajorMedicalPlan(
    periodId: string
  ): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/employees/{employeeId}/onboarding-periods/{periodId}/major-medical-plan',
      path: {
        periodId: periodId,
      },
    });
  }
  /**
   * @param benefitId
   * @param requestBody
   * @returns void
   * @throws ApiError
   */
  public majorMedicalControllerConfirmEnrollment(
    benefitId: string,
    requestBody: EnrollmentConfirmationDto
  ): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/employees/{employeeId}/onboarding-periods/major-medical-plan/{benefitId}/confirm',
      path: {
        benefitId: benefitId,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * @param employeeId
   * @param requestBody
   * @returns void
   * @throws ApiError
   */
  public majorMedicalControllerUpsertMajorMedicalPlanBySelfReport(
    employeeId: string,
    requestBody: UpdateSelfReportMajorMedicalPlanDto
  ): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/employees/{employeeId}/onboarding-periods/active/major-medical-plan/self-report',
      path: {
        employeeId: employeeId,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * @param employeeId
   * @returns void
   * @throws ApiError
   */
  public majorMedicalControllerPutMyMajorMedicalWaiver(
    employeeId: string
  ): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/employees/{employeeId}/onboarding-periods/active/major-medical-waiver',
      path: {
        employeeId: employeeId,
      },
    });
  }
  /**
   * @param employeeId
   * @param requestBody
   * @returns MedicalPlanForEnrollment
   * @throws ApiError
   */
  public majorMedicalControllerSearchMedicalPlansForEmployee(
    employeeId: string,
    requestBody: PlanSearchSelfDto
  ): CancelablePromise<Array<MedicalPlanForEnrollment>> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/employees/{employeeId}/onboarding-periods/active/major-medical-plans',
      path: {
        employeeId: employeeId,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * @param periodId
   * @param requestBody
   * @returns MedicalPlanForEnrollment
   * @throws ApiError
   */
  public majorMedicalControllerSearchMedicalPlansForOnboardingPeriod(
    periodId: string,
    requestBody: PlanSearchSelfDto
  ): CancelablePromise<Array<MedicalPlanForEnrollment>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/employees/{employeeId}/onboarding-periods/{periodId}/major-medical-plans',
      path: {
        periodId: periodId,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * @param employeeId
   * @param selectedTargetEnrollmentDate
   * @returns GetPlanSearchModeDto
   * @throws ApiError
   */
  public majorMedicalControllerGetPlanSearchMode(
    employeeId: string,
    selectedTargetEnrollmentDate?: string
  ): CancelablePromise<GetPlanSearchModeDto> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/employees/{employeeId}/onboarding-periods/active/major-medical-plans/search-mode',
      path: {
        employeeId: employeeId,
      },
      query: {
        selectedTargetEnrollmentDate: selectedTargetEnrollmentDate,
      },
    });
  }
  /**
   * @param requestBody
   * @returns OnboardingPeriodDto
   * @throws ApiError
   */
  public onboardingPeriodsControllerCreate(
    requestBody: CreateOnboardingPeriodByAdminDto
  ): CancelablePromise<OnboardingPeriodDto> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/onboarding-periods',
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * @param employeeId
   * @returns OnboardingPeriodDto
   * @throws ApiError
   */
  public onboardingPeriodsControllerFindActivePeriod(
    employeeId: string
  ): CancelablePromise<OnboardingPeriodDto> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/employees/{employeeId}/onboarding-periods/active',
      path: {
        employeeId: employeeId,
      },
    });
  }
  /**
   * @param employeeId
   * @param requestBody
   * @returns void
   * @throws ApiError
   */
  public onboardingPeriodsControllerSubmitOnboardingChoices(
    employeeId: string,
    requestBody: UpdateOnboardingPeriodByAdminDto
  ): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/employees/{employeeId}/active',
      path: {
        employeeId: employeeId,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * @param periodId
   * @param requestBody
   * @returns void
   * @throws ApiError
   */
  public onboardingPeriodsControllerUpdateByAdmin(
    periodId: string,
    requestBody: UpdateOnboardingPeriodByAdminDto
  ): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/onboarding-periods/{periodId}',
      path: {
        periodId: periodId,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * @param periodId
   * @returns void
   * @throws ApiError
   */
  public onboardingPeriodsControllerDelete(
    periodId: string
  ): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/onboarding-periods/{periodId}',
      path: {
        periodId: periodId,
      },
    });
  }
  /**
   * @param employeeId
   * @param periodId
   * @returns OnboardingPeriodDto
   * @throws ApiError
   */
  public onboardingPeriodsControllerFindOne(
    employeeId: string,
    periodId: string
  ): CancelablePromise<OnboardingPeriodDto> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/employees/{employeeId}/onboarding-periods/{periodId}',
      path: {
        employeeId: employeeId,
        periodId: periodId,
      },
    });
  }
  /**
   * @param employeeId
   * @returns OnboardingPeriodDto
   * @throws ApiError
   */
  public onboardingPeriodsControllerFindMany(
    employeeId: string
  ): CancelablePromise<Array<OnboardingPeriodDto>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/employees/{employeeId}/onboarding-periods',
      path: {
        employeeId: employeeId,
      },
    });
  }
  /**
   * @param periodId
   * @returns void
   * @throws ApiError
   */
  public onboardingPeriodsControllerSendEnrollmentConfirmationEmail(
    periodId: string
  ): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/onboarding-periods/{periodId}/enrollment-confirmation/email',
      path: {
        periodId: periodId,
      },
    });
  }
  /**
   * @param periodId
   * @returns void
   * @throws ApiError
   */
  public onboardingPeriodsControllerSendSummaryEmail(
    periodId: string
  ): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/onboarding-periods/{periodId}/summary/email',
      path: {
        periodId: periodId,
      },
    });
  }
  /**
   * @param periodId
   * @returns void
   * @throws ApiError
   */
  public onboardingPeriodsControllerSendElectionWindowLaunchEmail(
    periodId: string
  ): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/onboarding-periods/{periodId}/election-window-launch/email',
      path: {
        periodId: periodId,
      },
    });
  }
  /**
   * @param periodId
   * @returns void
   * @throws ApiError
   */
  public onboardingPeriodsControllerSendElectionReminder(
    periodId: string
  ): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/onboarding-periods/{periodId}/election-reminder/email',
      path: {
        periodId: periodId,
      },
    });
  }
  /**
   * @param employeeId
   * @returns void
   * @throws ApiError
   */
  public onboardingPeriodsControllerSendWaiverConfirmation(
    employeeId: string
  ): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/employees/{employeeId}/waiver-confirmation/email',
      path: {
        employeeId: employeeId,
      },
    });
  }
  /**
   * @param employeeId
   * @returns void
   * @throws ApiError
   */
  public onboardingPeriodsControllerUpdateMyLastLogin(
    employeeId: string
  ): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'PATCH',
      url: '/employees/{employeeId}/onboarding-periods/active/userLogin',
      path: {
        employeeId: employeeId,
      },
    });
  }
  /**
   * @returns any
   * @throws ApiError
   */
  public onboardingPeriodsControllerUpdateEveryStatus(): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'PATCH',
      url: '/onboarding-periods/update-every-status',
    });
  }
  /**
   * @param employeeId
   * @returns CarePreferencesDto
   * @throws ApiError
   */
  public carePreferencesControllerGetCarePreferences(
    employeeId: string
  ): CancelablePromise<CarePreferencesDto> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/employees/{employeeId}/care-preferences',
      path: {
        employeeId: employeeId,
      },
    });
  }
  /**
   * @param employeeId
   * @param requestBody
   * @returns void
   * @throws ApiError
   */
  public carePreferencesControllerPutCarePreferences(
    employeeId: string,
    requestBody: CarePreferencesDto
  ): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/employees/{employeeId}/care-preferences',
      path: {
        employeeId: employeeId,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * @param employeeId
   * @returns LifestyleDto
   * @throws ApiError
   */
  public carePreferencesControllerGetLifestyle(
    employeeId: string
  ): CancelablePromise<LifestyleDto> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/employees/{employeeId}/care-preferences/lifestyle',
      path: {
        employeeId: employeeId,
      },
    });
  }
  /**
   * @param employeeId
   * @param requestBody
   * @returns void
   * @throws ApiError
   */
  public carePreferencesControllerPutLifestyle(
    employeeId: string,
    requestBody: LifestyleDto
  ): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/employees/{employeeId}/care-preferences/lifestyle',
      path: {
        employeeId: employeeId,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * @param employeeId
   * @param searchTerm The search term to use when searching for providers
   * @param type The entity type of the provider
   * @returns Provider
   * @throws ApiError
   */
  public carePreferencesControllerSearchProviders(
    employeeId: string,
    searchTerm?: string,
    type?: string
  ): CancelablePromise<Array<Provider>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/employees/{employeeId}/providers',
      path: {
        employeeId: employeeId,
      },
      query: {
        searchTerm: searchTerm,
        type: type,
      },
    });
  }
  /**
   * @param requestBody
   * @returns EmployeeDto
   * @throws ApiError
   */
  public employeesControllerCreate(
    requestBody: CreateEmployeeDto
  ): CancelablePromise<EmployeeDto> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/employees',
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * @param employerId
   * @returns EmployeeDto
   * @throws ApiError
   */
  public employeesControllerFindAll(
    employerId: string
  ): CancelablePromise<Array<EmployeeDto>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/employees',
      query: {
        employerId: employerId,
      },
    });
  }
  /**
   * @param requestBody
   * @returns UpsertManyEmployeesResult
   * @throws ApiError
   */
  public employeesControllerUpsertMany(
    requestBody: UpsertManyEmployeesRequest
  ): CancelablePromise<UpsertManyEmployeesResult> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/employees/upsertMany',
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * @param requestBody
   * @returns EmployeeDto
   * @throws ApiError
   */
  public employeesControllerUpsert(
    requestBody: CreateEmployeeDto
  ): CancelablePromise<EmployeeDto> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/employees/upsert',
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * @param employerId
   * @param requestBody
   * @returns EmployeeDto
   * @throws ApiError
   */
  public employeesControllerFindMany(
    employerId: string,
    requestBody: Array<string>
  ): CancelablePromise<Array<EmployeeDto>> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/employees/find-many',
      query: {
        employerId: employerId,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * @param requestBody
   * @returns EmployeeDto
   * @throws ApiError
   */
  public employeesControllerFindLastUpdated(
    requestBody: FindLastUpdatedEmployeesDto
  ): CancelablePromise<Array<EmployeeDto>> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/employees/find-last-updated',
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * @returns EmployeeCount
   * @throws ApiError
   */
  public employeesControllerCount(): CancelablePromise<Array<EmployeeCount>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/employees/count',
    });
  }
  /**
   * @param employeeId
   * @param requestBody
   * @returns void
   * @throws ApiError
   */
  public employeesControllerUpdateEmployeeInfo(
    employeeId: string,
    requestBody: UpdateEmployeeInfoDto
  ): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/employees/{employeeId}/info',
      path: {
        employeeId: employeeId,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * @param employeeId
   * @returns EmployeeInfoDto
   * @throws ApiError
   */
  public employeesControllerGetEmployeeInfo(
    employeeId: string
  ): CancelablePromise<EmployeeInfoDto> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/employees/{employeeId}/info',
      path: {
        employeeId: employeeId,
      },
    });
  }
  /**
   * @param employeeId
   * @returns EmployeeDto
   * @throws ApiError
   */
  public employeesControllerFindOne(
    employeeId: string
  ): CancelablePromise<EmployeeDto> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/employees/{employeeId}',
      path: {
        employeeId: employeeId,
      },
    });
  }
  /**
   * @param employeeId
   * @returns void
   * @throws ApiError
   */
  public employeesControllerDelete(
    employeeId: string
  ): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/employees/{employeeId}',
      path: {
        employeeId: employeeId,
      },
    });
  }
  /**
   * @param userId
   * @returns EmployeeDto
   * @throws ApiError
   */
  public employeesControllerFindEmployeeByUserId(
    userId: string
  ): CancelablePromise<EmployeeDto> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/employees/find-user-by-id/{userId}',
      path: {
        userId: userId,
      },
    });
  }
  /**
   * @param employeeId
   * @param requestBody
   * @returns void
   * @throws ApiError
   */
  public employeesControllerUpdateSelf(
    employeeId: string,
    requestBody: UpdateEmployeeDto
  ): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'PATCH',
      url: '/employees/{employeeId}/update-self',
      path: {
        employeeId: employeeId,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * @param employeeId
   * @param requestBody
   * @returns any
   * @throws ApiError
   */
  public employeesControllerUpdateByAdmin(
    employeeId: string,
    requestBody: UpdateEmployeeDto
  ): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'PATCH',
      url: '/employees/{employeeId}/update-by-admin',
      path: {
        employeeId: employeeId,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * @param employeeId
   * @param requestBody
   * @returns any
   * @throws ApiError
   */
  public employeesControllerUpdateEligibility(
    employeeId: string,
    requestBody: UpdateEligibilityDto
  ): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'PATCH',
      url: '/employees/{employeeId}/update-eligibility',
      path: {
        employeeId: employeeId,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * @param requestBody
   * @returns any
   * @throws ApiError
   */
  public employeesControllerCheckCompanyEmailExists(
    requestBody: EmailDto
  ): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/employees/available-company-emails',
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * @param employeeId
   * @param requestBody
   * @returns void
   * @throws ApiError
   */
  public employeesControllerUpdateCompanyEmail(
    employeeId: string,
    requestBody: EmailDto
  ): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/employees/{employeeId}/company-email',
      path: {
        employeeId: employeeId,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * @param requestBody
   * @returns AgencyDto
   * @throws ApiError
   */
  public agenciesControllerCreate(
    requestBody: CreateAgencyDto
  ): CancelablePromise<AgencyDto> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/agencies',
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * @returns AllAgenciesDto
   * @throws ApiError
   */
  public agenciesControllerFindAll(): CancelablePromise<AllAgenciesDto> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/agencies',
    });
  }
  /**
   * @param id
   * @param requestBody
   * @returns AgencyDto
   * @throws ApiError
   */
  public agenciesControllerUpdate(
    id: string,
    requestBody: UpdateAgencyDto
  ): CancelablePromise<AgencyDto> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/agencies/{id}',
      path: {
        id: id,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * @param id
   * @returns AgencyDto
   * @throws ApiError
   */
  public agenciesControllerFindOne(id: string): CancelablePromise<AgencyDto> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/agencies/{id}',
      path: {
        id: id,
      },
    });
  }
  /**
   * @param id
   * @returns void
   * @throws ApiError
   */
  public agenciesControllerRemove(id: string): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/agencies/{id}',
      path: {
        id: id,
      },
    });
  }
  /**
   * @param id
   * @returns AllAgenciesDto
   * @throws ApiError
   */
  public agenciesControllerFindAssignableAgencies(
    id: string
  ): CancelablePromise<AllAgenciesDto> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/agencies/{id}/assignable-agencies',
      path: {
        id: id,
      },
    });
  }
  /**
   * @param employerId
   * @returns AgencyDto
   * @throws ApiError
   */
  public agenciesControllerFindMany(
    employerId: string
  ): CancelablePromise<Array<AgencyDto>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/employers/{employerId}/agencies',
      path: {
        employerId: employerId,
      },
    });
  }
  /**
   * @param requestBody
   * @returns AgencyDto
   * @throws ApiError
   */
  public agenciesControllerFindLastUpdated(
    requestBody: FindLastUpdatedAgenciesDto
  ): CancelablePromise<Array<AgencyDto>> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/agencies/find-last-updated',
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * @param requestBody
   * @returns AgentDto
   * @throws ApiError
   */
  public agentsControllerCreate(
    requestBody: CreateAgentDto
  ): CancelablePromise<AgentDto> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/agents',
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * @param agencyId
   * @returns AgentDto
   * @throws ApiError
   */
  public agentsControllerFindAllForAgency(
    agencyId: string
  ): CancelablePromise<Array<AgentDto>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/agents',
      query: {
        agencyId: agencyId,
      },
    });
  }
  /**
   * @param id
   * @returns AgentDto
   * @throws ApiError
   */
  public agentsControllerFindOne(id: string): CancelablePromise<AgentDto> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/agents/{id}',
      path: {
        id: id,
      },
    });
  }
  /**
   * @param id
   * @param requestBody
   * @returns AgentDto
   * @throws ApiError
   */
  public agentsControllerUpdate(
    id: string,
    requestBody: UpdateAgentDto
  ): CancelablePromise<AgentDto> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/agents/{id}',
      path: {
        id: id,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * @param id
   * @returns void
   * @throws ApiError
   */
  public agentsControllerRemove(id: string): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/agents/{id}',
      path: {
        id: id,
      },
    });
  }
  /**
   * @param requestBody
   * @returns EmployerDto
   * @throws ApiError
   */
  public employersControllerCreate(
    requestBody: CreateEmployerDto
  ): CancelablePromise<EmployerDto> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/employers',
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * @returns GetEmployerDto
   * @throws ApiError
   */
  public employersControllerFindAll(): CancelablePromise<
    Array<GetEmployerDto>
  > {
    return this.httpRequest.request({
      method: 'GET',
      url: '/employers',
    });
  }
  /**
   * @param employerId
   * @returns GetEmployerDto
   * @throws ApiError
   */
  public employersControllerFindOne(
    employerId: string
  ): CancelablePromise<GetEmployerDto> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/employers/{employerId}',
      path: {
        employerId: employerId,
      },
    });
  }
  /**
   * @param employerId
   * @param requestBody
   * @returns EmployerDto
   * @throws ApiError
   */
  public employersControllerUpdate(
    employerId: string,
    requestBody: UpdateEmployerDto
  ): CancelablePromise<EmployerDto> {
    return this.httpRequest.request({
      method: 'PATCH',
      url: '/employers/{employerId}',
      path: {
        employerId: employerId,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * @param employerId
   * @returns void
   * @throws ApiError
   */
  public employersControllerRemove(
    employerId: string
  ): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/employers/{employerId}',
      path: {
        employerId: employerId,
      },
    });
  }
  /**
   * @param employerId
   * @returns EmployerPublicInfoDto
   * @throws ApiError
   */
  public employersControllerFindEmployerPublicInfo(
    employerId: string
  ): CancelablePromise<EmployerPublicInfoDto> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/employers/{employerId}/public-info',
      path: {
        employerId: employerId,
      },
    });
  }
  /**
   * @param requestBody
   * @returns EmployerDto
   * @throws ApiError
   */
  public employersControllerFindLastUpdated(
    requestBody: FindLastUpdatedEmployersDto
  ): CancelablePromise<Array<EmployerDto>> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/employers/find-last-updated',
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * @param employerId
   * @param email
   * @param sendInvitationEmail
   * @returns string
   * @throws ApiError
   */
  public employersControllerInviteEmployerAdmin(
    employerId: string,
    email: string,
    sendInvitationEmail: boolean
  ): CancelablePromise<string> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/employers/{employerId}/invite-employer-admin',
      path: {
        employerId: employerId,
      },
      query: {
        email: email,
        sendInvitationEmail: sendInvitationEmail,
      },
    });
  }
  /**
   * @param employerId
   * @returns void
   * @throws ApiError
   */
  public employersControllerCancelInviteEmployerAdmin(
    employerId: string
  ): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/employers/{employerId}/invite-employer-admin',
      path: {
        employerId: employerId,
      },
    });
  }
  /**
   * @param employerId
   * @returns SelectedSupplementalBenefitDto
   * @throws ApiError
   */
  public employersControllerSupplementalBenefits(
    employerId: string
  ): CancelablePromise<Array<SelectedSupplementalBenefitDto>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/employers/{employerId}/supplemental-benefits',
      path: {
        employerId: employerId,
      },
    });
  }
  /**
   * @param employerId
   * @param requestBody A list of filters used to exclude plans from search results.
   * @returns void
   * @throws ApiError
   */
  public employersControllerPutMajorMedicalPlanFilters(
    employerId: string,
    requestBody: Array<PlanExclusionFilter>
  ): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/employers/{employerId}/major-medical-plans/filters',
      path: {
        employerId: employerId,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * @param employerId
   * @returns PlanExclusionFilter
   * @throws ApiError
   */
  public employersControllerGetMajorMedicalPlanFilters(
    employerId: string
  ): CancelablePromise<Array<PlanExclusionFilter>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/employers/{employerId}/major-medical-plans/filters',
      path: {
        employerId: employerId,
      },
    });
  }
  /**
   * @param employerId
   * @returns EmployerContactsByType
   * @throws ApiError
   */
  public employersControllerGetEmployerContactsByType(
    employerId: string
  ): CancelablePromise<EmployerContactsByType> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/employers/{employerId}/contacts',
      path: {
        employerId: employerId,
      },
    });
  }
  /**
   * @param employerId
   * @param requestBody
   * @returns void
   * @throws ApiError
   */
  public employersControllerUpsertEmployerContacts(
    employerId: string,
    requestBody: Array<CreateEmployerContactDto>
  ): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/employers/{employerId}/contacts',
      path: {
        employerId: employerId,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * @param employerId
   * @returns void
   * @throws ApiError
   */
  public employersControllerActivateContacts(
    employerId: string
  ): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/employers/{employerId}/activate-employer-admin',
      path: {
        employerId: employerId,
      },
    });
  }
  /**
   * @param employerId
   * @param requestBody
   * @returns AllowanceModelDto
   * @throws ApiError
   */
  public allowanceModelControllerUpsert(
    employerId: string,
    requestBody: UpsertAllowanceModelDto
  ): CancelablePromise<AllowanceModelDto> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/employers/{employerId}/allowance-model',
      path: {
        employerId: employerId,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * @param employerId
   * @param requestBody
   * @returns AllowanceModelDto
   * @throws ApiError
   */
  public allowanceModelControllerSearch(
    employerId: string,
    requestBody: GetAllowanceModelDto
  ): CancelablePromise<AllowanceModelDto> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/employers/{employerId}/allowance-model/search',
      path: {
        employerId: employerId,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * @param employerId
   * @returns EmployeeClassesDto
   * @throws ApiError
   */
  public allowanceModelControllerGetEmployeeClasses(
    employerId: string
  ): CancelablePromise<EmployeeClassesDto> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/employers/{employerId}/allowance-model/employee-classes',
      path: {
        employerId: employerId,
      },
    });
  }
  /**
   * @param id
   * @param requestBody
   * @returns void
   * @throws ApiError
   */
  public employerDocumentControllerPostDocument(
    id: string,
    requestBody: CreateEmployerDocumentDto
  ): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/employers/{id}/documents',
      path: {
        id: id,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * @param id
   * @returns EmployerDocumentDto
   * @throws ApiError
   */
  public employerDocumentControllerGetDocuments(
    id: string
  ): CancelablePromise<Array<EmployerDocumentDto>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/employers/{id}/documents',
      path: {
        id: id,
      },
    });
  }
  /**
   * @param documentId
   * @param requestBody
   * @returns any
   * @throws ApiError
   */
  public employerDocumentControllerUpdateDocument(
    documentId: string,
    requestBody: UpdateEmployerDocumentDto
  ): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/employers/{id}/documents/{documentId}',
      path: {
        documentId: documentId,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * @param id
   * @param documentId
   * @returns void
   * @throws ApiError
   */
  public employerDocumentControllerDeleteDocument(
    id: string,
    documentId: string
  ): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/employers/{id}/documents/{documentId}',
      path: {
        id: id,
        documentId: documentId,
      },
    });
  }
  /**
   * @param documentId
   * @returns EmployerDocumentDto
   * @throws ApiError
   */
  public employerDocumentControllerGetDocument(
    documentId: string
  ): CancelablePromise<EmployerDocumentDto> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/employers/{id}/documents/{documentId}',
      path: {
        documentId: documentId,
      },
    });
  }
  /**
   * @param id
   * @param type
   * @returns EmployerDocumentDto
   * @throws ApiError
   */
  public employerDocumentControllerGetDocumentsByType(
    id: string,
    type: string
  ): CancelablePromise<Array<EmployerDocumentDto>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/employers/{id}/documents/{type}',
      path: {
        id: id,
        type: type,
      },
    });
  }
  /**
   * @param employerId
   * @returns EnrollmentInstructionsDto
   * @throws ApiError
   */
  public enrollmentInstructionsControllerFind(
    employerId: string
  ): CancelablePromise<EnrollmentInstructionsDto> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/employers/{employerId}/enrollment-instructions',
      path: {
        employerId: employerId,
      },
    });
  }
  /**
   * @param employerId
   * @param requestBody
   * @returns EnrollmentInstructionsDto
   * @throws ApiError
   */
  public enrollmentInstructionsControllerUpsert(
    employerId: string,
    requestBody: UpsertEnrollmentInstructionsDto
  ): CancelablePromise<EnrollmentInstructionsDto> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/employers/{employerId}/enrollment-instructions',
      path: {
        employerId: employerId,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * @param requestBody
   * @returns OpenEnrollmentPeriodDto
   * @throws ApiError
   */
  public openEnrollmentPeriodsControllerCreate(
    requestBody: CreateOpenEnrollmentPeriodDto
  ): CancelablePromise<OpenEnrollmentPeriodDto> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/open-enrollment-periods',
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * @returns OpenEnrollmentPeriodDto
   * @throws ApiError
   */
  public openEnrollmentPeriodsControllerFindAll(): CancelablePromise<
    Array<OpenEnrollmentPeriodDto>
  > {
    return this.httpRequest.request({
      method: 'GET',
      url: '/open-enrollment-periods',
    });
  }
  /**
   * @param employerId
   * @returns OpenEnrollmentPeriodDto
   * @throws ApiError
   */
  public openEnrollmentPeriodsControllerFindAllForEmployer(
    employerId: string
  ): CancelablePromise<Array<OpenEnrollmentPeriodDto>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/employers/{employerId}/open-enrollment-periods',
      path: {
        employerId: employerId,
      },
    });
  }
  /**
   * @param id
   * @returns OpenEnrollmentPeriodDto
   * @throws ApiError
   */
  public openEnrollmentPeriodsControllerFind(
    id: string
  ): CancelablePromise<OpenEnrollmentPeriodDto> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/open-enrollment-periods/{id}',
      path: {
        id: id,
      },
    });
  }
  /**
   * @param id
   * @param requestBody
   * @returns void
   * @throws ApiError
   */
  public openEnrollmentPeriodsControllerUpdate(
    id: string,
    requestBody: UpdateOpenEnrollmentPeriodDto
  ): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/open-enrollment-periods/{id}',
      path: {
        id: id,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * @param id
   * @param mode
   * @param findByDate
   * @returns OpenEnrollmentPeriodDto
   * @throws ApiError
   */
  public openEnrollmentPeriodsControllerFindEmployerRelevantOpenEnrollmentPeriod(
    id: string,
    mode: 'EFFECTIVE' | 'ONBOARDABLE',
    findByDate?: string
  ): CancelablePromise<OpenEnrollmentPeriodDto> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/employers/{id}/open-enrollment-periods/relevant',
      path: {
        id: id,
      },
      query: {
        mode: mode,
        findByDate: findByDate,
      },
    });
  }
  /**
   * @param requestBody
   * @returns any
   * @throws ApiError
   */
  public eventsControllerCollect(
    requestBody: Array<EventDto>
  ): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/events',
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * @param requestBody
   * @returns any
   * @throws ApiError
   */
  public supportControllerReportIssue(
    requestBody: ReportSupportIssuePayloadDto
  ): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/support/report-issue',
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * @returns any The Health Check is successful
   * @throws ApiError
   */
  public healthControllerReadiness(): CancelablePromise<{
    status?: string;
    info?: Record<string, Record<string, any>> | null;
    error?: Record<string, Record<string, any>> | null;
    details?: Record<string, Record<string, any>>;
  }> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/health/readiness',
      errors: {
        503: `The Health Check is not successful`,
      },
    });
  }
  /**
   * @param id
   * @returns EmployerPublicInfoDto
   * @throws ApiError
   */
  public healthControllerAnonymousFindEmployerPublicInfo(
    id: string
  ): CancelablePromise<EmployerPublicInfoDto> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/health/employers/{id}/public-info',
      path: {
        id: id,
      },
    });
  }
  /**
   * @param zipCode The zip code of the location to center the search on
   * @param searchTerm The search term to use when searching for providers
   * @param type The entity type of the provider
   * @returns Provider
   * @throws ApiError
   */
  public insuranceControllerSearchProviders(
    zipCode: string,
    searchTerm?: string,
    type?: string
  ): CancelablePromise<Array<Provider>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/insurance/providers',
      query: {
        searchTerm: searchTerm,
        type: type,
        zipCode: zipCode,
      },
    });
  }
  /**
   * @param ids The NPIs to search for
   * @returns Provider
   * @throws ApiError
   */
  public insuranceControllerSearchProvidersByIds(
    ids: Array<number>
  ): CancelablePromise<Array<Provider>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/insurance/providers-by-ids',
      query: {
        ids: ids,
      },
    });
  }
  /**
   * @param ids The NPIs to search for
   * @param employeeId
   * @returns Provider
   * @throws ApiError
   */
  public insuranceControllerSearchEmployeeProvidersByIdsAndZipCode(
    ids: Array<number>,
    employeeId: string
  ): CancelablePromise<Array<Provider>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/insurance/{employeeId}/providers-by-ids',
      path: {
        employeeId: employeeId,
      },
      query: {
        ids: ids,
      },
    });
  }
  /**
   * @param searchTerm The search term to use when searching for drugs
   * @returns Drug
   * @throws ApiError
   */
  public insuranceControllerSearchDrugs(
    searchTerm: string
  ): CancelablePromise<Array<Drug>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/insurance/drugs',
      query: {
        searchTerm: searchTerm,
      },
    });
  }
  /**
   * @param requestBody
   * @returns MedicalPlan
   * @throws ApiError
   */
  public insuranceControllerSearchMedicalPlans(
    requestBody: MedicalPlanSearchRequest
  ): CancelablePromise<Array<MedicalPlan>> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/insurance/plans',
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * @param requestBody
   * @returns QuoteDto
   * @throws ApiError
   */
  public quotesControllerCreate(
    requestBody: CreateQuoteDto
  ): CancelablePromise<QuoteDto> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/quotes',
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * @param employerId
   * @returns QuoteDto
   * @throws ApiError
   */
  public quotesControllerFindAll(
    employerId: string
  ): CancelablePromise<Array<QuoteDto>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/quotes',
      query: {
        employerId: employerId,
      },
    });
  }
  /**
   * @param id
   * @param requestBody
   * @returns GenerateQuoteResultDto
   * @throws ApiError
   */
  public quotesControllerGenerateOutput(
    id: string,
    requestBody: GenerateQuoteOutputDto
  ): CancelablePromise<GenerateQuoteResultDto> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/quotes/{id}/generateOutput',
      path: {
        id: id,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * @param id
   * @param requestBody
   * @returns QuoteDto
   * @throws ApiError
   */
  public quotesControllerUpdate(
    id: string,
    requestBody: UpdateQuoteDto
  ): CancelablePromise<QuoteDto> {
    return this.httpRequest.request({
      method: 'PATCH',
      url: '/quotes/{id}',
      path: {
        id: id,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * @param id
   * @returns QuoteDto
   * @throws ApiError
   */
  public quotesControllerFindOne(id: string): CancelablePromise<QuoteDto> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/quotes/{id}',
      path: {
        id: id,
      },
    });
  }
  /**
   * @param id
   * @returns void
   * @throws ApiError
   */
  public quotesControllerRemove(id: string): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/quotes/{id}',
      path: {
        id: id,
      },
    });
  }
  /**
   * @param id
   * @returns QuotePreferenceDto
   * @throws ApiError
   */
  public quotesControllerGetQuotePreference(
    id: string
  ): CancelablePromise<QuotePreferenceDto> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/quotes/{id}/preference',
      path: {
        id: id,
      },
    });
  }
  /**
   * @param id
   * @param requestBody
   * @returns void
   * @throws ApiError
   */
  public quotesControllerUpsertQuotePreference(
    id: string,
    requestBody: QuotePreferenceDto
  ): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/quotes/{id}/preference',
      path: {
        id: id,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * @param requestBody
   * @returns QualifyingLifeEventDto
   * @throws ApiError
   */
  public qualifyingLifeEventsControllerCreate(
    requestBody: CreateQualifyingLifeEventDto
  ): CancelablePromise<QualifyingLifeEventDto> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/qualifying-life-events',
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * @param requestBody
   * @returns any
   * @throws ApiError
   */
  public recommendationDataControllerUpdateRecommendationData(
    requestBody: RecommendationDataInputDto
  ): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/recommendation-data',
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * @param requestBody
   * @returns RecommendationDataDto
   * @throws ApiError
   */
  public recommendationDataControllerSearchRecommendationData(
    requestBody: RecommendationDataSearchDto
  ): CancelablePromise<Array<RecommendationDataDto>> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/recommendation-data/search',
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * @returns AgencyReportRowDto
   * @throws ApiError
   */
  public agencyReportsControllerGenerateAgenciesReport(): CancelablePromise<
    Array<AgencyReportRowDto>
  > {
    return this.httpRequest.request({
      method: 'GET',
      url: '/agencyReports',
    });
  }
  /**
   * @param id
   * @returns AgentReportRowDto
   * @throws ApiError
   */
  public agencyReportsControllerGenerateAgentsReport(
    id: string
  ): CancelablePromise<Array<AgentReportRowDto>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/agencies/{id}/report',
      path: {
        id: id,
      },
    });
  }
  /**
   * @param isDemo
   * @returns EmployerReportRowDto
   * @throws ApiError
   */
  public employerReportsControllerGenerateEmployerReport(
    isDemo: boolean
  ): CancelablePromise<Array<EmployerReportRowDto>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/employersReport',
      query: {
        isDemo: isDemo,
      },
    });
  }
  /**
   * @param isDemo
   * @returns ProspectReportRowDto
   * @throws ApiError
   */
  public employerReportsControllerGenerateProspectReport(
    isDemo: boolean
  ): CancelablePromise<Array<ProspectReportRowDto>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/prospectsReport',
      query: {
        isDemo: isDemo,
      },
    });
  }
  /**
   * @param employerId
   * @param effectiveOn The start date of the payroll report
   * @param cycle The payroll cycle
   * @param perPage Number of rows per page
   * @param page The page number to retrieve
   * @returns PaginatedPayrollReportRowDto
   * @throws ApiError
   */
  public employerReportsControllerGeneratePayrollReport(
    employerId: string,
    effectiveOn: string,
    cycle:
      | 'WEEKLY'
      | 'BIWEEKLY'
      | 'BIWEEKLY_DEDUCTIONS'
      | 'MONTHLY'
      | 'SEMI_MONTHLY',
    perPage?: number,
    page?: number
  ): CancelablePromise<PaginatedPayrollReportRowDto> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/employers/{employerId}/payroll',
      path: {
        employerId: employerId,
      },
      query: {
        effectiveOn: effectiveOn,
        cycle: cycle,
        perPage: perPage,
        page: page,
      },
    });
  }
  /**
   * @param employerId
   * @returns CompanyOverviewDto
   * @throws ApiError
   */
  public employerReportsControllerGenerateCompanyOverviewReport(
    employerId: string
  ): CancelablePromise<CompanyOverviewDto> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/employers/{employerId}/overview',
      path: {
        employerId: employerId,
      },
    });
  }
  /**
   * @param openEnrollmentPeriodId
   * @param view
   * @param perPage Number of employees per page
   * @param page The page number to retrieve
   * @returns PaginatedEmployeeReportRowDto
   * @throws ApiError
   */
  public employerReportsControllerGenerateEmployeeReport(
    openEnrollmentPeriodId: string,
    view:
      | 'ALL'
      | 'OPEN_ENROLLMENT'
      | 'LATEST_COVERAGE'
      | 'ENROLLMENTS_IN_PROCESS',
    perPage?: number,
    page?: number
  ): CancelablePromise<PaginatedEmployeeReportRowDto> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/open-enrollment-periods/{openEnrollmentPeriodId}/employees-report',
      path: {
        openEnrollmentPeriodId: openEnrollmentPeriodId,
      },
      query: {
        view: view,
        perPage: perPage,
        page: page,
      },
    });
  }
  /**
   * @param periodId
   * @returns OnboardingPeriodReportDto
   * @throws ApiError
   */
  public onboardingPeriodReportsControllerGenerateOnboardingPeriodReport(
    periodId: string
  ): CancelablePromise<OnboardingPeriodReportDto> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/onboarding-periods/{periodId}/onboarding-period-report',
      path: {
        periodId: periodId,
      },
    });
  }
  /**
   * @param employeeId
   * @returns OnboardingPeriodReportDto
   * @throws ApiError
   */
  public onboardingPeriodReportsControllerGenerateEmployeeOnboardingReport(
    employeeId: string
  ): CancelablePromise<Array<OnboardingPeriodReportDto>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/employees/{employeeId}/onboarding-periods-report',
      path: {
        employeeId: employeeId,
      },
    });
  }
  /**
   * @param id
   * @param perPage Number of onboarding periods per page
   * @param page The page number to retrieve
   * @returns PaginatedEmployerOnboardingReportDto
   * @throws ApiError
   */
  public onboardingPeriodReportsControllerGenerateEmployerOnboardingReport(
    id: string,
    perPage?: number,
    page?: number
  ): CancelablePromise<PaginatedEmployerOnboardingReportDto> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/employers/{id}/onboarding-periods',
      path: {
        id: id,
      },
      query: {
        perPage: perPage,
        page: page,
      },
    });
  }
  /**
   * @returns PlanYearEmployeeRenewalReportDto
   * @throws ApiError
   */
  public planYearReportsControllerGeneratePlanYearEmployeeRenewalReport(): CancelablePromise<
    Array<PlanYearEmployeeRenewalReportDto>
  > {
    return this.httpRequest.request({
      method: 'POST',
      url: '/plan-year-reports/employee-renewal-report',
    });
  }
  /**
   * @param employerId
   * @returns any
   * @throws ApiError
   */
  public customerDataTasksControllerSyncIntercom(
    employerId: string
  ): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/customer-data-tasks/{employerId}/intercom-sync',
      path: {
        employerId: employerId,
      },
    });
  }
  /**
   * @param requestBody
   * @returns IntercomUserHashResponseDto
   * @throws ApiError
   */
  public intercomControllerGenerateHash(
    requestBody: IntercomUserHashPayloadDto
  ): CancelablePromise<IntercomUserHashResponseDto> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/intercom/users/generate-hash',
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * @param requestBody
   * @returns string
   * @throws ApiError
   */
  public userActivationLinksControllerGenerateActivationLink(
    requestBody: CreateActivationLinkDto
  ): CancelablePromise<string> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/user-activation-links',
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * @param id
   * @returns string
   * @throws ApiError
   */
  public userActivationLinksControllerGenerateFronteggActivationToken(
    id: string
  ): CancelablePromise<string> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/user-activation-links/{id}/generateFronteggActivationToken',
      path: {
        id: id,
      },
    });
  }
  /**
   * @param requestBody
   * @returns void
   * @throws ApiError
   */
  public usersControllerUpdateEmployeeName(
    requestBody: UpdateEmployeeNameDto
  ): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/users/updateEmployeeName',
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * @param requestBody
   * @returns void
   * @throws ApiError
   */
  public usersControllerUpdateEmployerName(
    requestBody: UpdateEmployerNameDto
  ): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/users/updateEmployerName',
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * @param requestBody
   * @returns void
   * @throws ApiError
   */
  public usersControllerUpdateAgencyName(
    requestBody: UpdateAgencyNameDto
  ): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/users/updateAgencyName',
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * @param roles
   * @param requestBody
   * @returns UserDto
   * @throws ApiError
   */
  public usersControllerUpsert(
    roles: Array<string>,
    requestBody: CreateUserDto
  ): CancelablePromise<UserDto> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/users/{userId}',
      query: {
        roles: roles,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * @param userId
   * @param tenantId
   * @returns void
   * @throws ApiError
   */
  public usersControllerDeleteUser(
    userId: string,
    tenantId: string
  ): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/users/{userId}',
      path: {
        userId: userId,
      },
      query: {
        tenantId: tenantId,
      },
    });
  }
  /**
   * @param userId
   * @returns UserDto
   * @throws ApiError
   */
  public usersControllerFindUser(userId: string): CancelablePromise<UserDto> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/users/{userId}',
      path: {
        userId: userId,
      },
    });
  }
  /**
   * @param tenantId
   * @param roles
   * @param requestBody
   * @returns UpsertManyUsersResponseDto
   * @throws ApiError
   */
  public usersControllerUpsertMany(
    tenantId: string,
    roles: Array<string>,
    requestBody: Array<CreateManyUsersDto>
  ): CancelablePromise<UpsertManyUsersResponseDto> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/users',
      query: {
        tenantId: tenantId,
        roles: roles,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * @param ids
   * @returns UserDto
   * @throws ApiError
   */
  public usersControllerGetUsersFromFronteggByIds(
    ids: Array<string>
  ): CancelablePromise<Array<UserDto>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/users',
      query: {
        ids: ids,
      },
    });
  }
  /**
   * @param requestBody
   * @returns void
   * @throws ApiError
   */
  public usersControllerSendActivationEmails(
    requestBody: Array<UserAndTenantDto>
  ): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/users/send-activation-emails',
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * @param employerId
   * @param requestBody
   * @returns any
   * @throws ApiError
   */
  public usersControllerGetAllUsersRoles(
    employerId: string,
    requestBody: UsersRolesDto
  ): CancelablePromise<Record<string, any>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/users/all/roles',
      query: {
        employerId: employerId,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * @param employerId
   * @param userId
   * @returns GetUserRolesDto
   * @throws ApiError
   */
  public usersControllerGetUserRoles(
    employerId: string,
    userId: string
  ): CancelablePromise<GetUserRolesDto> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/users/{userId}/roles',
      path: {
        userId: userId,
      },
      query: {
        employerId: employerId,
      },
    });
  }
  /**
   * @param userId
   * @param requestBody
   * @returns void
   * @throws ApiError
   */
  public usersControllerChangeCustomerFacingRole(
    userId: string,
    requestBody: UpdateUserRolesDto
  ): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/users/{userId}/roles',
      path: {
        userId: userId,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * @param userId
   * @returns PasswordResetDto
   * @throws ApiError
   */
  public usersControllerGeneratePasswordResetToken(
    userId: string
  ): CancelablePromise<PasswordResetDto> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/users/{userId}/password-reset-token',
      path: {
        userId: userId,
      },
    });
  }
  /**
   * @param email
   * @returns UserDto
   * @throws ApiError
   */
  public usersControllerFindUserByEmail(
    email: string
  ): CancelablePromise<UserDto> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/users/find-by-email/{email}',
      path: {
        email: email,
      },
    });
  }
  /**
   * @returns UserDto
   * @throws ApiError
   */
  public usersControllerGetAll(): CancelablePromise<Array<UserDto>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/users/all',
    });
  }
  /**
   * @param tenantId
   * @returns UserDto
   * @throws ApiError
   */
  public usersControllerGetAllUsersFromTenant(
    tenantId: string
  ): CancelablePromise<Array<UserDto>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/users/all/{tenantId}',
      query: {
        tenantId: tenantId,
      },
    });
  }
  /**
   * @param requestBody
   * @returns UserStatusDto
   * @throws ApiError
   */
  public usersControllerGetUsersTenantsStatuses(
    requestBody: UserIdsDto
  ): CancelablePromise<Array<UserStatusDto>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/users/statuses',
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * @param userId
   * @param requestBody
   * @returns void
   * @throws ApiError
   */
  public usersControllerUpdateCompanyEmail(
    userId: string,
    requestBody: EmailDto
  ): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/users/{userId}/update-company-email',
      path: {
        userId: userId,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * @param userId
   * @param tenantId
   * @returns UserDtoWithRoles
   * @throws ApiError
   */
  public usersControllerGetFronteggUser(
    userId: string,
    tenantId: string
  ): CancelablePromise<UserDtoWithRoles> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/users/frontegg/{userId}',
      path: {
        userId: userId,
      },
      query: {
        tenantId: tenantId,
      },
    });
  }
  /**
   * @param requestBody
   * @returns void
   * @throws ApiError
   */
  public usersControllerUpdateUserStatus(
    requestBody: UserStatusDto
  ): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/users/{userId}/status',
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * @param employerId
   * @returns FinchPaymentDto
   * @throws ApiError
   */
  public finchControllerPayments(
    employerId: string
  ): CancelablePromise<Array<FinchPaymentDto>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/finch/{employerId}/payments',
      path: {
        employerId: employerId,
      },
    });
  }
  /**
   * @param employerId
   * @returns FinchEmployeeDto
   * @throws ApiError
   */
  public finchControllerFindEmployees(
    employerId: string
  ): CancelablePromise<Array<FinchEmployeeDto>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/finch/{employerId}/employee',
      path: {
        employerId: employerId,
      },
    });
  }
  /**
   * @param employerId
   * @returns FinchFeatureStatusResponseDto
   * @throws ApiError
   */
  public finchControllerStatus(
    employerId: string
  ): CancelablePromise<FinchFeatureStatusResponseDto> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/finch/{employerId}/status',
      path: {
        employerId: employerId,
      },
    });
  }
  /**
   * @param requestBody
   * @returns any
   * @throws ApiError
   */
  public finchControllerManage(
    requestBody: FinchFeatureManagePayloadDto
  ): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/finch/manage',
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * @param requestBody
   * @returns FinchConnectResponseDto
   * @throws ApiError
   */
  public finchControllerConnect(
    requestBody: FinchConnectPayloadDto
  ): CancelablePromise<FinchConnectResponseDto> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/finch/connect',
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * @param code
   * @param employerId
   * @returns any
   * @throws ApiError
   */
  public finchControllerConnectCallback(
    code: string,
    employerId: string
  ): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/finch/connect/callback',
      query: {
        code: code,
        employerId: employerId,
      },
    });
  }
  /**
   * @param requestBody
   * @returns FinchConnectEmployerStatusDto
   * @throws ApiError
   */
  public finchControllerConnectStatus(
    requestBody: FinchConnectPayloadDto
  ): CancelablePromise<FinchConnectEmployerStatusDto> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/finch/connect/status',
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * @param requestBody
   * @returns any
   * @throws ApiError
   */
  public finchControllerConnectDisconnect(
    requestBody: FinchConnectPayloadDto
  ): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/finch/connect/disconnect',
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * @param employerId
   * @returns void
   * @throws ApiError
   */
  public finchControllerSyncCompany(
    employerId: string
  ): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/finch/employers/{employerId}/sync',
      path: {
        employerId: employerId,
      },
    });
  }
  /**
   * @param employeeId
   * @param requestBody
   * @returns EmployeePaymentMethodDetailsDto
   * @throws ApiError
   */
  public paymentsControllerEmployeePaymentMethodDetails(
    employeeId: string,
    requestBody: EmployeePaymentMethodDetailsPayloadDto
  ): CancelablePromise<EmployeePaymentMethodDetailsDto> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/payments/v3/{employeeId}/details',
      path: {
        employeeId: employeeId,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * @param employeeId
   * @param requestBody
   * @returns any
   * @throws ApiError
   */
  public paymentsControllerUpdatePaymentMethodAssociatedBenefits(
    employeeId: string,
    requestBody: UpdateEmployeePaymentMethodAssociatedBenefitsPayloadDto
  ): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/payments/{employeeId}/update-associated-benefits',
      path: {
        employeeId: employeeId,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * @param employeeId
   * @param requestBody
   * @returns EmployeePaymentMethodDto
   * @throws ApiError
   */
  public paymentsControllerCreatePaymentMethod(
    employeeId: string,
    requestBody: CreateEmployeePaymentMethodPayloadDto
  ): CancelablePromise<EmployeePaymentMethodDto> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/payments/{employeeId}/create',
      path: {
        employeeId: employeeId,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * @param employeeId
   * @param requestBody
   * @returns EmployeePaymentMethodDto
   * @throws ApiError
   */
  public paymentsControllerUpdatePaymentMethod(
    employeeId: string,
    requestBody: UpdateEmployeePaymentMethodPayloadDto
  ): CancelablePromise<EmployeePaymentMethodDto> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/payments/{employeeId}/update',
      path: {
        employeeId: employeeId,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * @param employeeId
   * @param onboardingPeriodId
   * @returns EmployeePaymentMethodDto
   * @throws ApiError
   */
  public paymentsControllerGetPaymentMethod(
    employeeId: string,
    onboardingPeriodId: string
  ): CancelablePromise<EmployeePaymentMethodDto> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/payments/{employeeId}',
      path: {
        employeeId: employeeId,
      },
      query: {
        onboardingPeriodId: onboardingPeriodId,
      },
    });
  }
  /**
   * @param requestBody
   * @returns PaymentsMigrationDto
   * @throws ApiError
   */
  public paymentsControllerMigration(
    requestBody: PaymentsMigrationPayloadDto
  ): CancelablePromise<PaymentsMigrationDto> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/payments/migration',
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * @param searchTerm
   * @returns SearchResultDto
   * @throws ApiError
   */
  public searchControllerSearch(
    searchTerm: string
  ): CancelablePromise<Array<SearchResultDto>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/search',
      query: {
        searchTerm: searchTerm,
      },
    });
  }
}
