import { yupResolver } from '@hookform/resolvers/yup';
import { useQueryClient } from '@tanstack/react-query';
import { AgencyDto, AgencyType, AllAgenciesDto } from '@zorro/clients';
import { formatDateISO, parseDateISO } from '@zorro/shared/formatters';
import {
  callEndpoint,
  logger,
  responseErrorToString,
  showErrorNotification,
  useForm,
  useImpersonation,
  useMonolithQuery,
  useRoles,
} from '@zorro/shared/utils';
import _cloneDeep from 'lodash/cloneDeep';
import { useMemo } from 'react';
import { UseFormReturn } from 'react-hook-form';

import {
  ZorroSetupFormFields,
  getZorroSetupSchemaObject,
} from './ZorroSetupUtils';

type UseZorroSetupForm = (props?: {
  employerId?: string;
  shouldShowCoverageStartDate?: boolean;
  isFinalizationMode?: boolean;
}) => {
  zorroSetupForm: UseFormReturn<ZorroSetupFormFields>;
  onSubmit: () => Promise<boolean>;
  allAgencies: AllAgenciesDto;
};

const blankAllAgencies: AllAgenciesDto = {
  zorroPartners: [],
  producers: [],
  enrollmentTeams: [],
};

const blankEmployerAgencies: AgencyDto[] = [];

export const useZorroSetupForm: UseZorroSetupForm = ({
  employerId,
  shouldShowCoverageStartDate,
  isFinalizationMode,
} = {}) => {
  const { user } = useImpersonation();
  const { isAgent, isLoading } = useRoles();

  const queryClient = useQueryClient();

  const { data: employer } = useMonolithQuery({
    method: 'employersControllerFindOne',
    params: employerId && [employerId],
  });

  const { data: allAgencies = blankAllAgencies } = useMonolithQuery(
    isAgent
      ? {
          method: 'agenciesControllerFindAssignableAgencies',
          params: user && !isLoading && [user.tenantId],
        }
      : {
          method: 'agenciesControllerFindAll',
          params: !isLoading && [],
        }
  );

  const { data: employerAgencies = blankEmployerAgencies } = useMonolithQuery({
    method: 'agenciesControllerFindMany',
    params: employer && !isLoading && [employer.id],
  });

  const allAgenciesWithEmployerAgencies = useMemo(() => {
    const _allAgencies: AllAgenciesDto = _cloneDeep(allAgencies);

    const { zorroPartners, enrollmentTeams, producers } = _allAgencies;

    for (const employerAgency of employerAgencies) {
      switch (employerAgency.type) {
        case AgencyType.ZORRO_PARTNER: {
          if (
            !zorroPartners.some(
              (agencyPartner) => agencyPartner.id === employerAgency.id
            )
          ) {
            zorroPartners.push(employerAgency);
          }
          break;
        }
        case AgencyType.ENROLLMENT_TEAM: {
          if (
            !enrollmentTeams.some(
              (agencyEnrollmentTeam) =>
                agencyEnrollmentTeam.id === employerAgency.id
            )
          ) {
            enrollmentTeams.push(employerAgency);
          }
          break;
        }
        case AgencyType.PRODUCER: {
          if (
            !producers.some(
              (agencyProducer) => agencyProducer.id === employerAgency.id
            )
          ) {
            producers.push(employerAgency);
          }
          break;
        }
      }
    }

    return _allAgencies;
  }, [allAgencies, employerAgencies]);

  const zorroSetupForm = useForm<ZorroSetupFormFields>({
    mode: 'all',
    resolver: yupResolver(
      getZorroSetupSchemaObject(isFinalizationMode, shouldShowCoverageStartDate)
    ),
    defaultValues: {
      producerId: employer?.producerId || null,
      enrollmentTeamIds: employer?.enrollmentTeamIds || [],
      zorroPartnerId: isAgent
        ? employer?.zorroPartnerId || user?.tenantId || ''
        : employer?.zorroPartnerId || '',
      producerAgentId: employer?.producerAgentId || null,
      ccEmailList: employer?.ccEmailList || [],
      crmRecordId: employer?.crmRecordId
        ? Number.parseInt(employer?.crmRecordId)
        : null,
      consultationLink: employer?.consultationLink || '',
      prospectCoverageStartDate: employer?.prospectCoverageStartDate
        ? parseDateISO(employer.prospectCoverageStartDate).toDate()
        : undefined,
    },
  });

  return {
    allAgencies: allAgenciesWithEmployerAgencies,
    zorroSetupForm,
    onSubmit: async () => {
      const data = zorroSetupForm.getValues();

      try {
        if (employerId) {
          await callEndpoint({
            method: 'employersControllerUpdate',
            params: [
              employerId,
              {
                producerId: data.producerId,
                enrollmentTeamIds: data.enrollmentTeamIds,
                zorroPartnerId: data.zorroPartnerId,
                crmRecordId: data.crmRecordId?.toString() || null,
                consultationLink: data.consultationLink,
                ccEmailList: data.ccEmailList || [],
                isProspect: false,
                producerAgentId: data.producerAgentId,
                prospectCoverageStartDate: data.prospectCoverageStartDate
                  ? formatDateISO(data.prospectCoverageStartDate)
                  : undefined,
              },
            ],
          });
        }

        await queryClient.invalidateQueries();
        return true;
      } catch (error) {
        logger.error(error);
        showErrorNotification({ message: responseErrorToString(error) });
      }

      return false;
    },
  };
};
