import { FinchConnectEmployerStatusDto } from '@zorro/clients';
import { Card, Group, Stack, Text } from '@zorro/zorro-ui-design';

export const FinchConnectionCard = ({
  status,
  providerId,
  connectionStatus,
}: FinchConnectEmployerStatusDto) => {
  return (
    <Card maw="60rem" p="xl">
      <Group wrap="nowrap" gap="xl">
        <Text>Provider Logo for {providerId}</Text>
        <Group wrap="nowrap">
          <Stack>
            <Group wrap="nowrap" align="start">
              <Stack gap="xxs" flex="0 0 auto">
                <Text>Internal status:</Text>
                <Text size="sm">(our part in the integration)</Text>
              </Stack>
              <Text>{status}</Text>
            </Group>
            <Group wrap="nowrap" align="start">
              <Stack gap="xxs" flex="0 0 auto">
                <Text>Provider status:</Text>
                <Text size="sm">(your part in the integration)</Text>
              </Stack>
              <Stack gap="sm">
                <Text>{connectionStatus?.status}</Text>
                <Text size="sm">{connectionStatus?.message}</Text>
              </Stack>
            </Group>
          </Stack>
        </Group>
      </Group>
    </Card>
  );
};
