import { Box, Center, Flex, Loader, Stack } from '@zorro/zorro-ui-design';
import { ReactNode } from 'react';

import { AppLayoutConstants } from '../AppLayout';

type FullPageLoaderProps = {
  children?: ReactNode;
};

export const FullPageLoader = ({ children }: FullPageLoaderProps) => {
  return (
    <Center
      style={{
        height: `calc(100vh - ${AppLayoutConstants.HEADER_HEIGHT}px - calc(2*var(--mantine-spacing-sm)))`,
      }}
    >
      <Box style={{ transform: 'translate(0, -50%)' }}>
        <Flex justify="center" align="center" h="100%">
          <Stack align="center">
            <Loader />
            {children}
          </Stack>
        </Flex>
      </Box>
    </Center>
  );
};
