import { useMonolithQuery } from '@zorro/shared/utils';
import { List } from '@zorro/zorro-ui-design';

type Props = {
  employerId: string;
};

export const FinchEmployeesList = ({ employerId }: Props) => {
  const { data: finchEmployees = [], isLoading } = useMonolithQuery({
    method: 'finchControllerFindEmployees',
    params: [employerId],
  });

  if (isLoading) {
    return null;
  }

  return (
    <List w="50%" mah="20rem" style={{ overflowY: 'auto' }}>
      Individuals
      {finchEmployees.map((employee) => (
        <List.Item key={employee.employeeId}>
          {employee.employeeId},{employee.syncType}
        </List.Item>
      ))}
    </List>
  );
};
