import { IconDownload, IconTrash } from '@tabler/icons-react';
import { DocumentsIcon } from '@zorro/shared/assets';
import { downloadEmployerDocument } from '@zorro/shared/utils';
import { ActionIcon, Card, Flex, Space, Text } from '@zorro/zorro-ui-design';

interface DownloadableDocumentProps {
  name: string;
  documentLocationUrl: string;
  onDelete?: () => void;
  canDelete: boolean;
  onDownload?: () => void;
}

const DownloadableDocument = ({
  name,
  documentLocationUrl,
  onDelete,
  canDelete,
  onDownload = async () => {
    await downloadEmployerDocument(documentLocationUrl, name);
  },
}: DownloadableDocumentProps) => {
  return (
    <Card key={name}>
      <Flex justify="space-between" align="center" w="100%">
        <Flex
          justify="space-evenly"
          align="center"
          style={{ overflow: 'hidden' }}
        >
          <DocumentsIcon />
          <Space w="md" />
          <Text mr="sm">{name}</Text>
        </Flex>
        <Flex align="flex-end">
          <ActionIcon size="md">
            <IconDownload onClick={onDownload} size={20} />
          </ActionIcon>
          <>
            <Space w="sm" />
            {canDelete && (
              <ActionIcon size="md">
                <IconTrash onClick={onDelete} size={20} />
              </ActionIcon>
            )}
          </>
        </Flex>
      </Flex>
    </Card>
  );
};

export { DownloadableDocument };
