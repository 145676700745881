import { useOs, useWindowEvent } from '@mantine/hooks';
import { IconSearch, IconX } from '@tabler/icons-react';
import { useDebouncedValue, useMonolithQuery } from '@zorro/shared/utils';
import {
  ActionIcon,
  Group,
  Icon,
  Modal,
  Text,
  TextInput,
  UnstyledButton,
  brand,
} from '@zorro/zorro-ui-design';
import { useState } from 'react';

import { useAppLayout } from '../../AppLayoutContext';
import classNames from './AppLayoutHeaderSearch.module.scss';
import { AppLayoutHeaderSearchButtons } from './AppLayoutHeaderSearchButtons';
import { AppLayoutHeaderSearchFooter } from './AppLayoutHeaderSearchFooter';
import { AppLayoutHeaderSearchResults } from './AppLayoutHeaderSearchResults';

export const AppLayoutHeaderSearch = () => {
  const os = useOs();
  const { isSearchOpen, toggleSearch } = useAppLayout();
  const [searchTerm, setSearchTerm] = useState<string>('');
  const { debounced: debouncedSearchTerm } = useDebouncedValue({
    value: searchTerm,
    wait: 500,
  });

  const { data: searchResults, isLoading } = useMonolithQuery({
    method: 'searchControllerSearch',
    params: debouncedSearchTerm && [debouncedSearchTerm],
  });

  useWindowEvent('keydown', (event) => {
    if (event.key !== 'b') {
      return;
    }

    if (
      (os === 'macos' && event.metaKey) ||
      (os === 'windows' && event.ctrlKey)
    ) {
      toggleSearch();
    }
  });

  return (
    <>
      <UnstyledButton onClick={toggleSearch} classNames={classNames}>
        <Group gap="xs">
          <Icon icon={IconSearch} color={brand.zorroGray400} />
          <Text c="zorroGray.4">Search across Zorro...</Text>
        </Group>
      </UnstyledButton>
      <Modal
        opened={isSearchOpen}
        withCloseButton={false}
        onClose={toggleSearch}
        styles={{
          body: {
            position: 'relative',
            padding: '0 1rem',
            height: '32rem',
            width: '51.25rem',
          },
          header: { padding: '0 1rem', height: '3rem', minHeight: '3rem' },
          title: { width: '100%' },
          content: {
            position: 'absolute',
            left: '19.5rem',
            top: '0.75rem',
          },
        }}
        size="xl"
        title={
          <Group w="100%">
            <Icon icon={IconSearch} color={brand.zorroGray400} />
            <TextInput
              onChange={(event) => setSearchTerm(event.currentTarget.value)}
              styles={{ input: { border: 'none', padding: 0 } }}
              value={searchTerm}
              w="32rem"
            />
            {searchTerm && (
              <ActionIcon onClick={() => setSearchTerm('')} ml="auto">
                <Icon icon={IconX} />
              </ActionIcon>
            )}
          </Group>
        }
      >
        <AppLayoutHeaderSearchButtons />
        <AppLayoutHeaderSearchResults
          searchResults={searchResults}
          isLoading={isLoading}
        />
        <AppLayoutHeaderSearchFooter />
      </Modal>
    </>
  );
};
