import { ActionIcon, ActionIconProps } from '@mantine/core';
import { HTMLAttributes, forwardRef } from 'react';

import classNames from './ActionIcon.module.scss';

export type IActionIconProps = Omit<ActionIconProps, 'variant' | 'radius'> &
  HTMLAttributes<HTMLButtonElement> & {
    isChecked?: boolean;
    isTransparent?: boolean;
    isOnColor?: boolean;
  };

export const ZorroActionIcon = forwardRef<HTMLButtonElement, IActionIconProps>(
  (
    {
      disabled = false,
      onClick,
      isChecked = false,
      size = 'lg',
      isTransparent = false,
      isOnColor = false,
      color = 'var(--mantine-color-zorroCoal-9)',
      ...props
    },
    ref
  ) => {
    const handleClick = (
      event: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ) => {
      if (!disabled && onClick) {
        onClick(event);
      }
    };

    return (
      <ActionIcon
        ref={ref}
        size={size}
        classNames={classNames}
        data-checked={isChecked}
        data-disabled={disabled}
        data-transparent={isTransparent}
        data-oncolor={isOnColor}
        style={{ '--ai-color': color }}
        {...props}
        onClick={handleClick}
      />
    );
  }
);

ZorroActionIcon.displayName = 'ZorroActionIcon';
