import { AppShell } from '@mantine/core';
import { useViewportSize } from '@mantine/hooks';
import { Box } from '@zorro/zorro-ui-design';
import { ReactNode, forwardRef } from 'react';

import { AppLayoutConstants } from '../AppLayoutUtils';

type Props = {
  children: ReactNode;
};

export const AppLayoutMain = forwardRef<HTMLDivElement, Props>(
  ({ children }: Props, ref) => {
    const { height: viewportHeight } = useViewportSize();

    return (
      <AppShell.Main
        style={{
          height: `${viewportHeight - AppLayoutConstants.HEADER_HEIGHT}px`,
        }}
      >
        <Box ref={ref}>
          <Box>
            <Box>{children}</Box>
          </Box>
        </Box>
      </AppShell.Main>
    );
  }
);

AppLayoutMain.displayName = 'AppLayoutMain';
