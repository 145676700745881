import { LogoDark } from '@zorro/shared/assets';
import { Box, Button, Stack, Text, Title } from '@zorro/zorro-ui-design';

export const InviteByEmailDecoration = () => {
  return (
    <>
      <LogoDark />
      <Stack
        style={{ borderRadius: '16px 16px 0 0' }}
        bg="zorroWhite.0"
        h="100%"
        p="2rem"
      >
        <Text fw={500} c="zorroSky.5">
          Action required
        </Text>
        <Text fw={500} mt="xl" c="zorroGray.4">
          Welcome to Zorro!
        </Text>
        <Title fw={700}>
          Complete your company profile on Zorro to get set up
        </Title>
        <Box style={{ borderRadius: '16px' }} bg="#D9DBDF" w="152px" h="9px" />
        <Box style={{ borderRadius: '16px' }} bg="#D9DBDF" h="9px" />
        <Button size="xs" w="10px" px="6rem" h="2.5rem" mt="lg" disableHover>
          <Text fs="1rem" c="white">
            Set up your company
          </Text>
        </Button>
      </Stack>
    </>
  );
};
