import { yupResolver } from '@hookform/resolvers/yup';
import { DocumentType, EmployerDocumentDto } from '@zorro/clients';
import { parseDateISO } from '@zorro/shared/formatters';
import {
  ERROR_MESSAGES,
  VALIDATION_MESSAGES,
  createEmployerDocumentUrl,
  showErrorNotification,
  useForm,
  useMonolithQuery,
} from '@zorro/shared/utils';
import { DeepReadonly } from '@zorro/types';
import {
  FormErrorMessage,
  Grid,
  Select,
  Textarea,
} from '@zorro/zorro-ui-design';
import { useState } from 'react';
import { Control, Controller } from 'react-hook-form';
import * as yup from 'yup';

import { FileUploader } from '../FileUploader';
import { FormFooter } from '../FormFooter';
import { FullPageLoader } from '../FullPageLoader';
import { useLoadingOverlay } from '../LoadingOverlayContext';
import { UploadedFileBox } from '../UploadedFileBox';
import { useMonolithMutation } from '../hooks';

const getUploadFileSchema = (isEditMode: boolean) =>
  isEditMode
    ? yup.object({
        document: yup.mixed().optional(),
        description: yup.string().optional(),
        openEnrollmentPeriodId: yup.string().optional().nullable(),
      })
    : yup.object({
        document: yup.mixed().required(VALIDATION_MESSAGES.documentRequired),
        description: yup.string().optional().nullable(),
        openEnrollmentPeriodId: yup
          .string()
          .required(VALIDATION_MESSAGES.planYearRequired),
      });

export type UploadDocumentProps = {
  employerId: string;
  existingDocument?: EmployerDocumentDto;
  type: DocumentType;
  viewedOpenEnrollmentPeriodId?: string;
  uploadedFile?: File;
  onSuccess: () => Promise<void> | void;
  onCancel: () => Promise<void> | void;
};

export interface EmployerDocumentFormFields {
  document?: File;
  openEnrollmentPeriodId: string;
  description?: string | null;
}

const uploadLabel: DeepReadonly<{
  [documentType in DocumentType]: string;
}> = {
  [DocumentType.ADMIN]: 'Document',
  [DocumentType.ZORRO_PAY]: 'Report file',
  [DocumentType.PLAN]: 'Document',
  [DocumentType.COMPLIANCE]: 'Report file',
  [DocumentType.INVOICE]: 'Document',
};

export function EmployerDocumentForm({
  employerId,
  existingDocument,
  type,
  viewedOpenEnrollmentPeriodId,
  onSuccess,
  onCancel,
  uploadedFile,
}: UploadDocumentProps) {
  const {
    handleSubmit,
    getValues,
    control,
    watch,
    setValue,
    formState: { isValid, isDirty, errors },
  } = useForm<EmployerDocumentFormFields>({
    mode: 'all',
    resolver: yupResolver(getUploadFileSchema(!!existingDocument)),
    defaultValues: {
      document: uploadedFile,
      openEnrollmentPeriodId:
        existingDocument?.openEnrollmentPeriodId ??
        viewedOpenEnrollmentPeriodId,
      description: existingDocument?.description,
    },
  });
  const [shouldOverrideExistingFile, setShouldOverrideExistingFile] = useState(
    !existingDocument
  );
  const { startLoading, stopLoading } = useLoadingOverlay();

  const { tryMutate: createDocument } = useMonolithMutation({
    method: 'employerDocumentControllerPostDocument',
    successMessage: 'Document uploaded successfully!',
  });

  const { tryMutate: updateDocument } = useMonolithMutation({
    method: 'employerDocumentControllerUpdateDocument',
    successMessage: 'Document updated successfully!',
  });

  const { data: openEnrollmentPeriods = [], isLoading } = useMonolithQuery({
    method: 'openEnrollmentPeriodsControllerFindAllForEmployer',
    params: [employerId],
  });

  if (isLoading || !openEnrollmentPeriods) {
    return <FullPageLoader />;
  }

  const document = watch('document');

  const handleDocumentRemoval = () => {
    setValue('document', undefined, { shouldValidate: true });
    setShouldOverrideExistingFile(!!existingDocument);
  };

  const uploadDocument = async () => {
    try {
      startLoading();
      if (existingDocument) {
        let documentUrl = existingDocument.documentUrl;
        let fileName = existingDocument.fileName;
        if (shouldOverrideExistingFile) {
          if (!document) {
            showErrorNotification({
              message: ERROR_MESSAGES.NO_FILE_SELECTED_ERROR_MESSAGE,
            });
            return;
          }
          const { documentUrl: newDocumentUrl, fileName: newFileName } =
            await createEmployerDocumentUrl(document);
          documentUrl = newDocumentUrl;
          fileName = newFileName;
        }
        const { openEnrollmentPeriodId, description } = getValues();
        const updateDocumentResult = await updateDocument([
          existingDocument.id,
          {
            documentUrl,
            fileName,
            description,
            openEnrollmentPeriodId,
          },
        ]);
        if (updateDocumentResult.isOk()) {
          await onSuccess();
        }
      } else {
        if (!document) {
          showErrorNotification({
            message: ERROR_MESSAGES.NO_FILE_SELECTED_ERROR_MESSAGE,
          });
          return;
        }
        const { documentUrl, fileName } = await createEmployerDocumentUrl(
          document
        );
        const { openEnrollmentPeriodId, description } = getValues();
        const createDocumentResult = await createDocument([
          employerId,
          {
            type,
            documentUrl,
            fileName,
            description,
            openEnrollmentPeriodId,
          },
        ]);
        if (createDocumentResult.isOk()) {
          await onSuccess();
        }
      }
    } finally {
      stopLoading();
    }
  };

  return (
    <Grid>
      <Grid.Col span={12}>
        {!document && (shouldOverrideExistingFile || !existingDocument) && (
          <FileUploader
            control={control as unknown as Control}
            name="document"
            label={uploadLabel[type]}
            isLoading={false}
            onDrop={(files: File[], onBlur, onChange) => {
              onChange(files[0]);
              onBlur();
            }}
            isRequired
            isMultiple={false}
            isDisabled={!!document}
            zoneLabelText="Or drag it here"
            buttonText="Upload a file"
          />
        )}
        {document && (
          <UploadedFileBox
            onClickDelete={handleDocumentRemoval}
            name={document.name}
            label={uploadLabel[type]}
            isRequired
          />
        )}
        {existingDocument && !shouldOverrideExistingFile && (
          <UploadedFileBox
            onClickDelete={handleDocumentRemoval}
            name={existingDocument.fileName}
            label={uploadLabel[type]}
            isRequired
          />
        )}
      </Grid.Col>
      <Grid.Col span={12}>
        <Controller
          control={control}
          name="description"
          render={({ field: { ref: _ref, value, ...rest } }) => (
            <>
              <Textarea
                {...rest}
                value={value || ''}
                label="Description"
                placeholder="Type description..."
                minRows={3}
                autosize
              />
              <FormErrorMessage fieldName="description" errors={errors} />
            </>
          )}
        />
      </Grid.Col>
      <Grid.Col span={12}>
        <Controller
          control={control}
          name="openEnrollmentPeriodId"
          render={({ field }) => (
            <Select
              {...field}
              required={!existingDocument}
              label="Plan year"
              placeholder="Select plan year"
              data={openEnrollmentPeriods.map((openEnrollmentPeriod) => ({
                value: openEnrollmentPeriod.id,
                label: parseDateISO(openEnrollmentPeriod.effectiveFrom)
                  .year()
                  .toString(),
              }))}
            />
          )}
        />

        <FormErrorMessage fieldName="openEnrollmentPeriodId" errors={errors} />
      </Grid.Col>
      <FormFooter
        primaryLabel="Save"
        primaryButtonProps={{
          onClick: handleSubmit(uploadDocument),
          disabled: !isValid || !isDirty,
        }}
        secondaryLabel="Cancel"
        secondaryButtonProps={{ onClick: onCancel }}
      />
    </Grid>
  );
}
