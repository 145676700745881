import { Group, Stack, Text, brand } from '@zorro/zorro-ui-design';
import { CSSProperties, ReactElement } from 'react';

import classNames from './EmployeeLayoutSidebar.module.scss';

export type EmployeeLayoutSidebarSubitemProps = {
  id: string;
  label: string;
  onClick: () => void;
  leftContent?: ReactElement;
  rightContent?: ReactElement;
  isChecked?: boolean;
  description?: string;
  align?: CSSProperties['alignItems'];
};

type Props = {
  subitems: EmployeeLayoutSidebarSubitemProps[];
};

export const EmployeeLayoutSidebarSubitem = ({ subitems }: Props) => {
  if (subitems.length === 0) {
    return null;
  }

  return subitems.map(
    ({
      id,
      label,
      description,
      isChecked,
      onClick,
      leftContent,
      rightContent,
      align = 'start',
    }) => (
      <Group
        bg={isChecked ? brand.primaryButtonSelected : undefined}
        style={{ borderRadius: 8, cursor: 'pointer' }}
        classNames={classNames}
        onClick={onClick}
        align={align}
        wrap="nowrap"
        pl="2.75rem"
        gap="xs"
        key={id}
        py="md"
        px="lg"
      >
        {leftContent}
        <Stack gap="0.25rem" mr="auto">
          <Text size="sm">{label}</Text>
          {description && (
            <Text c="zorroGray.4" size="sm">
              {description}
            </Text>
          )}
        </Stack>
        {rightContent}
      </Group>
    )
  );
};
