import { Center } from '@zorro/zorro-ui-design';

import { SVGIconProps } from '../types';
import classNames from './Icon.module.scss';

export const ImagePlaceholderIcon = ({ style, grow }: SVGIconProps) => {
  return (
    <Center style={style} classNames={classNames} data-grow={grow}>
      <svg
        width="32"
        height="24"
        viewBox="0 0 32 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="32" height="24" rx="4" fill="#EDEFF6" />
        <path
          d="M18.4615 9.5998C19.821 9.5998 20.9231 8.52529 20.9231 7.1998C20.9231 5.87432 19.821 4.7998 18.4615 4.7998C17.1021 4.7998 16 5.87432 16 7.1998C16 8.52529 17.1021 9.5998 18.4615 9.5998Z"
          fill="white"
        />
        <mask
          id="mask0_706_38668"
          style={{ maskType: 'alpha' }}
          maskUnits="userSpaceOnUse"
          x="0"
          y="9"
          width="32"
          height="15"
        >
          <path
            d="M10.3134 9.82719L0 21L2.46154 24H28.3077L32 23.4V19.2L22.7918 12.4665C22.4179 12.1931 21.905 12.2123 21.5526 12.513L17.2308 16.2L11.8083 9.85575C11.4191 9.4004 10.7197 9.38704 10.3134 9.82719Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask0_706_38668)">
          <rect width="32" height="24" rx="4" fill="#F7F9FB" />
        </g>
      </svg>
    </Center>
  );
};
