/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum DocumentType {
  PLAN = 'PLAN',
  ADMIN = 'ADMIN',
  COMPLIANCE = 'COMPLIANCE',
  ZORRO_PAY = 'ZORRO_PAY',
  INVOICE = 'INVOICE',
}
