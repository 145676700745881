import { useDebouncedValue as useMantineDebouncedValue } from '@mantine/hooks';

type Props<T> = { value: T; wait: number };
type ReturnProps<T> = { debounced: T; isDebouncing: boolean };

export const useDebouncedValue = <T>({
  value,
  wait,
}: Props<T>): ReturnProps<T> => {
  const [debounced] = useMantineDebouncedValue(value, wait);

  return { debounced, isDebouncing: debounced !== value };
};
