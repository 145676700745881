import { Center, Text } from '@zorro/zorro-ui-design';

interface UserAvatarProps {
  firstName: string;
  lastName: string;
  color: string;
  size: number;
  textSize: number;
  textColor?: string;
}
export const UserAvatar = ({
  firstName,
  lastName,
  color,
  size,
  textSize,
  textColor,
}: UserAvatarProps) => {
  return (
    <Center
      style={{ borderRadius: '100%' }}
      bg={color}
      miw={size}
      mih={size}
      fw="400"
      w={size}
      h={size}
    >
      <Text c={textColor} size={`${textSize}px`}>
        {firstName?.[0]}
        {lastName?.[0]}
      </Text>
    </Center>
  );
};
