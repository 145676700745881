import { ComboboxItem, Popover, UnstyledButton } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';

import { ZorroGroup } from '../Group';
import { ZorroPill } from '../Pill';
import classNames from './MultiSelectPopover.module.scss';

export type IMultiSelectPopoverProps<T extends string[] | ComboboxItem[]> = {
  data: T;
  onRemove: (value: string) => void;
  isDisabled?: boolean;
};

export const MultiSelectPopover = <T extends string[] | ComboboxItem[]>({
  data,
  onRemove,
  isDisabled,
}: IMultiSelectPopoverProps<T>) => {
  const [opened, { toggle }] = useDisclosure();

  return (
    <Popover
      opened={opened}
      onChange={toggle}
      position="top"
      withArrow
      shadow="md"
    >
      <Popover.Target>
        <UnstyledButton classNames={classNames} onClick={toggle} size="xs">
          +{data.length}
        </UnstyledButton>
      </Popover.Target>
      <Popover.Dropdown>
        <ZorroGroup gap="xs">
          {data.map((item) => {
            const value = typeof item === 'object' ? item.value : item;
            const label = typeof item === 'object' ? item.label : item;

            return (
              <ZorroPill
                key={value}
                withRemoveButton
                onRemove={() => onRemove(value)}
                disabled={isDisabled}
              >
                {label}
              </ZorroPill>
            );
          })}
        </ZorroGroup>
      </Popover.Dropdown>
    </Popover>
  );
};
