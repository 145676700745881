import { Combobox, Group, Pill, PillsInput, useCombobox } from '@mantine/core';
import { useElementSize } from '@mantine/hooks';
import {
  IconChevronDown,
  IconSquare,
  IconSquareCheckFilled,
  IconX,
} from '@tabler/icons-react';
import {
  ForwardedRef,
  type ReactNode,
  forwardRef,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { ZorroActionIcon } from '../Icon';
import { MultiSelectPopover } from '../MultiSelectPopover/MultiSelectPopover';
import { ZorroPill } from '../Pill';
import { ZorroText } from '../Text';
import { brand } from '../theme';
import classNames from './ComboboxMultiSelect.module.scss';

type TComboboxProps = {
  data: {
    label: string;
    value: string;
  }[];
  value: string[];
  onChange: (value: string[]) => void;
  placeholder: string;
  label: ReactNode;
  size?: 'sm' | 'md' | 'lg';
  isReadonlyMode?: boolean;
};

export const ZorroComboboxMultiSelect = forwardRef(
  (
    {
      data,
      value,
      onChange,
      placeholder,
      label,
      size = 'lg',
      isReadonlyMode = false,
    }: TComboboxProps,
    ref: ForwardedRef<HTMLInputElement>
  ) => {
    const combobox = useCombobox({
      onDropdownClose: () => combobox.resetSelectedOption(),
      onDropdownOpen: () => combobox.updateSelectedOptionIndex('active'),
    });

    const { ref: containerRef, width: containerWidth } = useElementSize();

    const { ref: visibleValuesRef, width: visibleValuesWidth } =
      useElementSize();

    const selectedItems = useMemo(() => {
      const selected: TComboboxProps['data'] = [];

      for (const item of data) {
        if (value.includes(item.value)) {
          selected.push(item);
        }
      }

      return selected;
    }, [data, value]);

    const [popoverValues, setPopoverValues] = useState<TComboboxProps['data']>(
      []
    );

    const [search, setSearch] = useState('');

    const [indexDisplayFrom, setIndexDisplayFrom] = useState<
      number | undefined
    >(undefined);

    const checkIndexDisplayFrom = useCallback(
      (index: number) => {
        if (indexDisplayFrom === undefined || indexDisplayFrom === index) {
          setIndexDisplayFrom(index);
          setPopoverValues(selectedItems.slice(index));
        }

        if (indexDisplayFrom !== undefined && indexDisplayFrom !== index) {
          setIndexDisplayFrom(index);
          setPopoverValues(selectedItems?.slice(index));
        }
      },
      [indexDisplayFrom, selectedItems]
    );

    useEffect(() => {
      if (!visibleValuesRef.current) {
        return;
      }

      let totalWidth = 0;
      let fitCount = 0;
      const minInputWidth = 100;

      const elmsValues = Array.from(
        visibleValuesRef.current.children
      ) as HTMLElement[];

      elmsValues?.forEach((elmValue) => {
        elmValue.style.display = 'flex';
      });

      for (const elmValue of elmsValues) {
        const valueWidth = elmValue.offsetWidth;

        if (totalWidth + valueWidth < containerWidth - minInputWidth) {
          totalWidth += valueWidth;
          fitCount += 1;
        } else {
          for (
            let pillIndex = fitCount;
            pillIndex < elmsValues.length;
            pillIndex++
          ) {
            if (pillIndex !== 0) {
              elmsValues[pillIndex].style.display = 'none';
            }
          }
          break;
        }
      }

      if (fitCount > 0) {
        checkIndexDisplayFrom(fitCount);
      }
    }, [
      visibleValuesWidth,
      containerWidth,
      visibleValuesRef,
      selectedItems,
      setPopoverValues,
      checkIndexDisplayFrom,
    ]);

    const options = data
      .filter(({ label }) =>
        label.toLowerCase().includes(search.trim().toLowerCase())
      )
      .map((item) => (
        <Combobox.Option
          value={item.value}
          key={item.value}
          active={value.includes(item.value)}
        >
          <Group gap="sm">
            {value.includes(item.value) ? (
              <IconSquareCheckFilled />
            ) : (
              <IconSquare />
            )}
            {item.label}
          </Group>
        </Combobox.Option>
      ));

    const handleValueRemove = (itemToRemove: string) => {
      const newValue = value.filter((item) => item !== itemToRemove);
      onChange(newValue);
      setPopoverValues(
        popoverValues.filter(({ value }) => value !== itemToRemove)
      );
    };

    const handleValueSelect = (selectedItem: string) => {
      const isAlreadySelected = value.includes(selectedItem);
      const newValue = isAlreadySelected
        ? value.filter((item) => item !== selectedItem)
        : [...value, selectedItem];
      onChange(newValue);
    };

    const openCombobox = () => combobox.openDropdown();
    const closeCombobox = () => combobox.closeDropdown();

    if (isReadonlyMode) {
      return (
        <>
          <ZorroText size="sm" fw={400}>
            {label}
          </ZorroText>
          <Pill.Group className={classNames['pill-group']}>
            {selectedItems.map(({ value, label }) => (
              <ZorroPill key={value}>{label}</ZorroPill>
            ))}
          </Pill.Group>
        </>
      );
    }

    return (
      <Combobox store={combobox} onOptionSubmit={handleValueSelect}>
        <Combobox.Target ref={containerRef}>
          <PillsInput
            size={size}
            label={
              <ZorroText size="sm" fw={400}>
                {label}
              </ZorroText>
            }
            onClick={openCombobox}
            rightSection={
              <Group gap="0">
                {popoverValues.length > 0 && (
                  <MultiSelectPopover
                    data={popoverValues}
                    onRemove={handleValueRemove}
                  />
                )}
                <ZorroActionIcon size="md">
                  <IconChevronDown
                    className={classNames['chevron']}
                    size={20}
                    color={brand.zorroGray400}
                  />
                </ZorroActionIcon>

                <ZorroActionIcon size="md">
                  <IconX
                    className={classNames['remove']}
                    size={20}
                    color={brand.zorroGray400}
                    onClick={() => {
                      setPopoverValues([]);
                      onChange([]);
                    }}
                  />
                </ZorroActionIcon>
              </Group>
            }
            classNames={classNames}
            pointer
          >
            <Pill.Group className={classNames['pill-group']}>
              {selectedItems.length > 0 && (
                <Group
                  ref={visibleValuesRef}
                  gap="sm"
                  wrap="nowrap"
                  className={classNames['pills-wrapper']}
                >
                  {selectedItems.map(({ value, label }) => (
                    <ZorroPill
                      key={value}
                      withRemoveButton
                      onRemove={() => handleValueRemove(value)}
                    >
                      {label}
                    </ZorroPill>
                  ))}
                </Group>
              )}

              <Combobox.EventsTarget>
                <PillsInput.Field
                  onBlur={closeCombobox}
                  onFocus={openCombobox}
                  placeholder={
                    selectedItems.length > 0 ? undefined : placeholder
                  }
                  className={classNames['inputField']}
                  onKeyDown={(event) => {
                    if (event.key === 'Backspace' && search.length === 0) {
                      event.preventDefault();
                      const lastItem = value.at(-1);
                      if (lastItem) {
                        handleValueRemove(lastItem);
                      }
                    }
                  }}
                  onChange={(event) => {
                    combobox.updateSelectedOptionIndex();
                    setSearch(event.currentTarget.value);
                  }}
                  value={search}
                />
              </Combobox.EventsTarget>
            </Pill.Group>
          </PillsInput>
        </Combobox.Target>

        <Combobox.Dropdown>
          <Combobox.Options>{options}</Combobox.Options>
        </Combobox.Dropdown>
      </Combobox>
    );
  }
);

ZorroComboboxMultiSelect.displayName = 'ZorroComboboxMultiSelect';
