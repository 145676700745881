import { useAuth } from '@frontegg/nextjs';
import {
  EmployeeDto,
  Roles,
  UserDtoWithRoles,
  UserStatus,
} from '@zorro/clients';
import { useRouter } from 'next/router';

import { useMonolithQuery } from './useMonolithQuery';
import { getQueryValue } from './useRouter';

function extractFronteggUserRoles(
  roles: {
    id: string;
    key: string;
    name: string;
  }[]
) {
  return roles.map(({ id, key, name }) => ({
    id,
    key,
    name,
  }));
}

export type TUseImpersonation = {
  sessionId: string;
  isLoading: boolean;
  isImpersonated: boolean;
  user: UserDtoWithRoles | null;
  employee?: EmployeeDto | null;
  authUser: UserDtoWithRoles | null;
  authEmployee?: EmployeeDto | null;
};

export function useImpersonation(): TUseImpersonation {
  const router = useRouter();
  const {
    isAuthenticated,
    user: fronteggUser,
    isLoading: isLoadingFronteggUser,
  } = useAuth();

  const sessionId = fronteggUser?.sid ?? '';
  const frontEggUserRoles = extractFronteggUserRoles(fronteggUser?.roles ?? []);

  const { data: authUser, isLoading: isLoadingAuthUser } = useMonolithQuery({
    method: 'usersControllerFindUserByEmail',
    params: fronteggUser && [fronteggUser.email],
  });

  const { data: authEmployee, isLoading: isLoadingAuthEmployee } =
    useMonolithQuery({
      method: 'employeesControllerFindEmployeeByUserId',
      params: authUser && [authUser.id],
      enabled: frontEggUserRoles.some((role) => role.key === Roles.EMPLOYEE),
    });

  const impersonatedEmployeeId = getQueryValue(
    router.query.impersonatedEmployeeId
  );

  const isImpersonated = Boolean(impersonatedEmployeeId);

  const employeeId = impersonatedEmployeeId ?? authEmployee?.id;

  const { data: employee, isLoading: isLoadingEmployee } = useMonolithQuery({
    method: 'employeesControllerFindOne',
    params: employeeId && [employeeId],
  });

  const { data: user, isLoading: isLoadingUser } = useMonolithQuery({
    method: 'usersControllerGetFronteggUser',
    params: employee && [employee.userId, employee.employerId],
  });

  const isLoading =
    isLoadingFronteggUser ||
    isLoadingAuthUser ||
    isLoadingAuthEmployee ||
    isLoadingUser ||
    isLoadingEmployee;

  if (isLoading || !isAuthenticated || !fronteggUser) {
    return {
      sessionId,
      isLoading,
      isImpersonated,
      user: null,
      employee: null,
      authUser: null,
      authEmployee: null,
    };
  }

  // 🧠 edge case: no user in the database (e.g. omnipotent admin)
  if (!authUser || !user) {
    const adoptedUser: UserDtoWithRoles = {
      id: fronteggUser.id,
      tenantId: fronteggUser.tenantId,
      email: fronteggUser.email,
      roles: extractFronteggUserRoles(fronteggUser.roles),
      firstName: fronteggUser.name,
      lastName: '',
      profilePictureUrl: fronteggUser.profilePictureUrl,
      phoneNumber: fronteggUser.phoneNumber,
      status: fronteggUser.activatedForTenant
        ? UserStatus.ACTIVATED
        : UserStatus.PENDING_LOGIN, // 🧠 no way to differentiate pending login and pending invitation in useAuth
    };

    return {
      sessionId,
      isLoading,
      isImpersonated,
      user: adoptedUser,
      employee: null,
      authUser: adoptedUser,
      authEmployee: null,
    };
  }

  return {
    sessionId,
    isLoading,
    isImpersonated,
    user,
    employee,
    authUser: {
      ...authUser,
      roles: extractFronteggUserRoles(fronteggUser.roles),
    },
    authEmployee,
  };
}
