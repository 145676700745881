import { MultiSelect, MultiSelectProps } from '@mantine/core';
import { IconChevronDown } from '@tabler/icons-react';
import { ForwardedRef, forwardRef } from 'react';

import { ZorroIcon } from '../Icon';
import classNames from './MultiSelect.module.scss';

export interface IMultiSelectProps extends MultiSelectProps {
  variant?: 'rectangle';
}

export const ZorroMultiSelect = forwardRef(
  (
    {
      size = 'xl',
      nothingFoundMessage = 'No results found',
      clearable = true,
      searchable = true,
      variant,
      ...props
    }: IMultiSelectProps,
    ref: ForwardedRef<HTMLInputElement>
  ) => {
    return (
      <MultiSelect
        ref={ref}
        size={size}
        clearable={clearable}
        clearButtonProps={{ size: 'md' }}
        searchable={searchable}
        classNames={classNames}
        nothingFoundMessage={nothingFoundMessage}
        wrapperProps={{ 'data-variant': variant }}
        /**
         * clearable prop only works when rightSection prop is not set
         * See more: https://mantine.dev/core/multi-select/#clearable
         */
        rightSection={
          clearable ? undefined : <ZorroIcon icon={IconChevronDown} />
        }
        {...props}
      />
    );
  }
);

ZorroMultiSelect.displayName = 'ZorroMultiSelect';
