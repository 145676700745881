import { useRoles } from '@zorro/shared/utils';
import { FormErrorMessage, Grid, Space } from '@zorro/zorro-ui-design';
import { Controller, UseFormReturn } from 'react-hook-form';

import { ApplicableLargeEmployerInput } from '../ApplicableLargeEmployerInput';
import { PayrollCycleInput } from '../PayrollCycleInput';
import { WaitingPeriodInput } from '../WaitingPeriodInput';
import { CompanySetupFormFields } from './CompanySetupUtils';

type Props = {
  companySetupForm: UseFormReturn<CompanySetupFormFields>;
  isFinalizationMode?: boolean;
};

export const CompanySetupForm = ({
  companySetupForm,
  isFinalizationMode = false,
}: Props) => {
  useRoles();
  const { isAgentWithoutSupervisor: isReadonlyMode } = useRoles();
  const { control, formState } = companySetupForm;
  const { errors } = formState;

  return (
    <Grid>
      <Grid.Col span={6}>
        <Controller
          name="payrollCycle"
          control={control}
          render={({ field }) => (
            <PayrollCycleInput
              {...field}
              disabled={isReadonlyMode}
              selectProps={{ required: isFinalizationMode }}
              size="md"
            />
          )}
        />
        <FormErrorMessage fieldName="payrollCycle" errors={errors} />
      </Grid.Col>

      <Grid.Col span={6}>
        <Controller
          name="waitingPeriod"
          control={control}
          render={({ field }) => (
            <WaitingPeriodInput
              {...field}
              disabled={isReadonlyMode}
              isRequired={isFinalizationMode}
            />
          )}
        />
        <FormErrorMessage fieldName="waitingPeriod" errors={errors} />
      </Grid.Col>
      <Grid.Col>
        <Space h={16} />
        <Controller
          name="isApplicableLargeEmployer"
          control={control}
          render={({ field }) => (
            <ApplicableLargeEmployerInput
              {...field}
              isReadOnly={isReadonlyMode}
              radioGroupProps={{ required: isFinalizationMode }}
            />
          )}
        />
        <FormErrorMessage
          fieldName="isApplicableLargeEmployer"
          errors={errors}
        />
      </Grid.Col>
    </Grid>
  );
};
