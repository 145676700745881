import {
  ContactIcon,
  IconPersonPurple,
  IconPremiumRights,
  IconScale,
} from '@zorro/shared/assets';

import {
  ContactsFormFields,
  mapContactItemsToReviewItems,
} from '../../../ContactsForm';
import { AddEmployerStepType } from '../../AddEmployerStep/AddEmployerUtils';
import { ReviewCard } from '../ReviewCard';

const iconProps = {
  style: { width: 40, height: 40 },
  grow: true,
};

type Props = {
  isFinalizationMode: boolean;
  fields: ContactsFormFields;
  employerId: string;
  isValid: boolean;
};

export const ContactsCard = ({
  isFinalizationMode,
  employerId,
  fields,
  isValid,
}: Props) => {
  const { onboardingContacts, legalContacts, hrContacts, financeContacts } =
    fields;

  return (
    <ReviewCard
      isValid={isValid}
      title="Contacts details"
      employerId={employerId}
      step={AddEmployerStepType.CONTACTS}
      items={[
        ...(isFinalizationMode
          ? mapContactItemsToReviewItems(
              onboardingContacts,
              'Onboarding',
              <IconPersonPurple {...iconProps} />,
              true
            )
          : []),
        ...mapContactItemsToReviewItems(
          legalContacts,
          'Legal',
          <IconScale {...iconProps} />,
          isFinalizationMode
        ),
        ...mapContactItemsToReviewItems(
          hrContacts,
          'HR',
          <ContactIcon {...iconProps} />,
          isFinalizationMode
        ),
        ...mapContactItemsToReviewItems(
          financeContacts,
          'ZorroPay',
          <IconPremiumRights {...iconProps} />,
          isFinalizationMode
        ),
      ]}
    />
  );
};
