import { Chip } from '@mantine/core';
import { IconSearch, IconX } from '@tabler/icons-react';
import {
  ActionIcon,
  Button,
  Divider,
  Flex,
  Group,
  ITableProps,
  Icon,
  NewTable,
  Space,
  Text,
  TextInput,
  Title,
  brand,
} from '@zorro/zorro-ui-design';
import { ReactElement, ReactNode, useEffect, useRef, useState } from 'react';

import { DownloadButton } from '../DownloadButton';

type SearchableDataTableProps = {
  componentsAfterSearch?: ReactNode;
  componentsRightDownloadButton?: ReactNode;
  componentsLeftToDownloadButton?: ReactNode;
  onDownload: () => void;
  searchValue: string;
  onSearchChange: (value: string) => void;
  isLoading?: boolean;
  availableViews?: { value: string; label: string; count: number }[];
  currentView?: string;
  setCurrentView?: (view: string) => void;
  filtersCount?: number;
  clearFilters?: () => void;
  isSearchEnabled?: boolean;
  clearSelectedEmployees?: () => void;
  heading: string | ReactElement;
} & ITableProps;

export function SearchableDataTable({
  componentsAfterSearch,
  componentsRightDownloadButton,
  componentsLeftToDownloadButton,
  searchValue = '',
  onSearchChange,
  onDownload,
  isLoading = false,
  availableViews,
  currentView,
  setCurrentView,
  filtersCount = 0,
  clearFilters,
  clearSelectedEmployees,
  heading,
  isSearchEnabled = false,
  ...rest
}: SearchableDataTableProps) {
  const selectedRecordsCount = rest?.selectedRecords?.length || 0;
  const [isSearchOpen, setIsSearchOpen] = useState<boolean>(false);
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (isSearchOpen && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isSearchOpen]);

  return (
    <>
      <Group align="center" mb="md" h="2.5rem">
        {typeof heading === 'string' ? <Title>{heading}</Title> : heading}
        {isSearchEnabled &&
          (isSearchOpen ? (
            <TextInput
              ref={inputRef}
              disabled={isLoading}
              leftSection={
                <IconSearch
                  style={{
                    color: brand.zorroGray800,
                    width: '20px',
                    height: '20px',
                  }}
                />
              }
              rightSection={
                <ActionIcon size="sm" color={brand.zorroGray800}>
                  <IconX
                    onClick={() => {
                      onSearchChange('');
                      setIsSearchOpen(false);
                    }}
                  />
                </ActionIcon>
              }
              styles={{
                input: {
                  fontSize: 'var(--mantine-font-size-sm)',
                  fontWeight: 400,
                  borderColor: brand.zorroGray800,
                },
              }}
              placeholder="Search Employees"
              size="sm"
              w="25rem"
              value={searchValue}
              onInput={(args) => {
                onSearchChange(args.currentTarget.value);
              }}
            />
          ) : (
            <ActionIcon onClick={() => setIsSearchOpen(true)}>
              <Icon
                icon={IconSearch}
                color={brand.zorroGray800}
                style={{
                  color: brand.zorroGray800,
                  width: '20px',
                  height: '20px',
                }}
              />
            </ActionIcon>
          ))}
      </Group>
      <Group justify="space-between">
        <Chip.Group
          value={currentView}
          onChange={setCurrentView}
          multiple={false}
        >
          <Group>
            {availableViews?.map((view) => (
              <Chip
                key={view.value}
                value={view.value}
                variant="filled"
                color={brand.primaryButtonSelected}
                style={{
                  '--chip-color': brand.zorroCoal900,
                }}
              >
                {`${view.label} (${view.count})`}
              </Chip>
            ))}
          </Group>
        </Chip.Group>
        {filtersCount > 0 && clearFilters && (
          <Group>
            <Divider orientation="vertical" />
            <Button
              size="sm"
              variant="default"
              style={{
                borderRadius: '4px',
                borderWidth: '1px',
                borderColor: brand.zorroGray200,
                color: brand.zorroCoal900,
                backgroundColor: brand.primaryButtonSelected,
              }}
              onClick={() => clearFilters()}
              rightSection={
                <Icon icon={IconX} size="16px" color={brand.zorroGray800} />
              }
            >{`Filters: ${filtersCount}`}</Button>
          </Group>
        )}
        {componentsAfterSearch}

        <Flex justify="space-between" align="end">
          <Group h="100%" gap="lg" justify="right" pos="relative">
            {selectedRecordsCount !== 0 && (
              <Flex align="center" gap="xs">
                <Text c="zorroCoal.9">
                  {selectedRecordsCount} items selected
                </Text>
                <ActionIcon onClick={clearSelectedEmployees}>
                  <Icon icon={IconX} size="16px" color={brand.zorroGray800} />
                </ActionIcon>
              </Flex>
            )}
            {componentsLeftToDownloadButton}
            <DownloadButton onDownload={onDownload} isDisabled={isLoading} />
            {componentsRightDownloadButton}
          </Group>
        </Flex>
      </Group>
      <Space h="md" />

      <NewTable fetching={isLoading} {...rest} />
    </>
  );
}
