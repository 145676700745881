import { ComboboxData, MantineSize } from '@mantine/core';
import { MajorMedicalCarrierDto } from '@zorro/clients';
import { useMonolithQuery } from '@zorro/shared/utils';
import {
  Autocomplete,
  Checkbox,
  FormErrorMessage,
  Select,
} from '@zorro/zorro-ui-design';
import { useEffect, useMemo, useState } from 'react';
import {
  FieldPath,
  FieldValues,
  UseControllerProps,
  useController,
} from 'react-hook-form';

import { useAnalytics } from '../Analytics';

function getCarrierOptions(
  externalData?: string[],
  data?: MajorMedicalCarrierDto[]
): ComboboxData {
  if (externalData) {
    return externalData;
  }

  if (data) {
    const carriers = data.map(({ name }) => name);
    const uniqueCarriers = new Set(carriers);
    return [...uniqueCarriers];
  }

  return [];
}

type Props<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>
> = UseControllerProps<TFieldValues, TName> & {
  placeholder?: string;
  label?: string;
  isDisabled?: boolean;
  isRequired?: boolean;
  isEditable?: boolean;
  size?: MantineSize;
  shouldShowError?: boolean;
  carriers?: string[];
};

export function CarrierInput<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>
>({
  placeholder = 'Carrier',
  label = 'Carrier',
  size = 'lg',
  isRequired,
  isDisabled,
  isEditable = false,
  shouldShowError = false,
  carriers,
  ...formProps
}: Props<TFieldValues, TName>) {
  const isExternalData = Boolean(carriers);

  const [isEditMode, setIsEditMode] = useState<boolean>(isEditable);
  const { sendEvent } = useAnalytics();

  const {
    field,
    formState: { errors },
  } = useController(formProps);

  const { data, isLoading } = useMonolithQuery({
    method: 'majorMedicalCarriersControllerGetCarriers',
    params: [],
    enabled: !isExternalData,
  });

  const carrierOptions = useMemo(
    () => getCarrierOptions(carriers, data),
    [carriers, data]
  );
  const shouldDisable = isDisabled || isLoading;

  useEffect(() => {
    if (carrierOptions.length === 0 || !field.value || isEditable) {
      return;
    }
    const isCustomCarrier = !carrierOptions.includes(field.value);
    setIsEditMode(isCustomCarrier);
  }, [carrierOptions, field.value, setIsEditMode, isEditable]);

  return (
    <>
      {isEditMode ? (
        <Autocomplete
          {...field}
          size={size}
          required={isRequired}
          disabled={shouldDisable}
          placeholder={placeholder}
          label={label}
          data={carrierOptions}
        />
      ) : (
        <Select
          {...field}
          size={size}
          required={isRequired}
          disabled={shouldDisable}
          placeholder={placeholder}
          label={label}
          data={carrierOptions}
          allowDeselect={false}
          searchable
          onChange={(...changePayload) => {
            field.onChange(...changePayload);
            sendEvent('select_carrier');
          }}
        />
      )}

      {!isEditable && (
        <Checkbox
          isChecked={isEditMode}
          onChange={(event) => {
            const checked = event.currentTarget.checked;
            setIsEditMode(checked);
            sendEvent('not_listed_carrier', { checked });
          }}
          label="Carrier not listed"
          shouldHaveBorder={false}
          disabled={shouldDisable}
          my="sm"
        />
      )}

      {shouldShowError && (
        <FormErrorMessage fieldName={formProps.name} errors={errors} />
      )}
    </>
  );
}
