import { IconX } from '@tabler/icons-react';
import { ActionIcon, Box, Group, Text, brand } from '@zorro/zorro-ui-design';

import { FileUploadDisplay } from '../FileUploadDisplay';

type Props = {
  children?: React.ReactNode;
  name?: string;
  onClickDelete: () => void;
  onClickDownload?: () => void;
  label?: string;
  isRequired?: boolean;
  isReadonly?: boolean;
};

/**
 * UploadedFileBox
 * @param name - if children is provided, name is not needed
 */

export const UploadedFileBox = ({
  children,
  onClickDelete,
  name,
  onClickDownload,
  label,
  isRequired = false,
  isReadonly = false,
}: Props) => {
  return (
    <>
      <Text size="sm" fw={400} lts="sm">
        {label}
        {isRequired && '*'}
      </Text>
      <Box
        px="xs"
        py="xs"
        style={(theme) => ({
          borderRadius: theme.radius.sm,
          width: '100%',
          backgroundColor: isReadonly ? brand.zorroPlum200 : brand.zorroGray100,
        })}
      >
        <Group
          align="center"
          justify="space-between"
          display="flex"
          wrap="nowrap"
          gap={0}
        >
          {children || (
            <FileUploadDisplay
              onClick={onClickDownload}
              fileName={name ?? ''}
            />
          )}

          {isReadonly ? undefined : (
            <ActionIcon onClick={onClickDelete} isTransparent>
              <IconX color={brand.zorroCoal900} />
            </ActionIcon>
          )}
        </Group>
      </Box>
    </>
  );
};
