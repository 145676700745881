import { CopyIcon } from '@zorro/shared/assets';
import { ActionIcon } from '@zorro/zorro-ui-design';
import { CopyToClipboard } from 'react-copy-to-clipboard';

type CopyToClipboardActionIconProps = {
  numberToCopy: string;
};

const CopyToClipboardActionIcon = ({
  numberToCopy,
}: CopyToClipboardActionIconProps) => {
  return (
    <CopyToClipboard text={numberToCopy}>
      <ActionIcon aria-label="copy-to-clipboard" size="xs" isOnColor>
        <CopyIcon style={{ width: '26px', height: '26px' }} />
      </ActionIcon>
    </CopyToClipboard>
  );
};

export default CopyToClipboardActionIcon;
