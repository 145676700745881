import { IconUpload, IconUserPlus } from '@tabler/icons-react';
import { AddIcon } from '@zorro/shared/assets';
import { Box, Group, Icon, RadixMenu, Text } from '@zorro/zorro-ui-design';

import { EmployeeActionsOverlays } from '../EmployeeActions';

type EmployeeManagementActionsMenuProps = {
  setOverlayId: (id: EmployeeActionsOverlays) => void;
  isDisabled?: boolean;
};

export function EmployeeManagementActionsMenu({
  setOverlayId,
  isDisabled = false,
}: EmployeeManagementActionsMenuProps) {
  return (
    <RadixMenu>
      <RadixMenu.Trigger>
        <Box>
          <AddIcon
            style={{ width: '36px', height: '36px', cursor: 'pointer' }}
          />
        </Box>
      </RadixMenu.Trigger>
      <RadixMenu.Content align="end">
        <RadixMenu.Item
          onClick={() => setOverlayId(EmployeeActionsOverlays.UPLOAD_ROSTER)}
          disabled={isDisabled}
        >
          <Group>
            <Icon icon={IconUpload} />
            <Text size="sm">Upload roster</Text>
          </Group>
        </RadixMenu.Item>
        <RadixMenu.Item
          onClick={() => setOverlayId(EmployeeActionsOverlays.ADD_EMPLOYEE)}
          disabled={isDisabled}
        >
          <Group>
            <Icon icon={IconUserPlus} />
            <Text size="sm">Add a new employee</Text>
          </Group>
        </RadixMenu.Item>
      </RadixMenu.Content>
    </RadixMenu>
  );
}
