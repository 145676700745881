import { FinchIntegrationManagement } from '../../Finch';
import {
  CompanySetupTabType,
  CompanySetupTabWrapper,
} from '../CompanySetupTabWrapper';

type Props = {
  employerId: string;
};

export const PayrollSyncTab = ({ employerId }: Props) => {
  return (
    <CompanySetupTabWrapper
      tab={CompanySetupTabType.PAYROLL_SYNC}
      employerId={employerId}
    >
      <FinchIntegrationManagement employerId={employerId} />
    </CompanySetupTabWrapper>
  );
};
