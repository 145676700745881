import { Radio, RadioGroupProps } from '@mantine/core';
import { ForwardedRef, forwardRef } from 'react';

import classNames from './RadioGroup.module.scss';

export type IRadioGroupProps = RadioGroupProps & {
  variant?: 'default' | 'vertical' | 'with-description';
  isReadOnly?: boolean;
};

export const ZorroRadioGroup = forwardRef(
  (
    {
      size = 'md',
      variant = 'default',
      isReadOnly = false,
      ...props
    }: IRadioGroupProps,
    ref: ForwardedRef<HTMLInputElement>
  ) => {
    return (
      <Radio.Group
        ref={ref}
        size={size}
        classNames={classNames}
        data-variant={variant}
        data-readonly={isReadOnly}
        {...props}
      />
    );
  }
);

ZorroRadioGroup.displayName = 'ZorroRadioGroup';
