import { Select, SelectProps } from '@mantine/core';
import { IconChevronDown } from '@tabler/icons-react';
import { ForwardedRef, forwardRef } from 'react';

import { ZorroActionIcon, ZorroIcon } from '../Icon';
import { brand } from '../theme';
import classNames from './Select.module.scss';

export type ISelectProps = Omit<SelectProps, 'variant'> & {
  variant?: 'default' | 'filled' | 'unstyled';
  readonly?: boolean;
};

export const ZorroSelect = forwardRef(
  (
    {
      variant = 'default',
      size = 'lg',
      nothingFoundMessage = 'No results found',
      color = brand.zorroGray600,
      readonly = false,
      disabled = false,
      ...props
    }: ISelectProps,
    ref: ForwardedRef<HTMLInputElement>
  ) => {
    return (
      <Select
        variant={variant}
        size={size}
        nothingFoundMessage={nothingFoundMessage}
        /**
         * clearable prop only works when rightSection prop is not set
         * See more: https://mantine.dev/core/select/#right-section
         */
        rightSection={
          props.clearable ? undefined : (
            <ZorroActionIcon size="sm">
              <ZorroIcon
                icon={IconChevronDown}
                color={brand.zorroGray400}
                size="1.25rem"
              />
            </ZorroActionIcon>
          )
        }
        comboboxProps={{ dropdownPadding: '0.5rem' }}
        withCheckIcon={false}
        classNames={classNames}
        color={color}
        ref={ref}
        disabled={disabled}
        readOnly={readonly}
        data-readonly={readonly}
        {...props}
      />
    );
  }
);

ZorroSelect.displayName = 'Select';
