import { useMonolithQuery } from '@zorro/shared/utils';
import { List } from '@zorro/zorro-ui-design';

type Props = {
  employerId: string;
};

export const FinchPaymentsList = ({ employerId }: Props) => {
  const { data: payments = [], isLoading } = useMonolithQuery({
    method: 'finchControllerPayments',
    params: [employerId],
  });

  if (isLoading) {
    return null;
  }

  return (
    <List w="50%" mah="20rem" style={{ overflowY: 'auto' }}>
      Payments
      {payments.map((payment) => (
        <List.Item key={payment.id}>
          {payment.payFrequency}, {payment.startDate}, {payment.endDate}
        </List.Item>
      ))}
    </List>
  );
};
