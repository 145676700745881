export const ZorroLoader = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      width="76"
      height="76"
      viewBox="0 0 168 168"
    >
      <path
        fill="#8033EF"
        d="M99.79 79.022v-4.203c0-.613-.495-1.111-1.103-1.111H85.595c-.695 0-1.26-.57-1.26-1.27v-7.19a.475.475 0 0 0-.472-.477h-8.958c-3.134 0-5.677 2.562-5.677 5.72v4.317c0 .263.212.476.473.476H82.43c1.151 0 1.7 1.428.847 2.21L68.843 90.69a6.302 6.302 0 0 0-2.048 4.652v4.29c0 .613.494 1.111 1.103 1.111H81.07c.696 0 1.26.569 1.26 1.27v7.283c0 .263.213.476.473.476h9.186c3.047 0 5.514-2.489 5.514-5.554V99.74a.475.475 0 0 0-.473-.476H83.553c-1.01 0-1.488-1.253-.739-1.931l14.933-13.649a6.302 6.302 0 0 0 2.048-4.651l-.005-.011Z"
      />
      <g>
        <circle
          cx="84"
          cy="84"
          r="80"
          stroke="#fff"
          strokeWidth="5"
          opacity=".4"
        />
        <path
          stroke="#8033EF"
          strokeWidth="5"
          d="M84 164c-44.183 0-80-35.817-80-80S39.817 4 84 4"
        />
        <animateTransform
          attributeName="transform"
          dur="1s"
          from="0 84 84"
          repeatCount="indefinite"
          to="360 84 84"
          type="rotate"
        />
      </g>
      <g>
        <circle
          cx="84"
          cy="84"
          r="61"
          stroke="#fff"
          strokeWidth="5"
          opacity=".4"
        />
        <path
          stroke="#8033EF"
          strokeWidth="5"
          d="M84 145c33.689 0 61-27.311 61-61 0-33.69-27.311-61-61-61-33.69 0-61 27.31-61 61"
        />
        <animateTransform
          attributeName="transform"
          dur="1s"
          from="0 84 84"
          repeatCount="indefinite"
          to="360 84 84"
          type="rotate"
        />
      </g>
      <g>
        <circle
          cx="84"
          cy="84"
          r="41"
          stroke="#fff"
          strokeWidth="5"
          opacity=".4"
        />
        <path
          stroke="#8033EF"
          strokeWidth="5"
          d="M84 125c-22.644 0-41-18.356-41-41"
        />
        <animateTransform
          attributeName="transform"
          dur="1s"
          from="0 84 84"
          repeatCount="indefinite"
          to="360 84 84"
          type="rotate"
        />
      </g>
    </svg>
  );
};

ZorroLoader.displayName = 'ZorroAnimatedLoader';
