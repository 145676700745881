import { TagsInput, TagsInputProps } from '@mantine/core';
import { useElementSize } from '@mantine/hooks';
import {
  ForwardedRef,
  forwardRef,
  useCallback,
  useEffect,
  useState,
} from 'react';

import { MultiSelectPopover } from '../MultiSelectPopover/MultiSelectPopover';
import classNames from './TagsInput.module.scss';

export type ITagsInputProps = TagsInputProps;

export const ZorroTagsInput = forwardRef<HTMLInputElement, ITagsInputProps>(
  (
    {
      size = 'lg',
      disabled = false,
      value = [],
      onChange,
      placeholder,
      ...props
    },
    ref: ForwardedRef<HTMLInputElement>
  ) => {
    const { ref: wrapperRef, width: wrapperWidth } = useElementSize();
    const { ref: inputRef } = useElementSize();

    const [popoverValues, setPopoverValues] = useState<string[]>([]);
    const [indexDisplayFrom, setIndexDisplayFrom] = useState<
      number | undefined
    >(undefined);

    const checkIndexDisplayFrom = useCallback(
      (index: number) => {
        if (
          (indexDisplayFrom === undefined || indexDisplayFrom === index) &&
          index !== 0
        ) {
          setIndexDisplayFrom(index);
          setPopoverValues(value.slice(index));
        }

        if (
          indexDisplayFrom !== undefined &&
          indexDisplayFrom !== index &&
          index !== 0
        ) {
          setIndexDisplayFrom(index);
          setPopoverValues(value.slice(index));
        }
      },
      [indexDisplayFrom, value]
    );

    useEffect(() => {
      const pills = Array.from(inputRef.current.parentElement.children).filter(
        (node): node is HTMLElement => {
          return node instanceof HTMLSpanElement;
        }
      );

      if (!wrapperRef.current || wrapperWidth === 0 || pills.length === 0) {
        return;
      }

      pills.forEach((pill) => {
        pill.style.display = 'flex';
      });

      let totalWidth = 0;

      const minInputWidth = 150;

      for (const [index, span] of pills.entries()) {
        totalWidth += span.getBoundingClientRect().width;

        if (wrapperWidth - totalWidth < minInputWidth) {
          checkIndexDisplayFrom(index);
          for (
            let pillIndex = indexDisplayFrom || index;
            pillIndex < pills.length;
            pillIndex++
          ) {
            if (pillIndex !== 0) {
              pills[pillIndex].style.display = 'none';
            }
          }
          break;
        } else {
          setPopoverValues([]);
        }
      }
    }, [
      checkIndexDisplayFrom,
      indexDisplayFrom,
      inputRef,
      wrapperRef,
      wrapperWidth,
    ]);

    return (
      <TagsInput
        ref={inputRef}
        size={size}
        classNames={classNames}
        disabled={disabled}
        wrapperProps={{ ref: wrapperRef }}
        value={value}
        rightSection={
          popoverValues &&
          popoverValues.length > 0 && (
            <MultiSelectPopover
              data={popoverValues}
              onRemove={(item) => {
                onChange?.(
                  value.filter((removedValue) => removedValue !== item)
                );
                setPopoverValues(
                  popoverValues.filter((value) => value !== item)
                );
              }}
              isDisabled={disabled}
            />
          )
        }
        onChange={onChange}
        placeholder={value.length > 0 ? undefined : placeholder}
        {...props}
      />
    );
  }
);

ZorroTagsInput.displayName = 'ZorroTagsInput';
