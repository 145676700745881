import { AdminPortal } from '@frontegg/nextjs';
import {
  IconAdjustments,
  IconLogout,
  IconTable,
  IconUser,
  IconUserCog,
} from '@tabler/icons-react';
import {
  DON_DYNAMIC_ROUTES,
  MURRIETA_DYNAMIC_ROUTES,
  ZORRO_UI_DYNAMIC_ROUTES,
  logout,
  useDynamicRouter,
  useImpersonation,
  useRoles,
} from '@zorro/shared/utils';
import {
  Avatar,
  Card,
  Group,
  Icon,
  RadixMenu,
  brand,
} from '@zorro/zorro-ui-design';

import { AppLayoutHeaderMenuLogo } from './AppLayoutHeaderMenuLogo';

type Props = {
  shouldShowDonElenaMenuItems: boolean;
  isDon: boolean;
};

export const AppLayoutHeaderMenu = ({
  shouldShowDonElenaMenuItems,
  isDon,
}: Props) => {
  const { navigateToRoute } = useDynamicRouter({
    ...DON_DYNAMIC_ROUTES,
    ...MURRIETA_DYNAMIC_ROUTES,
    ...ZORRO_UI_DYNAMIC_ROUTES,
  });

  const { user, isImpersonated } = useImpersonation();
  const { isAgencyAdmin, isEmployerAdmin } = useRoles();

  if (!user) {
    return null;
  }

  return (
    <RadixMenu>
      <RadixMenu.Trigger>
        <Card py="xs" px="sm" style={{ cursor: 'pointer' }}>
          <Group gap="sm" wrap="nowrap">
            <AppLayoutHeaderMenuLogo />
            <Avatar src={user.profilePictureUrl} size={32} />
          </Group>
        </Card>
      </RadixMenu.Trigger>
      <RadixMenu.Content align="end">
        {shouldShowDonElenaMenuItems && (
          <>
            {isDon ? (
              <RadixMenu.Item
                leftSection={
                  <Icon icon={IconUser} color={brand.zorroGray400} size={20} />
                }
                onClick={() => navigateToRoute('MY_COVERAGE', [])}
              >
                Personal view
              </RadixMenu.Item>
            ) : (
              <RadixMenu.Item
                leftSection={
                  <Icon icon={IconTable} color={brand.zorroGray400} size={20} />
                }
                onClick={() => navigateToRoute('COMPANY', [])}
              >
                Admin view
              </RadixMenu.Item>
            )}
            <RadixMenu.Divider />
          </>
        )}
        {!isImpersonated && (
          <RadixMenu.Item
            leftSection={
              <Icon icon={IconUserCog} color={brand.zorroGray400} size={20} />
            }
            onClick={() => AdminPortal.show()}
          >
            My settings
          </RadixMenu.Item>
        )}
        {isAgencyAdmin && (
          <RadixMenu.Item
            leftSection={
              <Icon
                icon={IconAdjustments}
                color={brand.zorroGray400}
                size={20}
              />
            }
            onClick={() => navigateToRoute('AGENCY_SETTINGS', [user.tenantId])}
          >
            Agency setup
          </RadixMenu.Item>
        )}
        {isEmployerAdmin && (
          <RadixMenu.Item
            leftSection={
              <Icon
                icon={IconAdjustments}
                color={brand.zorroGray400}
                size={20}
              />
            }
            onClick={() =>
              navigateToRoute('COMPANY_SETUP_COMPANY_DETAILS', [user.tenantId])
            }
          >
            Company setup
          </RadixMenu.Item>
        )}
        <RadixMenu.Item
          leftSection={
            <Icon icon={IconLogout} color={brand.zorroGray400} size={20} />
          }
          onClick={logout}
        >
          Log out
        </RadixMenu.Item>
      </RadixMenu.Content>
    </RadixMenu>
  );
};
