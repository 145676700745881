import { useQuery } from '@tanstack/react-query';
import { ZorroLogo } from '@zorro/shared/assets';
import {
  callEndpoint,
  nullIfNotFound,
  useImpersonation,
  useRoles,
} from '@zorro/shared/utils';
import { Center, Divider } from '@zorro/zorro-ui-design';

import { EmployerLogo } from '../../EmployerLogo';

export const AppLayoutHeaderMenuLogo = () => {
  const { user } = useImpersonation();
  const { isZorroOperations, isAgent } = useRoles();

  const isAuthenticated = Boolean(user);
  const tenantId = user?.tenantId;
  const { data: logoUrl } = useQuery({
    enabled: !isZorroOperations,
    queryKey: ['EmployerPublicInfo', isAuthenticated, isAgent, tenantId],
    queryFn: async () => {
      if (!tenantId) {
        return null;
      }

      if (isAgent) {
        const agency = await callEndpoint({
          method: 'agenciesControllerFindOne',
          params: [tenantId],
        });

        return agency?.logoUrl ?? null;
      }

      const employerPublicInfo = isAuthenticated
        ? await callEndpoint({
            method: 'employersControllerFindEmployerPublicInfo',
            params: [tenantId],
          })
        : await nullIfNotFound(() =>
            callEndpoint({
              method: 'healthControllerAnonymousFindEmployerPublicInfo',
              params: [tenantId],
              anonymous: true,
            })
          );

      return employerPublicInfo?.logoUrl ?? null;
    },
  });

  if (isZorroOperations) {
    return (
      <>
        <Center px="0.25rem">
          <ZorroLogo withTitle={false} />
        </Center>
        <Divider orientation="vertical" />
      </>
    );
  }

  if (logoUrl) {
    return (
      <>
        <EmployerLogo h={24} w="min-content" logoUrl={logoUrl} />
        <Divider orientation="vertical" />
      </>
    );
  }

  return null;
};
