/* eslint-disable id-length */
import { Text, TextProps } from '@mantine/core';
import { ForwardedRef, ReactNode, forwardRef } from 'react';

export interface ITextProps extends TextProps {
  letterSpacing?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  component?: any;
  title?: string;
  children: string | ReactNode;
}

export const ZorroText = forwardRef(
  (
    {
      letterSpacing = '-0.02em',
      component = 'span',
      c = 'zorroCoal.9',
      display = 'inline-block',
      size,
      fw = 400,
      children,
      ...props
    }: ITextProps,
    ref: ForwardedRef<HTMLDivElement>
  ) => {
    return (
      <Text
        component={component}
        style={{ letterSpacing }}
        display={display}
        size={size}
        ref={ref}
        fw={fw}
        c={c}
        {...props}
      >
        {children}
      </Text>
    );
  }
);

ZorroText.displayName = 'ZorroText';
