import { Button } from '@zorro/zorro-ui-design';

import { useMonolithMutation } from '../../index';

type Props = {
  employerId: string;
};

export const FinchDisconnect = ({ employerId }: Props) => {
  const { mutate: disconnect } = useMonolithMutation({
    method: 'finchControllerConnectDisconnect',
  });

  async function handleDisconnect() {
    await disconnect([{ employerId }]);
  }

  return (
    <Button onClick={handleDisconnect} size="lg">
      Disconnect
    </Button>
  );
};
