import { ComboboxData } from '@mantine/core';
import { Box, ComboboxMultiSelect } from '@zorro/zorro-ui-design';
import { Dispatch } from 'react';

import { Filter, FilterRecord } from './FilterUtils';

type Props<T extends FilterRecord> = {
  title: string;
  filters: Filter<T>[];
  filterBy: keyof T;
  setFilters: Dispatch<Filter<T>[]>;
  selectOptions: ComboboxData | undefined;
};

export const SelectFilter = <T extends FilterRecord>({
  selectOptions,
  title,
  filters,
  filterBy,
  setFilters,
}: Props<T>) => {
  const value = (filters.find(({ type }) => type === filterBy)?.value ??
    []) as string[];

  return (
    <Box w={350}>
      <ComboboxMultiSelect
        data={selectOptions as { label: string; value: string }[]} // TODO: Fix type castion
        value={value}
        onChange={(newValue) => {
          const newArr = filters.filter((fil) => fil.type !== filterBy);
          if (newValue.length > 0) {
            newArr.push({
              type: filterBy,
              value: newValue,
              filterCallback: (recordValues: T) => {
                let shouldFilter = false;
                newValue.forEach((val) => {
                  const recordValue = recordValues[filterBy];
                  const isArray = Array.isArray(recordValue);
                  if (
                    (isArray && recordValue.includes(val)) ||
                    String(recordValues[filterBy]) === val ||
                    (val === '' && !recordValues[filterBy])
                  ) {
                    shouldFilter = true;
                  }
                });
                return shouldFilter;
              },
            });
          }
          setFilters(newArr);
        }}
        label={title}
        placeholder={`Search ${title.replace(':', '...')}`}
      />
    </Box>
  );
};
