import { Center } from '@zorro/zorro-ui-design';

import { SVGIconProps } from '../types';

export const FileIcon = ({
  style,
  width = '24',
  height = '24',
}: SVGIconProps) => {
  return (
    <Center style={style}>
      <svg
        width={width}
        height={height}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M6.02104 3.7913C6.51473 3.28495 7.18432 3.00049 7.8825 3.00049H13.485C13.7177 3.00049 13.9409 3.09531 14.1055 3.26409L18.493 7.76409C18.6576 7.93288 18.75 8.16179 18.75 8.40049V18.3005C18.75 19.0166 18.4727 19.7033 17.979 20.2097C17.4853 20.716 16.8157 21.0005 16.1175 21.0005H7.8825C7.18432 21.0005 6.51473 20.716 6.02104 20.2097C5.52735 19.7033 5.25 19.0166 5.25 18.3005V5.70049C5.25 4.9844 5.52735 4.29765 6.02104 3.7913Z"
          fill="#3C0789"
        />
        <path
          d="M9 9.00049H10M9 12.3755H15M9 15.7505H15"
          stroke="white"
          strokeWidth="1.2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </Center>
  );
};
