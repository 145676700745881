import { yupResolver } from '@hookform/resolvers/yup';
import { PaymentStatus, YesNoNotSure } from '@zorro/clients';
import { useForm, useMonolithQuery } from '@zorro/shared/utils';
import { DeepReadonly } from '@zorro/types';

import { useMonolithMutation } from '../../hooks';
import { AgentOrEmployee } from './AgentOrEmployeeInput';
import {
  PaymentStatusFormFields,
  paymentStatusSchema,
} from './PaymentStatusUtils';

const getPaymentStatus = (
  handler: AgentOrEmployee,
  completed: YesNoNotSure
): PaymentStatus => {
  if (handler === AgentOrEmployee.AGENT) {
    return completed === YesNoNotSure.YES
      ? PaymentStatus.TRUE
      : PaymentStatus.FALSE;
  }
  return completed === YesNoNotSure.YES
    ? PaymentStatus.TRUE_BY_EMPLOYEE
    : completed === YesNoNotSure.NO
    ? PaymentStatus.FALSE_BY_EMPLOYEE
    : PaymentStatus.UNKNOWN_BY_EMPLOYEE;
};

const handlerAndCompletedFromPaymentStatus: DeepReadonly<{
  [paymentStatus in PaymentStatus]: {
    handler: AgentOrEmployee;
    completed: YesNoNotSure;
  };
}> = {
  [PaymentStatus.TRUE]: {
    handler: AgentOrEmployee.AGENT,
    completed: YesNoNotSure.YES,
  },
  [PaymentStatus.TRUE_BY_EMPLOYEE]: {
    handler: AgentOrEmployee.EMPLOYEE,
    completed: YesNoNotSure.YES,
  },
  [PaymentStatus.FALSE]: {
    handler: AgentOrEmployee.AGENT,
    completed: YesNoNotSure.NO,
  },
  [PaymentStatus.FALSE_BY_EMPLOYEE]: {
    handler: AgentOrEmployee.EMPLOYEE,
    completed: YesNoNotSure.NO,
  },
  [PaymentStatus.UNKNOWN_BY_EMPLOYEE]: {
    handler: AgentOrEmployee.EMPLOYEE,
    completed: YesNoNotSure.NOT_SURE,
  },
};

const getHandlerAndCompletedFromPaymentStatus = (
  paymentStatus?: PaymentStatus | null
): {
  handler?: AgentOrEmployee;
  completed?: YesNoNotSure;
} => {
  if (!paymentStatus) {
    return {
      handler: undefined,
      completed: undefined,
    };
  }
  return handlerAndCompletedFromPaymentStatus[paymentStatus];
};

export const usePaymentStatusForm = (
  employeeId: string | undefined,
  onboardingPeriodId: string | undefined
) => {
  const { isLoading, data: paymentMethod } = useMonolithQuery({
    method: 'paymentsControllerGetPaymentMethod',
    params: employeeId &&
      onboardingPeriodId && [employeeId, onboardingPeriodId],
  });

  const { tryMutate: updatePaymentMethod } = useMonolithMutation({
    method: 'paymentsControllerUpdatePaymentMethod',
    shouldReloadPage: false,
  });

  const initialPremiumDefaults = getHandlerAndCompletedFromPaymentStatus(
    paymentMethod?.initialPremiumPaymentStatus
  );
  const autoPayDefaults = getHandlerAndCompletedFromPaymentStatus(
    paymentMethod?.autoPayStatus
  );

  const paymentStatusForm = useForm<PaymentStatusFormFields>({
    mode: 'all',
    resolver: yupResolver(paymentStatusSchema),
    defaultValues: {
      initialPremiumHandler:
        initialPremiumDefaults.handler ?? AgentOrEmployee.AGENT,
      initialPremiumCompleted:
        initialPremiumDefaults.completed ?? YesNoNotSure.NO,
      autoPaySetter: autoPayDefaults.handler ?? AgentOrEmployee.AGENT,
      autoPayCompleted: autoPayDefaults.completed ?? YesNoNotSure.NO,
    },
  });

  async function onSubmit(): Promise<boolean> {
    if (!paymentMethod || !employeeId || !onboardingPeriodId) {
      return false;
    }

    const data = paymentStatusForm.getValues();
    const initialPremiumPaymentStatus = getPaymentStatus(
      data.initialPremiumHandler,
      data.initialPremiumCompleted
    );
    const autoPayStatus = getPaymentStatus(
      data.autoPaySetter,
      data.autoPayCompleted
    );

    const updatePaymentMethodResult = await updatePaymentMethod([
      employeeId,
      {
        onboardingPeriodId,
        status: paymentMethod.status,
        initialPremiumPaymentStatus,
        autoPayStatus,
      },
    ]);

    return updatePaymentMethodResult.isOk();
  }

  return {
    isLoading,
    paymentStatusForm,
    onSubmit,
  };
};
