import { ManWithFile } from '@zorro/shared/assets';
import { showErrorNotification } from '@zorro/shared/utils';
import {
  Button,
  Card,
  Dropzone,
  Group,
  Space,
  Stack,
  Text,
  Title,
  brand,
} from '@zorro/zorro-ui-design';

type Props = {
  onUpload: (file: File) => void;
  isEditable: boolean;
  label: string;
  visibleTo: string;
};

export const EmptyEmployerDocumentsSection = ({
  label,
  isEditable,
  visibleTo,
  onUpload,
}: Props) => {
  if (isEditable) {
    return (
      <>
        <Group wrap="nowrap" gap="xxs">
          <Title order={4} fw={600}>
            {label}
          </Title>
          <Text c="zorroGray.4" fw={400}>
            {visibleTo}
          </Text>
        </Group>
        <Space h="md" />
        <Dropzone
          w="66.875rem"
          h="100%"
          p="3.5rem"
          multiple={false}
          onDrop={(files: File[]) => onUpload(files[0])}
          onReject={(files) => {
            showErrorNotification({
              message: files[0].errors[0].message,
              title: files[0].errors[0].code,
            });
          }}
          loading={false}
          disabled={false}
        >
          <Stack align="center" justify="center" gap={4} w="100%" h="100%">
            <ManWithFile />
            <Space h={12} />
            <Text fw={600} size="sm">
              Nothing here yet
            </Text>
            <Text fw={400} c="zorroGray.4" size="xs">
              Upload an item to get started{' '}
            </Text>
            <Space h={20} />
            <Button size="sm">Add document</Button>
          </Stack>
        </Dropzone>
      </>
    );
  }
  return (
    <>
      <Group wrap="nowrap" gap="xxs">
        <Title order={4} fw={600}>
          {label}
        </Title>
        <Text c="zorroGray.4" fw={400}>
          {visibleTo}
        </Text>
      </Group>
      <Space h="md" />
      <Card w="66.875rem" p="3.5rem">
        <Stack align="center" justify="center" gap={4} w="100%" h="100%">
          <ManWithFile />
          <Space h={12} />
          <Title order={4} fw={600}>
            Nothing here yet
          </Title>
          <Title order={5} fw={400} c={brand.zorroGray400}>
            We haven't added anything yet. Please check back soon!
          </Title>
        </Stack>
      </Card>
    </>
  );
};
