/* eslint-disable unicorn/filename-case,@typescript-eslint/no-explicit-any */
import { FronteggNextJSSession } from '@frontegg/nextjs';
import { getSession } from '@frontegg/nextjs/pages';
import {
  CORRELATION_ID_HEADER,
  MonolithClientFactory,
  MonolithService,
} from '@zorro/clients';
import { COMMON_ROUTES, getLogger, tryGetHeader } from '@zorro/shared/utils';
import {
  GetServerSidePropsContext,
  GetServerSidePropsResult,
  NextApiRequest,
  NextApiResponse,
  PreviewData,
} from 'next/types';
import { ParsedUrlQuery } from 'node:querystring';
import { Logger } from 'pino';

export type ZorroNextJSSession = FronteggNextJSSession & { employerId: string };

export async function withApi(
  request: NextApiRequest,
  _response: NextApiResponse,
  handler: (
    monolithClient: MonolithService,
    logger: Logger
  ) => Promise<void> | void
) {
  const logger = getLogger();

  try {
    const monolithClient = MonolithClientFactory.getNextInstance(
      undefined,
      tryGetHeader(request.headers, CORRELATION_ID_HEADER)
    );
    await handler(monolithClient, logger);
  } catch (error) {
    logger.error(error);
    throw error;
  }
}

export async function withApiSession(
  request: NextApiRequest,
  response: NextApiResponse,
  handler: (
    monolithClient: MonolithService,
    logger: Logger,
    session: ZorroNextJSSession
  ) => Promise<void> | void
) {
  const session = await getSession(request);
  const logger = getLogger(session);

  if (!session) {
    response.status(401).json({ message: 'Unauthorized' });
    logger.error(request, 'Unauthorized');
    return;
  }

  try {
    const monolithClient = MonolithClientFactory.getNextInstance(
      session,
      tryGetHeader(request.headers, CORRELATION_ID_HEADER)
    );
    const zorroSession = {
      ...session,
      employerId: session.user.tenantId,
    };
    await handler(monolithClient, logger, zorroSession);
  } catch (error) {
    logger.error(error);
    throw error;
  }
}

export function withPageSession<
  Props extends { [key: string]: any } = { [key: string]: any },
  Params extends ParsedUrlQuery = ParsedUrlQuery,
  Preview extends PreviewData = PreviewData
>(
  handler: (
    context: GetServerSidePropsContext<Params, Preview>,
    session: ZorroNextJSSession,
    monolithClient: MonolithService,
    logger: Logger
  ) => Promise<GetServerSidePropsResult<Props>>
): (
  context: GetServerSidePropsContext<Params, Preview>
) => Promise<GetServerSidePropsResult<Props>> {
  return async (context) => {
    const session = await getSession(context.req);
    const logger = getLogger(session);

    if (!session) {
      return {
        redirect: {
          destination: COMMON_ROUTES.LOGIN,
          permanent: false,
        },
      };
    }

    try {
      const monolithClient = MonolithClientFactory.getNextInstance(session);
      const zorroSession = {
        ...session,
        employerId: session.user.tenantId,
      };
      return await handler(context, zorroSession, monolithClient, logger);
    } catch (error) {
      logger.error(error);
      throw error;
    }
  };
}
