import {
  DatePickerInput,
  DatePickerInputProps,
  DatesRangeValue,
} from '@mantine/dates';
import { IconCalendarEvent } from '@tabler/icons-react';
import { EN_LOCALE_DATE_FORMAT } from '@zorro/shared/formatters';
import { ForwardedRef, forwardRef } from 'react';

import { ZorroIcon } from '../../Icon';
import classNames from '../DateInput/DateInput.module.scss';

export type IDateRangeInputProps = DatePickerInputProps<'range'>;

export const ZorroDateRangeInput = forwardRef(
  (
    {
      placeholder = `${EN_LOCALE_DATE_FORMAT} - ${EN_LOCALE_DATE_FORMAT}`,
      size = 'lg',
      clearable = false,
      rightSection,
      onChange,
      ...props
    }: IDateRangeInputProps,
    ref: ForwardedRef<HTMLButtonElement>
  ) => {
    return (
      <DatePickerInput
        ref={ref}
        type="range"
        classNames={classNames}
        valueFormat={EN_LOCALE_DATE_FORMAT}
        placeholder={placeholder}
        size={size}
        clearable={clearable}
        rightSection={rightSection || <ZorroIcon icon={IconCalendarEvent} />}
        onChange={(value) => {
          onChange?.(value as DatesRangeValue);
        }}
        {...props}
      />
    );
  }
);

ZorroDateRangeInput.displayName = 'ZorroDateRangeInput';
