import { MantineColor, Radio, RadioCardProps, StyleProp } from '@mantine/core';

import { Text, brand } from '../../../index';
import { ZorroCenter } from '../../Center';
import { ZorroFlex } from '../../Flex';
import { ZorroGroup } from '../../Group';
import { ZorroRadioIndicator } from '../RadioIndicator/RadioIndicator';
import classNames from './RadioCard.module.scss';

export type IRadioCardProps = RadioCardProps & {
  iconColor?: StyleProp<MantineColor>;
  icon?: JSX.Element;
  label?: string;
  description?: string;
  isReadOnly?: boolean;
};

export const ZorroRadioCard = ({
  icon,
  label,
  iconColor,
  description,
  disabled,
  isReadOnly = false,
  ...props
}: IRadioCardProps) => {
  return (
    <Radio.Card
      {...props}
      className={classNames['root']}
      data-has-description={!!description}
      disabled={disabled}
      data-readonly={isReadOnly}
    >
      <ZorroGroup
        wrap="nowrap"
        justify="space-between"
        align={description && 'flex-start'}
      >
        <ZorroFlex align={description && 'flex-start'}>
          {icon && (
            <ZorroCenter bg={iconColor} className={classNames['iconWrapper']}>
              {icon}
            </ZorroCenter>
          )}
          <ZorroGroup gap="xs">
            <Text w="100%" size="sm" fw={description && 600}>
              {label}
            </Text>
            {description && (
              <Text w="100%" c={brand.zorroGray400} fz="xs">
                {description}
              </Text>
            )}
          </ZorroGroup>
        </ZorroFlex>
        {!disabled && <ZorroRadioIndicator />}
      </ZorroGroup>
    </Radio.Card>
  );
};

ZorroRadioCard.displayName = 'ZorroRadioCard';
