import { DocumentType } from '@zorro/clients';
import { DeepReadonly } from '@zorro/types';
import { Space, Tabs } from '@zorro/zorro-ui-design';
import { ReactElement, useState } from 'react';

const allDocumentsTabs = [
  DocumentType.ADMIN,
  DocumentType.PLAN,
  DocumentType.INVOICE,
];
const documentTabConfig: DeepReadonly<{
  [tabType in DocumentType]: {
    label: string;
    isDisabled: boolean;
  };
}> = {
  [DocumentType.ADMIN]: {
    label: 'Admin',
    isDisabled: false,
  },
  [DocumentType.PLAN]: {
    label: 'Plan',
    isDisabled: false,
  },
  [DocumentType.INVOICE]: {
    label: 'Invoices',
    isDisabled: false,
  },
  [DocumentType.ZORRO_PAY]: {
    label: 'Zorro Pay',
    isDisabled: true,
  },
  [DocumentType.COMPLIANCE]: {
    label: 'Invoices',
    isDisabled: true,
  },
};

type Props = {
  onSelectTab: (documentType: DocumentType) => void;
  children: ReactElement;
};

export const DocumentTabWrapper = ({ onSelectTab, children }: Props) => {
  const [activeTab, setActiveTab] = useState<DocumentType>(DocumentType.ADMIN);

  return (
    <>
      <Tabs maw="410px" mt="sm" keepMounted={false} value={activeTab}>
        <Tabs.List grow>
          {allDocumentsTabs
            .filter(
              (documentType) => !documentTabConfig[documentType].isDisabled
            )
            .map((documentTab) => {
              const { label, isDisabled } = documentTabConfig[documentTab];

              return (
                <Tabs.Tab
                  onClick={() => {
                    setActiveTab(documentTab);
                    onSelectTab(documentTab);
                  }}
                  disabled={isDisabled}
                  value={documentTab}
                  key={documentTab}
                >
                  {label}
                </Tabs.Tab>
              );
            })}
        </Tabs.List>
      </Tabs>
      <Space h="2rem" />
      {children}
    </>
  );
};
