import { NumberInput, NumberInputProps } from '@mantine/core';
import { forwardRef } from 'react';

import classNames from './NumberInput.module.scss';

export type INumberInputProps = NumberInputProps;

export const ZorroNumberInput = forwardRef<HTMLDivElement, INumberInputProps>(
  ({ size = 'lg', hideControls = true, decimalScale = 0, ...props }, ref) => {
    return (
      <NumberInput
        ref={ref}
        size={size}
        classNames={classNames}
        hideControls={hideControls}
        decimalScale={decimalScale}
        {...props}
      />
    );
  }
);

ZorroNumberInput.displayName = 'ZorroNumberInput';
