import { Container, ContainerProps } from '@mantine/core';
import { forwardRef } from 'react';

export type IContainerProps = ContainerProps;

const DEFAULT_PAGE_WIDTH = 700;

export const ZorroContainer = forwardRef<HTMLDivElement, IContainerProps>(
  (
    {
      size = DEFAULT_PAGE_WIDTH,
      pb = 'xl',
      mb = 'xl',
      ...props
    }: IContainerProps,
    ref
  ) => {
    return <Container ref={ref} size={size} pb={pb} mb={mb} {...props} />;
  }
);

ZorroContainer.displayName = 'ZorroContainer';
