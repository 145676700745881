import {
  ImageFinchIntroPoweredBy,
  ImageFinchIntroProviders,
} from '@zorro/shared/assets';
import { Box, Card, Group, Stack, Text, Title } from '@zorro/zorro-ui-design';

import { FinchConnect } from './FinchConnect';

type Props = {
  employerId: string;
};

export const FinchIntroduction = ({ employerId }: Props) => {
  return (
    <Card maw="62rem" p="sm">
      <Group wrap="nowrap" align="stretch">
        <Stack flex="1 1 32rem" p="xxl">
          <Title order={1} fw={600}>
            Connect your payroll provider to Zorro
          </Title>
          <Text c="zorroGray.4">
            Select your payroll provider and follow the instructions to automate
            deductions
          </Text>
          <Box>
            <FinchConnect employerId={employerId} />
          </Box>
        </Stack>
        <Card bg="zorroPlum.1" withBorder={false} flex="1 1 auto" p="3rem">
          <ImageFinchIntroProviders />
          <Box pos="absolute" top="0.5rem" right="1rem">
            <ImageFinchIntroPoweredBy />
          </Box>
        </Card>
      </Group>
    </Card>
  );
};
