import {
  EnrollmentStatus,
  MajorMedicalBenefitDto,
  OnboardingPeriodReportDto,
} from '@zorro/clients';
import {
  formatDateEnLocale,
  formatDateEnLocaleRange,
} from '@zorro/shared/formatters';
import { Group, Text } from '@zorro/zorro-ui-design';
import { $enum } from 'ts-enum-util';

type LabelWithValueProps = { label: string; value: string };

type GetCardLabels = (props: {
  onboardingPeriod: OnboardingPeriodReportDto;
  majorMedicalBenefit?: MajorMedicalBenefitDto | null;
}) => LabelWithValueProps[];

const getElectionDatesAndExpectedStartOfCoverage: GetCardLabels = ({
  onboardingPeriod,
  majorMedicalBenefit,
}) => [
  {
    label: 'Election dates',
    value: formatDateEnLocaleRange(
      onboardingPeriod.onboardingFrom,
      onboardingPeriod.onboardingUntil
    ),
  },
  {
    label: 'Expected start of coverage',
    value: formatDateEnLocale(
      majorMedicalBenefit?.benefitEnrollment?.effectiveFrom ||
        onboardingPeriod.targetEnrollmentDate
    ),
  },
];

const getCoveragePeriodAndPlanDetails: GetCardLabels = ({
  majorMedicalBenefit,
}) => [
  {
    label: 'Coverage period',
    value: formatDateEnLocaleRange(
      majorMedicalBenefit?.benefitEnrollment?.effectiveFrom,
      majorMedicalBenefit?.benefitEnrollment?.effectiveUntil
    ),
  },
  {
    label: 'Member ID',
    value: majorMedicalBenefit?.benefitEnrollment?.memberId || '-',
  },
];

type Props = {
  enrollmentStatus: EnrollmentStatus;
  onboardingPeriod: OnboardingPeriodReportDto;
  majorMedicalBenefit?: MajorMedicalBenefitDto | null;
};

export const EnrollmentTabLabels = ({ enrollmentStatus, ...props }: Props) => {
  const { onboardingPeriod, majorMedicalBenefit } = props;
  const labels = $enum.mapValue(enrollmentStatus).with<LabelWithValueProps[]>({
    [EnrollmentStatus.PENDING_ELECTION_WINDOW]:
      getElectionDatesAndExpectedStartOfCoverage(props),
    [EnrollmentStatus.ELECTION_ACTIVE]:
      getElectionDatesAndExpectedStartOfCoverage(props),
    [EnrollmentStatus.ELECTION_ACTIVE_HAS_NOT_STARTED]:
      getElectionDatesAndExpectedStartOfCoverage(props),
    [EnrollmentStatus.ELECTION_ACTIVE_STARTED]:
      getElectionDatesAndExpectedStartOfCoverage(props),
    [EnrollmentStatus.ELECTION_SUBMITTED]: [
      {
        label: 'Expected start of coverage',
        value: formatDateEnLocale(
          props.majorMedicalBenefit?.benefitEnrollment?.effectiveFrom ||
            onboardingPeriod.targetEnrollmentDate
        ),
      },
    ],
    [EnrollmentStatus.WAIVED_ELECTION]: [
      {
        label: 'Waiver effective period',
        value: formatDateEnLocaleRange(
          majorMedicalBenefit?.benefitEnrollment?.effectiveFrom,
          majorMedicalBenefit?.benefitEnrollment?.effectiveUntil
        ),
      },
    ],
    [EnrollmentStatus.DEADLINE_PASSED]: [
      {
        label: 'Election dates',
        value: formatDateEnLocaleRange(
          onboardingPeriod.onboardingFrom,
          onboardingPeriod.onboardingUntil
        ),
      },
    ],
    [EnrollmentStatus.ENROLLMENT_CONFIRMED]:
      getCoveragePeriodAndPlanDetails(props),
    [EnrollmentStatus.CARRIER_APPLICATION_SENT]: [
      {
        label: 'Requested coverage period',
        value: formatDateEnLocaleRange(
          majorMedicalBenefit?.benefitEnrollment?.effectiveFrom,
          majorMedicalBenefit?.benefitEnrollment?.effectiveUntil
        ),
      },
    ],
    [EnrollmentStatus.COVERAGE_ENDED]: getCoveragePeriodAndPlanDetails(props),
    [EnrollmentStatus.ACTIVE_COVERAGE]: getCoveragePeriodAndPlanDetails(props),
    [EnrollmentStatus.WAIVED_COVERAGE]: [
      {
        label: 'Waiver effective period',
        value: formatDateEnLocaleRange(
          majorMedicalBenefit?.benefitEnrollment?.effectiveFrom,
          majorMedicalBenefit?.benefitEnrollment?.effectiveUntil
        ),
      },
    ],
    [EnrollmentStatus.NO_ENROLLMENTS]: [],
  });

  if (labels.length === 0) {
    return null;
  }

  return (
    <Group>
      {labels.map(({ label, value }) => (
        <Text key={label + value} c="zorroGray.4" component="span">
          {label} <Text component="span">{value}</Text>
        </Text>
      ))}
    </Group>
  );
};
