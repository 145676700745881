import { Center } from '@mantine/core';

import { SVGIconProps } from '../types';
import classNames from './Icon.module.scss';

type Props = SVGIconProps & { size?: number };

export const IconCoverageEnded = ({ style, grow, size }: Props) => {
  return (
    <Center style={style} classNames={classNames} data-grow={grow}>
      <svg
        width={size}
        height={size}
        viewBox="0 0 26 26"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="26" height="26" rx="13" fill="#EDEFF6" />
        <path
          d="M8.78437 8.75495C8.22171 9.30918 7.77436 9.96931 7.46811 10.6973C7.16186 11.4253 7.00278 12.2067 7.00004 12.9964C6.99729 13.7862 7.15095 14.5687 7.45213 15.2987C7.75332 16.0288 8.19608 16.6921 8.75487 17.2502C9.31367 17.8083 9.97744 18.2502 10.7079 18.5506C11.4384 18.8509 12.221 19.0036 13.0108 18.9999C13.8006 18.9963 14.5818 18.8363 15.3095 18.5292C16.0371 18.2221 16.6967 17.774 17.2503 17.2107M18.3632 15.6684C18.9276 14.5447 19.1235 13.2716 18.9232 12.0302C18.7229 10.7887 18.1365 9.64191 17.2472 8.75271C16.358 7.8635 15.2112 7.27709 13.9697 7.07677C12.7282 6.87646 11.4551 7.07242 10.3313 7.63683M7.034 7L19 18.9656"
          stroke="#676979"
          strokeWidth="1.45"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </Center>
  );
};
