import { RadioIndicator, RadioIndicatorProps } from '@mantine/core';

import classNames from './RadioIndicator.module.scss';

export const ZorroRadioIndicator = ({
  size = 'xs',
  ...props
}: RadioIndicatorProps) => {
  return <RadioIndicator classNames={classNames} size={size} {...props} />;
};

ZorroRadioIndicator.displayName = 'ZorroRadioIndicator';
