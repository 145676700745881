import {
  IconBriefcase,
  IconTargetArrow,
  IconTopologyStar,
} from '@tabler/icons-react';
import {
  MURRIETA_DYNAMIC_ROUTES,
  useDynamicMurrietaRouter,
  useRoles,
} from '@zorro/shared/utils';
import {
  Group,
  Icon,
  Stack,
  Text,
  UnstyledButton,
  brand,
} from '@zorro/zorro-ui-design';
import { ElementType } from 'react';

import { useAppLayout } from '../../../AppLayoutContext';
import classNames from './AppLayoutHeaderSearchButtons.module.scss';

type SearchButtonProps = {
  href: string;
  label: string;
  icon: ElementType;
};

const getSearchButtons = (
  isZorroOperations: boolean,
  isAccountSupervisor: boolean
): SearchButtonProps[] => {
  const buttons = [
    {
      href: MURRIETA_DYNAMIC_ROUTES.EMPLOYERS(),
      label: 'All employers',
      icon: IconBriefcase,
    },
  ];

  if (isZorroOperations || isAccountSupervisor) {
    buttons.push({
      href: MURRIETA_DYNAMIC_ROUTES.PROSPECTS(),
      label: 'All prospects',
      icon: IconTargetArrow,
    });
  }

  if (isZorroOperations) {
    buttons.push({
      href: MURRIETA_DYNAMIC_ROUTES.AGENCIES(),
      label: 'All agencies',
      icon: IconTopologyStar,
    });
  }

  return buttons;
};

export const AppLayoutHeaderSearchButtons = () => {
  const { router } = useDynamicMurrietaRouter();
  const { toggleSearch } = useAppLayout();
  const { isZorroOperations, isAccountSupervisor } = useRoles();

  const buttons = getSearchButtons(isZorroOperations, isAccountSupervisor);

  const handleResultClick = (href: string, isChecked: boolean) => {
    toggleSearch();
    if (isChecked) {
      router.reload();
    } else {
      router.push(href);
    }
  };

  return (
    <Stack gap="xs" mb="lg">
      <Text c="zorroGray.4">Go to</Text>
      <Group gap="sm">
        {buttons.map(({ href, label, icon }) => {
          const isChecked = router.asPath === href;

          return (
            <UnstyledButton
              onClick={() => handleResultClick(href, isChecked)}
              data-checked={isChecked}
              classNames={classNames}
              key={href}
            >
              <Group gap="xs">
                <Icon icon={icon} color={brand.zorroGray400} />
                <Text size="sm">{label}</Text>
              </Group>
            </UnstyledButton>
          );
        })}
      </Group>
    </Stack>
  );
};
