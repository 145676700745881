import { useAuth } from '@frontegg/nextjs';
import Intercom from '@intercom/messenger-js-sdk';
import { parseDate } from '@zorro/shared/formatters';
import { useMonolithQuery } from '@zorro/shared/utils';
import { Roles } from '@zorro/types';
import { useEffect } from 'react';

export const IntercomWidget = () => {
  const { user, isAuthenticated } = useAuth();
  const { data: employee, isLoading: isLoadingEmployee } = useMonolithQuery({
    method: 'employeesControllerFindEmployeeByUserId',
    params: user && [user.id],
    enabled: user?.roles.some((role) => role.key === Roles.EMPLOYEE),
  });

  let intercomUserId: string | undefined;
  if (!isLoadingEmployee) {
    intercomUserId = employee?.id ?? user?.id;
  }

  const { data: hashData, isLoading: isLoadingHash } = useMonolithQuery({
    method: 'intercomControllerGenerateHash',
    params: intercomUserId && [{ intercomUserId }],
  });

  useEffect(() => {
    if (
      !isAuthenticated ||
      !user ||
      !intercomUserId ||
      isLoadingHash ||
      !hashData?.hash ||
      !process.env.NEXT_PUBLIC_INTERCOM_ID
    ) {
      return;
    }

    const createdAt = user.createdAt
      ? parseDate(user.createdAt).unix()
      : undefined;

    Intercom({
      app_id: process.env.NEXT_PUBLIC_INTERCOM_ID,
      email: user.email,
      name: user.name,
      created_at: createdAt,
      user_id: intercomUserId,
      user_hash: hashData.hash,
      hide_default_launcher: true,
      alignment: 'right',
    });
  }, [isAuthenticated, user, intercomUserId, isLoadingHash, hashData]);

  return null;
};
