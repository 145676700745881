import { Pill } from '@mantine/core';
import { IconCheck, IconChevronUp, IconTrash } from '@tabler/icons-react';
import { InsuredSubtype } from '@zorro/clients';
import {
  AddIcon,
  IconZorroUser,
  IconZorroUsers,
  IconZorroUsers2,
} from '@zorro/shared/assets';
import {
  Accordion,
  ActionIcon,
  Box,
  Group,
  Text,
  ThemeIcon,
  brand,
} from '@zorro/zorro-ui-design';
import { useEffect, useState } from 'react';
import { FormState, useFieldArray } from 'react-hook-form';

import { useAnalytics } from '../Analytics';
import {
  DependentForm,
  InsuredDependentFormFields,
} from './DependentsFormInputs';
import {
  EmployeeFormInputs,
  InsuredEmployeeFormFields,
} from './EmployeeFormInputs';
import { getDefaultInsuredFormFields } from './InsuredFormUtils';
import { InsuredSpouseFormFields, SpouseFormInputs } from './SpouseFormInputs';
import { useInsuredForm } from './useInsuredForm';

type Props = {
  insuredForm: ReturnType<typeof useInsuredForm>;
  employerId: string;
  isFinalizationMode: boolean;
  isEmployeeMode: boolean;
};

type InsuredTabsType = 'EMPLOYEE' | 'SPOUSE' | `DEPENDENT-${number}`;

export const InsuredFormInputs = ({
  insuredForm,
  employerId,
  isFinalizationMode,
  isEmployeeMode,
}: Props) => {
  const {
    employeeForm,
    spouseForm,
    dependentsForm,
    hasAnyDependents,
    insured,
    isSpouseActive,
    setIsSpouseActive,
    targetEnrollmentDate,
  } = insuredForm;
  const { sendEvent } = useAnalytics();

  const fullNameEmployee = `${employeeForm.watch('firstName') || ''} ${
    employeeForm.watch('lastName') || ''
  }`.trim();
  const fullNameSpouse = `${spouseForm.watch('firstName') || ''} ${
    spouseForm.watch('lastName') || ''
  }`.trim();
  const employeeLabel = isEmployeeMode ? 'You' : 'Employee';
  const spouseSubtypeLabel =
    spouseForm.watch('subtype') === InsuredSubtype.DOMESTIC_PARTNER
      ? 'Domestic partner'
      : 'Spouse';

  const [openedTabs, setOpenedTabs] = useState<InsuredTabsType[]>(['EMPLOYEE']);

  const handleDeleteSpouse = () => {
    spouseForm.reset(getDefaultInsuredFormFields(undefined));
    setIsSpouseActive(false);
    sendEvent('remove_spouse');
  };

  const employeeAddress = employeeForm.watch('residentialAddress');
  const { setValue: setSpouseValue } = spouseForm;
  const {
    append: appendDependent,
    remove,
    fields: dependents,
  } = useFieldArray({
    control: dependentsForm.control,
    name: 'dependents',
  });

  const handleAppendDependent = (isNewDependents?: boolean) => {
    employeeForm.trigger();
    if (hasAnyDependents) {
      dependentsForm.trigger();
    }
    appendDependent(
      getDefaultInsuredFormFields(employeeAddress) as InsuredDependentFormFields
    );
    setOpenedTabs((prevTab) => [...prevTab, `DEPENDENT-${dependents.length}`]);
    sendEvent('add_dependent');
  };

  useEffect(() => {
    setIsSpouseActive(Boolean(insured?.spouse));
  }, [setIsSpouseActive, insured]);

  const handleAddSpouse = () => {
    if (!isSpouseActive) {
      setIsSpouseActive(true);
      sendEvent('add_spouse');
      setOpenedTabs((prevTab) => [...prevTab, 'SPOUSE']);
    }
  };

  const handleSetSpouseActive = (isActive: boolean) => {
    setSpouseValue('residentialAddress', employeeAddress);
    setIsSpouseActive(isActive);
  };

  const handleChange = async (value: string[]) => {
    await insuredForm.employeeForm.trigger();
    if (isSpouseActive && openedTabs.includes('SPOUSE')) {
      await insuredForm.spouseForm.trigger();
    }
    if (hasAnyDependents) {
      await insuredForm.dependentsForm.trigger();
    }
    setOpenedTabs(value as InsuredTabsType[]);
  };

  const getDependentTitle = (index: number) => {
    const dependent = dependentsForm.getValues().dependents[index];
    const fullName = `${dependent.firstName || ''} ${
      dependent.lastName || ''
    }`.trim();
    const isDependentOpen = openedTabs.includes(`DEPENDENT-${index}`);
    const subtypeLabel =
      dependent.subtype === InsuredSubtype.OTHER_DEPENDENT
        ? 'Dependent'
        : 'Child';

    if (fullName && !isDependentOpen) {
      return `${subtypeLabel} (${fullName})`;
    }

    return 'Dependent';
  };

  const renderFormState = (
    formState:
      | FormState<InsuredEmployeeFormFields>
      | FormState<InsuredSpouseFormFields>
  ) => {
    const { isValid, errors } = formState;

    if (isValid) {
      return (
        <ThemeIcon color={brand.zorroGreen600} radius={20} size={20}>
          <IconCheck color={brand.zorroWhite} size={16} />
        </ThemeIcon>
      );
    }

    if (Object.keys(errors).length > 0) {
      return (
        <Pill size="md" bg={brand.zorroFire300} radius="sm" fw={400} fz={12}>
          Missing details
        </Pill>
      );
    }

    return null;
  };

  const getRequiredDependetFields = (index: number) => {
    const dependent = dependentsForm.getValues().dependents[index];

    return (
      dependent.firstName !== '' &&
      dependent.lastName !== '' &&
      dependent.dateOfBirth !== undefined
    );
  };

  return (
    <Accordion
      variant="separated"
      multiple
      value={openedTabs}
      onChange={handleChange}
      chevron={
        <ActionIcon>
          <IconChevronUp />
        </ActionIcon>
      }
    >
      <Accordion.Item
        value="EMPLOYEE"
        style={(theme) => ({
          borderRadius: theme.radius.md,
          backgroundColor: theme.colors.zorroWhite,
        })}
      >
        <Accordion.Control icon={<IconZorroUser />}>
          <Group>
            <Text size="sm" fw={600}>
              {employeeLabel}
              <Text fw={400} c="zorroGray.4" ml="xs">
                {fullNameEmployee}
              </Text>
            </Text>
            {renderFormState(employeeForm.formState)}
          </Group>
        </Accordion.Control>
        <Accordion.Panel>
          <EmployeeFormInputs
            employeeForm={employeeForm}
            targetEnrollmentDate={targetEnrollmentDate}
            isFinalizationMode={isFinalizationMode}
            isEmployeeMode={isEmployeeMode}
            employerId={employerId}
            setPossibleFipsCodes={insuredForm.setPossibleFipsCodes}
            possibleFipsCodes={insuredForm.possibleFipsCodes}
          />
        </Accordion.Panel>
      </Accordion.Item>
      <Accordion.Item
        value="SPOUSE"
        style={(theme) => ({
          borderRadius: theme.radius.md,
          backgroundColor: theme.colors.zorroWhite,
          cursor: isSpouseActive ? 'default' : 'pointer',
          padding: !isSpouseActive && '0.75rem 1.5rem',
        })}
      >
        {isSpouseActive ? (
          <>
            <Accordion.Control icon={<IconZorroUsers />}>
              <Group justify="space-between">
                <Group>
                  <Text size="sm" fw="600" className="fs-mask">
                    {spouseSubtypeLabel}
                    <Text fw={400} c="zorroGray.4" ml="xs">
                      {fullNameSpouse}
                    </Text>
                  </Text>
                  {renderFormState(spouseForm.formState)}
                </Group>
                <ActionIcon
                  mr="xs"
                  onClick={(event) => {
                    event.preventDefault();
                    handleDeleteSpouse();
                  }}
                >
                  <IconTrash size={20} color={brand.zorroGray800} />
                </ActionIcon>
              </Group>
            </Accordion.Control>
            <Accordion.Panel>
              <SpouseFormInputs
                spouseForm={spouseForm}
                setIsSpouseActive={(isActive) =>
                  handleSetSpouseActive(isActive)
                }
                isSpouseActive={isSpouseActive}
                isEmployeeMode={isEmployeeMode}
                isFinalizationMode={isFinalizationMode}
                targetEnrollmentDate={targetEnrollmentDate}
              />
            </Accordion.Panel>
          </>
        ) : (
          <Group onClick={() => handleAddSpouse()} gap="sm">
            <AddIcon style={{ width: '24px', height: '24px' }} />
            <Text size="sm" fw={600}>
              Add spouse / domestic partner
            </Text>
          </Group>
        )}
      </Accordion.Item>
      {dependents.map((dependent, index) => (
        <Accordion.Item
          value={`DEPENDENT-${index}`}
          key={`DEPENDENT-${dependent.id}`}
          style={(theme) => ({
            borderRadius: theme.radius.md,
            backgroundColor: theme.colors.zorroWhite,
          })}
        >
          <Accordion.Control icon={<IconZorroUsers2 />}>
            <Group justify="space-between">
              <Group>
                <Text size="sm" fw="600" className="fs-mask">
                  {getDependentTitle(index)}
                </Text>
                {!dependentsForm.formState.isValid &&
                dependentsForm.formState.errors?.dependents?.[index] ? (
                  <Pill
                    ml="sm"
                    size="lg"
                    bg={brand.zorroFire300}
                    radius="sm"
                    fw={400}
                    fz={12}
                  >
                    Missing details
                  </Pill>
                ) : dependentsForm.formState.errors?.dependents?.[index] ===
                    undefined && getRequiredDependetFields(index) ? (
                  <ThemeIcon color={brand.zorroGreen600} radius={20} size={20}>
                    <IconCheck color={brand.zorroWhite} size={16} />
                  </ThemeIcon>
                ) : null}
              </Group>
              <ActionIcon
                mr="xs"
                onClick={(event) => {
                  event.preventDefault();
                  remove(index);
                  sendEvent('remove_dependent');
                }}
              >
                <IconTrash size={20} color={brand.zorroGray400} />
              </ActionIcon>
            </Group>
          </Accordion.Control>
          <Accordion.Panel>
            <DependentForm
              targetEnrollmentDate={targetEnrollmentDate}
              isFinalizationMode={isFinalizationMode}
              isEmployeeMode={isEmployeeMode}
              dependentsForm={dependentsForm}
              index={index}
            />
          </Accordion.Panel>
        </Accordion.Item>
      ))}
      <Box
        w="100%"
        opacity={1}
        tabIndex={0}
        p="0.75rem 1.5rem"
        pos="relative"
        bg="zorroWhite.0"
        mt="md"
        style={(theme) => ({
          cursor: 'pointer',
          borderRadius: theme.radius.md,
          boxShadow: `inset 0 0 0 1px ${theme.colors.zorroGray[2]}`,
        })}
        onClick={() => handleAppendDependent(true)}
        onKeyDown={(event) => {
          if (event.code === 'Space' || event.code === 'Enter') {
            event.preventDefault();
            handleAppendDependent(true);
          }
        }}
      >
        <Group gap="xs">
          <AddIcon />
          <Text size="sm" fw={600}>
            Add dependent <Text c={brand.zorroGray400}>(under 26)</Text>
          </Text>
        </Group>
      </Box>
    </Accordion>
  );
};
