import { formatCurrencyEnUs } from '@zorro/shared/formatters';
import {
  Box,
  Divider,
  FormErrorMessage,
  Grid,
  NumberInput,
  Stack,
  Text,
} from '@zorro/zorro-ui-design';
import { Controller, UseFormReturn } from 'react-hook-form';

import { AllowanceFormFields } from './AllowanceFormUtils';

type Props = {
  allowanceForm: UseFormReturn<AllowanceFormFields>;
  employerMonthlyContribution: number;
};

export const AllowanceFormInputs = ({
  allowanceForm,
  employerMonthlyContribution,
}: Props) => {
  const { control, watch, formState } = allowanceForm;
  const { errors } = formState;

  const { employeeMonthlyContribution, premium } = watch();

  return (
    <Box p="lg" bg="zorroGray.10">
      <Grid>
        <Grid.Col span={3}>
          <Text fw="600">Total allowance *</Text>
        </Grid.Col>
        <Grid.Col span={2}>
          <Controller
            control={control}
            name="allowance"
            render={({ field: { ...rest } }) => (
              <NumberInput
                {...rest}
                prefix="$"
                styles={(theme) => ({
                  input: {
                    background: 'transparent',
                    fontSize: theme.fontSizes.xl,
                    color: theme.colors.zorroCoal[0],
                    fontWeight: 600,
                  },
                })}
                size="md"
              />
            )}
          />

          <FormErrorMessage errors={errors} fieldName="allowance" />
        </Grid.Col>
        <Grid.Col>
          <Divider color="zorroGray.2" />
        </Grid.Col>

        <Grid.Col span={3}>
          <Text fw="600">Monthly breakdown</Text>
        </Grid.Col>

        <Grid.Col span={2}>
          <Stack gap="xs">
            <Text fw={600} size="xl">
              {formatCurrencyEnUs(employerMonthlyContribution)}
            </Text>
            <Text c="zorroGray.4">Employer</Text>
          </Stack>
        </Grid.Col>

        <Grid.Col span={1}>
          <Text fw={600} size="lg">
            +
          </Text>
        </Grid.Col>

        <Grid.Col span={2}>
          <Stack gap="xs">
            <Text fw={600} size="xl">
              {formatCurrencyEnUs(employeeMonthlyContribution)}
            </Text>
            <Text c="zorroGray.4">Employee</Text>
          </Stack>
        </Grid.Col>

        <Grid.Col span={1}>
          <Text fw={600} size="lg">
            =
          </Text>
        </Grid.Col>

        <Grid.Col span={2}>
          <Stack gap="xs">
            <Text fw={600} size="xl">
              {formatCurrencyEnUs(premium)}
            </Text>
            <Text c="zorroGray.4">Total premium</Text>
          </Stack>
        </Grid.Col>
      </Grid>
    </Box>
  );
};
