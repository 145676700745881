import { useMantineTheme } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import {
  EnrollmentStatus,
  InsuredPeopleDto,
  MajorMedicalBenefitDto,
} from '@zorro/clients';
import { HealthPlanIcon, TotalIcon } from '@zorro/shared/assets';
import { Grid, Text } from '@zorro/zorro-ui-design';

import { DividerRow } from './DividerRow';
import { EditActionColumn } from './EditActionColumn';
import { SummaryRow } from './SummaryRow';
import { WithEmployeeContribution } from './WithEmployeeContribution';

const displayProp = { base: 'none', md: 'inherit' };

type EnrollmentSummaryCardProps = {
  isLoading: boolean;
  healthPlanYourContribution: number;
  healthPlanEmployersContribution: number;
  majorMedicalBenefit?: MajorMedicalBenefitDto | null;
  enrollmentStatus?: EnrollmentStatus;
  memberId?: string | null;
  isEmployerPage?: boolean;
  onClickEditHealthPlan?: () => void;
  insuredPeople?: InsuredPeopleDto | null;
  selfPayAmount?: number | null;
  shouldShowBenefitStatus?: boolean;
  shouldShowEmptyValues?: boolean;
};

export const EnrollmentSummaryCard = ({
  isLoading,
  healthPlanYourContribution,
  healthPlanEmployersContribution,
  majorMedicalBenefit,
  enrollmentStatus,
  memberId,
  isEmployerPage = false,
  shouldShowBenefitStatus,
  shouldShowEmptyValues,
  onClickEditHealthPlan,
  insuredPeople,
  selfPayAmount,
}: EnrollmentSummaryCardProps) => {
  const theme = useMantineTheme();
  const isSmallerThanMd = useMediaQuery(`(max-width: ${theme.breakpoints.md})`);
  const employeeTotal = healthPlanYourContribution;
  const employerTotal = healthPlanEmployersContribution;
  const selectedMedicalPlan = majorMedicalBenefit?.plan;

  return (
    <Grid
      align="center"
      style={(theme) => ({
        border: `1px solid ${theme.colors.zorroGray[6]}`,
        borderRadius: theme.radius.md,
      })}
      m={0}
    >
      <Grid.Col display={displayProp} offset={3} span={3} py="xl">
        <Text size="sm" fw={600}>
          Who is covered?
        </Text>
      </Grid.Col>

      <Grid.Col span={1} py="xl" display={displayProp}>
        <Text size="sm" fw={600}>
          {isEmployerPage ? 'Employee’s' : 'Your'} monthly contribution
        </Text>
      </Grid.Col>

      <Grid.Col span={1} py="xl" display={displayProp} />

      <Grid.Col span={1} py="xl" display={displayProp}>
        <Text size="sm" fw={600}>
          Employer&apos;s monthly contribution
        </Text>
      </Grid.Col>

      <Grid.Col span={1} py="xl" display={displayProp} />

      <Grid.Col span={1} py="xl" display={displayProp}>
        <Text size="sm" fw={600}>
          Total {isEmployerPage ? '' : 'monthly Premium'}
        </Text>
      </Grid.Col>

      <Grid.Col span={1} py="xl" display={displayProp} />
      <DividerRow />

      <SummaryRow
        isLoading={isLoading}
        icon={<HealthPlanIcon />}
        title="Medical Plan"
        yourContribution={healthPlanYourContribution}
        enrollmentStatus={enrollmentStatus}
        memberId={memberId}
        employerContribution={healthPlanEmployersContribution}
        familyUnitCovered={selectedMedicalPlan?.familyUnit}
        shouldShowBenefitStatus={shouldShowBenefitStatus}
        shouldShowEmptyValues={shouldShowEmptyValues}
        selectedMedicalPlan={selectedMedicalPlan}
        editActionColumn={
          isEmployerPage && (
            <EditActionColumn onClickEdit={onClickEditHealthPlan} />
          )
        }
        insuredPeople={insuredPeople}
      />

      <Grid.Col
        style={{ borderRadius: 'inherit' }}
        bg="zorroGray.10"
        pt="md"
        pb="xl"
        px="xl"
      >
        <Grid align="center">
          <WithEmployeeContribution
            isLoading={isLoading}
            icon={<TotalIcon />}
            shouldShowEmptyValues={shouldShowEmptyValues}
            title={isSmallerThanMd ? 'Monthly Total' : 'Total'}
            yourContribution={employeeTotal}
            employerContribution={employerTotal}
            insuredPeople={insuredPeople}
            selfPayAmount={selfPayAmount}
          />
        </Grid>
      </Grid.Col>
    </Grid>
  );
};
