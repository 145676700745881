import { IconDownload } from '@tabler/icons-react';
import { ActionIcon } from '@zorro/zorro-ui-design';

type Props = {
  onDownload: () => void;
  isDisabled?: boolean;
};

export const DownloadButton = ({ onDownload, isDisabled = false }: Props) => {
  return (
    <ActionIcon size="lg" disabled={isDisabled} onClick={onDownload}>
      <IconDownload />
    </ActionIcon>
  );
};
