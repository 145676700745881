import { Divider } from '@mantine/core';
import { Group, Skeleton, Stack } from '@zorro/zorro-ui-design';

const SearchResultSkeleton = () => (
  <Group p="sm">
    <Skeleton h="4rem" w="7.25rem" borderRadius={4} />
    <Stack gap="xs">
      <Skeleton h={20} w={120} borderRadius={4} />
      <Skeleton h={20} w={310} borderRadius={4} />
    </Stack>
  </Group>
);

export const AppLayoutHeaderSearchResultsSkeleton = () => {
  return (
    <Stack h="20rem" style={{ overflowY: 'auto' }}>
      <Stack gap={0}>
        <SearchResultSkeleton />
        <Divider />
        <SearchResultSkeleton />
        <Divider />
        <SearchResultSkeleton />
      </Stack>
    </Stack>
  );
};
