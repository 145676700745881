import { IconInfoCircle } from '@tabler/icons-react';
import {
  EnrollmentStatus,
  FamilyUnit,
  InsuredPeopleDto,
  MajorMedicalPlanDto,
} from '@zorro/clients';
import { formatCurrencyEnUs } from '@zorro/shared/formatters';
import {
  Box,
  Grid,
  Group,
  Icon,
  Link,
  Skeleton,
  Stack,
  Text,
  Tooltip,
} from '@zorro/zorro-ui-design';
import { ReactNode } from 'react';

import { EnrollmentStatusBadge } from '../EnrollmentStatus/EnrollmentStatusBadge';
import { FamilyMembersReadOnly } from '../FamilyMembers';
import { IconWithTitle } from './IconWithTitle';

type FirstIconColumnProps = {
  isLoading: boolean;
  title: string;
  icon?: ReactNode;
  isHealthPlan?: boolean;
  selectedMedicalPlan?: MajorMedicalPlanDto | null;
  enrollmentStatus?: EnrollmentStatus;
  memberId?: string | null;
  shouldShowBenefitStatus?: boolean;
  editActionColumn?: ReactNode;
  isSummaryRow?: boolean;
};

const FirstIconColumn = ({
  isLoading,
  title,
  icon,
  isHealthPlan,
  selectedMedicalPlan,
  enrollmentStatus,
  memberId,
  shouldShowBenefitStatus = true,
  editActionColumn,
  isSummaryRow = false,
}: FirstIconColumnProps) => {
  const formattedEnrollmentStatus =
    enrollmentStatus &&
    [
      EnrollmentStatus.ELECTION_ACTIVE_STARTED,
      EnrollmentStatus.ELECTION_ACTIVE_HAS_NOT_STARTED,
    ].includes(enrollmentStatus)
      ? EnrollmentStatus.ELECTION_ACTIVE
      : enrollmentStatus;

  return (
    <Grid.Col pl={0} span={{ md: 3, sm: 12 }}>
      <Group gap={0}>
        <IconWithTitle
          title={title}
          icon={icon}
          editActionColumn={editActionColumn}
          gap={10}
          mr={14}
          pl={6}
        />
        {shouldShowBenefitStatus && formattedEnrollmentStatus && (
          <EnrollmentStatusBadge
            enrollmentStatus={formattedEnrollmentStatus}
            shouldDisableTooltip
          />
        )}
      </Group>
      {isLoading && isSummaryRow && (
        <Grid.Col pl={8}>
          <Skeleton height={26} width={194} />
          <Skeleton height={26} width={140} mt="0.5rem" />
        </Grid.Col>
      )}
      {isHealthPlan && selectedMedicalPlan && (
        <Grid.Col pl={8}>
          <Stack gap="xs">
            <Text>
              {selectedMedicalPlan.carrierName}, {selectedMedicalPlan.name}
            </Text>
            {selectedMedicalPlan.benefitsSummaryUrl && (
              <Text>
                <Link
                  href={selectedMedicalPlan.benefitsSummaryUrl}
                  target="_blank"
                  anchorProps={{
                    fw: 600,
                    className: 'excluded-from-print',
                  }}
                >
                  View details
                </Link>
              </Text>
            )}
            {selectedMedicalPlan?.externalID && (
              <Group gap="xs">
                <Text fw={600}>Plan ID:</Text>
                <Text>{selectedMedicalPlan?.externalID}</Text>
              </Group>
            )}
            {memberId && (
              <Group gap="xs">
                <Text fw={600}>Member ID:</Text>
                <Text>{memberId}</Text>
              </Group>
            )}
          </Stack>
        </Grid.Col>
      )}
    </Grid.Col>
  );
};

type Props = {
  isLoading: boolean;
  title: string;
  yourContribution: number;
  employerContribution: number;
  familyUnitCovered?: FamilyUnit | null;
  selectedMedicalPlan?: MajorMedicalPlanDto | null;
  enrollmentStatus?: EnrollmentStatus;
  memberId?: string | null;
  editActionColumn?: ReactNode;
  isSupplemental?: boolean;
  icon?: ReactNode;
  insuredPeople?: InsuredPeopleDto | null;
  selfPayAmount?: number | null;
  shouldShowBenefitStatus?: boolean;
  shouldShowEmptyValues?: boolean;
  isSummaryRow?: boolean;
};

export const WithEmployeeContribution = ({
  isLoading,
  title,
  familyUnitCovered,
  selectedMedicalPlan,
  editActionColumn,
  employerContribution,
  enrollmentStatus,
  memberId,
  shouldShowBenefitStatus,
  shouldShowEmptyValues,
  yourContribution,
  isSupplemental,
  icon,
  insuredPeople,
  selfPayAmount,
  isSummaryRow = false,
}: // eslint-disable-next-line sonarjs/cognitive-complexity
Props) => {
  const isMedicalPlanWaived =
    enrollmentStatus === EnrollmentStatus.WAIVED_ELECTION ||
    enrollmentStatus === EnrollmentStatus.WAIVED_COVERAGE;
  return (
    <>
      <FirstIconColumn
        isLoading={isLoading}
        title={title}
        icon={icon}
        shouldShowBenefitStatus={shouldShowBenefitStatus}
        enrollmentStatus={enrollmentStatus}
        memberId={memberId}
        selectedMedicalPlan={selectedMedicalPlan}
        isHealthPlan={title === 'Medical Plan'}
        editActionColumn={editActionColumn}
        isSummaryRow={isSummaryRow}
      />

      <Grid.Col span={{ md: 3 }}>
        <Group>
          <Text size="sm" fw={600} display={{ md: 'none' }}>
            Who is covered?
          </Text>
          {isLoading && isSummaryRow ? (
            <Skeleton height={26} width={100} />
          ) : (
            <Text>
              {isMedicalPlanWaived ? (
                '-'
              ) : (
                <FamilyMembersReadOnly
                  employee={insuredPeople ? insuredPeople.employee : null}
                  spouse={insuredPeople?.spouse}
                  dependents={insuredPeople?.dependents}
                  checkedMembers={familyUnitCovered}
                />
              )}
            </Text>
          )}
        </Group>
      </Grid.Col>

      <Grid.Col span={{ md: 1 }}>
        {isSupplemental ? null : (
          <Group>
            <Text size="sm" fw={600} display={{ md: 'none' }}>
              Your contribution:
            </Text>
            {isLoading ? (
              <Skeleton height={26} width={86} />
            ) : (
              <Text fw={600}>
                {isMedicalPlanWaived || shouldShowEmptyValues
                  ? '-'
                  : formatCurrencyEnUs(yourContribution)}
              </Text>
            )}
          </Group>
        )}
      </Grid.Col>
      <Grid.Col display={{ base: 'none', md: 'inherit' }} span={{ md: 1 }}>
        <Text fw={600} ta="center">
          {!isMedicalPlanWaived && !isLoading && '+'}
        </Text>
      </Grid.Col>
      <Grid.Col span={{ md: 1 }}>
        <Group>
          <Text size="sm" fw={600} display={{ md: 'none' }}>
            Employer&apos;s contribution:
          </Text>
          {isLoading ? (
            <Skeleton height={26} width={86} />
          ) : (
            <Text fw={600}>
              {isMedicalPlanWaived || shouldShowEmptyValues
                ? '-'
                : formatCurrencyEnUs(employerContribution)}
            </Text>
          )}
        </Group>
      </Grid.Col>
      <Grid.Col span={{ md: 1 }} display={{ base: 'none', md: 'inherit' }}>
        <Text fw={600} ta="center">
          {!isMedicalPlanWaived && !isLoading && '='}
        </Text>
      </Grid.Col>
      <Grid.Col span={{ md: 1 }}>
        <Group>
          <Text size="sm" fw={600} display={{ md: 'none' }}>
            Total Premium:
          </Text>
          <Group gap="0.125rem" pos="relative">
            {isLoading ? (
              <Skeleton height={26} width={86} />
            ) : (
              <Text fw={600}>
                {isMedicalPlanWaived || shouldShowEmptyValues
                  ? '-'
                  : formatCurrencyEnUs(employerContribution + yourContribution)}
              </Text>
            )}
            {selectedMedicalPlan && selfPayAmount && (
              <Tooltip
                label={
                  <Text>
                    Employee’s self payment (personal credit card):{' '}
                    <Text fw={600} display="inline">
                      {formatCurrencyEnUs(selfPayAmount)}
                    </Text>
                  </Text>
                }
              >
                <Box h="1.5rem">
                  <Icon icon={IconInfoCircle} size="1.25rem" />
                </Box>
              </Tooltip>
            )}
          </Group>
        </Group>
      </Grid.Col>
      <Grid.Col span={{ md: 1 }} />
    </>
  );
};
