import { IconPrinter } from '@tabler/icons-react';
import { ActionIcon, Icon, brand } from '@zorro/zorro-ui-design';

export const PrintButton = () => {
  return (
    <ActionIcon style={{ border: `1px solid ${brand.zorroGray200}` }} size="lg">
      <Icon icon={IconPrinter} />
    </ActionIcon>
  );
};
