import { EmployerSignupStatus, EmployerStatus } from '@zorro/clients';
import { $enum } from 'ts-enum-util';

import { MURRIETA_DYNAMIC_ROUTES } from './routes';

export type EmployerStatusRoutes = Extract<
  keyof typeof MURRIETA_DYNAMIC_ROUTES,
  | 'COMPANY_SETUP_PROSPECT'
  | 'ADD_EMPLOYER_COMPANY_DETAILS'
  | 'ADD_EMPLOYER_REVIEW'
  | 'COMPANY_OVERVIEW'
  | 'ERROR'
>;

export const getRouteForEmployerStatus = (
  employerStatus: EmployerStatus | EmployerSignupStatus
): EmployerStatusRoutes => {
  return $enum.mapValue(employerStatus).with<EmployerStatusRoutes>({
    [EmployerStatus.PROSPECT]: 'COMPANY_SETUP_PROSPECT',
    [EmployerStatus.DRAFT]: 'ADD_EMPLOYER_COMPANY_DETAILS',
    [EmployerStatus.WAITING_FOR_EMPLOYER]: 'ADD_EMPLOYER_REVIEW',
    [EmployerStatus.NEEDS_REVIEW]: 'ADD_EMPLOYER_REVIEW',
    [EmployerStatus.IN_ZORRO_SETUP]: 'ADD_EMPLOYER_REVIEW',
    [EmployerStatus.ACTIVE]: 'COMPANY_OVERVIEW',
    [EmployerStatus.DEACTIVATED]: 'COMPANY_OVERVIEW',
    [$enum.handleUnexpected]: 'ERROR',
  });
};
