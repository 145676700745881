import { DateInput, DateInputProps } from '@mantine/dates';
import { IconCalendarEvent } from '@tabler/icons-react';
import { EN_LOCALE_DATE_FORMAT } from '@zorro/shared/formatters';
import { useForwardRef } from '@zorro/shared/utils';
import { KeyboardEvent, forwardRef } from 'react';

import { ZorroIcon } from '../../Icon';
import classNames from './DateInput.module.scss';

type IDateInputProps = DateInputProps & {
  masked?: boolean;
};

export const ZorroDateInput = forwardRef<HTMLInputElement, IDateInputProps>(
  (
    {
      rightSection,
      onChange,
      placeholder = EN_LOCALE_DATE_FORMAT,
      size = 'lg',
      clearable = false,
      masked = false,
      ...props
    },
    ref
  ) => {
    const innerRef = useForwardRef<HTMLInputElement | null>(ref);

    const handleOnKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
      if (event.key === 'Enter') {
        event.preventDefault();
        innerRef.current?.blur();
      }
    };

    const inputClassNames = [
      classNames.input ?? null,
      masked ? 'fs-mask' : null,
    ].filter((className): className is string => Boolean(className));

    return (
      <DateInput
        ref={innerRef}
        placeholder={placeholder}
        size={size}
        clearable={clearable}
        classNames={{
          ...classNames,
          input: inputClassNames.join(' '),
        }}
        rightSection={rightSection || <ZorroIcon icon={IconCalendarEvent} />}
        onKeyDown={handleOnKeyDown}
        onChange={onChange}
        {...props}
      />
    );
  }
);

ZorroDateInput.displayName = 'ZorroDateInput';
