import { ThreeDotsIcon } from '@zorro/shared/assets';
import {
  getEmployeeEmploymentStatus,
  useMonolithQuery,
  useRoles,
} from '@zorro/shared/utils';
import {
  ActionIcon,
  Box,
  Group,
  Menu,
  Stack,
  Text,
  useDisclosure,
} from '@zorro/zorro-ui-design';

import { EmployeeLayoutAction } from '../EmployeeLayout/EmployeeLayoutActions';
import { getEligibilityStatusBadgeConfig } from './EligibilityStatusBadgeUtils';

type Props = {
  employeeId: string;
  setAction: (action: EmployeeLayoutAction) => void;
};

export const EligibilityStatusBadge = ({ employeeId, setAction }: Props) => {
  const { isZorroOperations } = useRoles();
  const [opened, { toggle }] = useDisclosure(false);

  const { data: employee, isLoading } = useMonolithQuery({
    method: 'employeesControllerFindOne',
    params: [employeeId],
  });

  if (!employee || isLoading) {
    return null;
  }

  const employmentStatus = getEmployeeEmploymentStatus(
    employeeId,
    employee.eligibleFrom,
    employee.eligibleUntil,
    employee.leaveOfAbsence?.startDate,
    employee.leaveOfAbsence?.endDate
  );
  const { label, icon, description } = getEligibilityStatusBadgeConfig(
    employmentStatus,
    employee
  );

  return (
    <Group
      bg="zorroGray.10"
      p="sm"
      pb="0.75rem"
      pos="relative"
      style={{ borderRadius: 8 }}
      id="eligibilityMenu"
    >
      <Group gap="xs" wrap="nowrap" align="start" w="100%">
        {icon}
        <Stack gap="0.25rem">
          <Text size="sm">{label}</Text>
          <Text c="zorroGray.4" size="xs" style={{ textWrap: 'nowrap' }}>
            {description}
          </Text>
        </Stack>
      </Group>

      <Box pos="absolute" right="0.25rem" top="0.125rem">
        <Menu shadow="md" width={200} opened={opened} onChange={toggle}>
          <Menu.Target>
            <ActionIcon isChecked={opened} isTransparent>
              <ThreeDotsIcon />
            </ActionIcon>
          </Menu.Target>

          <Menu.Dropdown>
            <Menu.Item
              onClick={() => setAction(EmployeeLayoutAction.TERMINATE_EMPLOYEE)}
            >
              Terminate employee
            </Menu.Item>
            <Menu.Item
              onClick={() =>
                setAction(EmployeeLayoutAction.SET_LEAVE_OF_ABSENCE)
              }
            >
              Set a leave of absence
            </Menu.Item>
            {isZorroOperations && (
              <Menu.Item
                onClick={() =>
                  setAction(EmployeeLayoutAction.UPDATE_ELIGIBILITY)
                }
              >
                Update eligibility
              </Menu.Item>
            )}
          </Menu.Dropdown>
        </Menu>
      </Box>
    </Group>
  );
};
