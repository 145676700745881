import { PasswordInput, PasswordInputProps } from '@mantine/core';
import { IconEye, IconEyeOff } from '@tabler/icons-react';
import { forwardRef } from 'react';

import { ZorroIcon } from '../Icon';
import classNames from './PasswordInput.module.scss';

export interface IPasswordInputProps extends PasswordInputProps {
  isVisible?: boolean;
}

const EyeOffIcon = () => <ZorroIcon icon={IconEyeOff} />;
const EyeIcon = () => <ZorroIcon icon={IconEye} />;

export const ZorroPasswordInput = forwardRef<
  HTMLInputElement,
  IPasswordInputProps
>(({ size = 'lg', styles, isVisible, ...props }, ref) => {
  const regex = /X/u;

  return (
    <PasswordInput
      ref={ref}
      size={size}
      classNames={classNames}
      visibilityToggleIcon={({ reveal }) => (reveal ? EyeOffIcon() : EyeIcon())}
      tabIndex={0}
      styles={(theme, props, ctx) => ({
        innerInput: {
          color: isVisible
            ? props.value === 'XXXXXXXXX'
              ? theme.colors.zorroGray[7]
              : regex.test(props.value?.toString() ?? '')
              ? theme.colors.zorroCoal[9]
              : undefined
            : undefined,
        },
        ...(typeof styles === 'function' ? styles(theme, props, ctx) : styles),
      })}
      {...props}
    />
  );
});

ZorroPasswordInput.displayName = 'ZorroPasswordInput';
