/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum FinchPayFrequency {
  ANNUALLY = 'annually',
  SEMI_ANNUALLY = 'semi_annually',
  QUARTERLY = 'quarterly',
  MONTHLY = 'monthly',
  SEMI_MONTHLY = 'semi_monthly',
  BI_WEEKLY = 'bi_weekly',
  WEEKLY = 'weekly',
  DAILY = 'daily',
  OTHER = 'other',
}
