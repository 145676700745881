import {
  Card,
  Divider,
  Grid,
  Skeleton,
  Stack,
  brand,
} from '@zorro/zorro-ui-design';

export const EmployeeLayoutSidebarHeaderSkeleton = () => {
  return (
    <Card bd="none" p={15} radius={14} mb={21}>
      <Grid gutter="lg" mt={5}>
        <Grid.Col span={2}>
          <Skeleton h={44} w={44} circle />
        </Grid.Col>

        <Grid.Col span={9} mt={4} ml={16}>
          <Grid>
            <Grid.Col span={9} mt={4}>
              <Skeleton h={12} mb="xs" />
            </Grid.Col>
            <Grid.Col span={2} mt={4} offset={1}>
              <Skeleton h={8} w={12} mb="xs" />
            </Grid.Col>
          </Grid>
          <Skeleton h={12} w={100} />
        </Grid.Col>
      </Grid>
      <Divider my="sm" mb="lg" />
      <Grid mb={18}>
        <Grid.Col span={3}>
          <Skeleton h={12} w={100} />
        </Grid.Col>
        <Grid.Col span={3} offset={5}>
          <Skeleton h={12} w={70} />
        </Grid.Col>
      </Grid>
      <Grid mb="lg">
        <Grid.Col span={3}>
          <Skeleton h={12} w={100} />
        </Grid.Col>
        <Grid.Col span={3} offset={5}>
          <Skeleton h={12} w={70} />
        </Grid.Col>
      </Grid>
      <Stack bg={brand.zorroGray900} p="md" style={{ borderRadius: 8 }}>
        <Grid>
          <Grid.Col span={2}>
            <Skeleton h={24} w={24} />
          </Grid.Col>
          <Grid.Col span={10}>
            <Skeleton h={12} mb="xs" />
            <Skeleton h={12} />
          </Grid.Col>
        </Grid>
      </Stack>
    </Card>
  );
};
