import { FullPageLoader } from '../../FullPageLoader';
import {
  MedicalPlansFormInputs,
  useMedicalPlanForm,
} from '../../MedicalPlanForm';
import {
  ManualSubmissionStep,
  ManualSubmissionStepSubmit,
  ManualSubmissionStepType,
} from '../ManualSubmissionStep';

type Props = {
  employerId: string;
  employeeId: string;
  onboardingPeriodId: string;
  isDon?: boolean;
};

export const ManualSubmissionMedicalPlanStep = ({
  employerId,
  employeeId,
  onboardingPeriodId,
  isDon = false,
}: Props) => {
  const {
    isSubmitting,
    medicalPlanForm,
    whoIsEnrolled,
    insured,
    onboardingPeriod,
    insuredIdToNameMap,
    onSubmit,
  } = useMedicalPlanForm({ employeeId, onboardingPeriodId });

  const handleStepSubmit: ManualSubmissionStepSubmit = async () => {
    if (await medicalPlanForm.trigger()) {
      return { navigate: await onSubmit() };
    }

    return { navigate: false };
  };

  if (!insured) {
    return <FullPageLoader />;
  }

  return (
    <ManualSubmissionStep
      title="Enter all relevant information about the selected medical plan"
      step={ManualSubmissionStepType.MEDICAL_PLAN}
      submitLabel="Confirm and submit election"
      onboardingPeriodId={onboardingPeriodId}
      onSubmit={handleStepSubmit}
      employerId={employerId}
      employeeId={employeeId}
      isDon={isDon}
    >
      <MedicalPlansFormInputs
        insuredIdToNameMap={insuredIdToNameMap}
        onboardingPeriod={onboardingPeriod}
        medicalPlanForm={medicalPlanForm}
        whoIsEnrolled={whoIsEnrolled}
        isSubmitting={isSubmitting}
        employeeId={employeeId}
        insured={insured}
      />
    </ManualSubmissionStep>
  );
};
